import "antd/dist/antd.css";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import AuthContextProvider from "./context_api/AuthContext";
import { AppRoutes } from "./routes";

function App() {
  
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <AppRoutes />
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
