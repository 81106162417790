import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Layout, Menu } from 'antd';
import { memo, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { DefaultUserIcon, MWare_Logo_Blue } from '../../../assets/ims_images';
import { AuthContext } from '../../../context_api/AuthContext';
import { AuthActionTypes } from '../../../context_api/AuthContext/action-types';
import { DrawerContext } from '../../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../../context_api/DrawerContext/action-types';
import { IMSNameParams } from '../../../pages/ims';
import { hasRole } from '../../../utils/Auth/role.auth';
import getDatastoresMapping, { IDashboardDatastores } from '../../../utils/DatastoresMapping';
import useMwareRouteMatch from '../../../utils/Hooks/useMwareRouteMatch';
import HeaderContentManagement from './components/ContentManagement/index';
import HeaderContentPublishing from './components/Publishing';
const { Header } = Layout;
const { SubMenu } = Menu;
type IMSDashboardProps = {
  isSideBarShow: Boolean;
  path: string;
};
const IMSDashboardHeader = (props: IMSDashboardProps) => {
  const location = useLocation();
  const { ims_name } = useParams<IMSNameParams>();
  const { url } = useMwareRouteMatch();
  const [datastoreInfo, setDatastoreInfo] = useState<IDashboardDatastores>();

  const history = useHistory();
  const { state: AuthState, dispatch: AuthDispatch } = useContext(AuthContext);
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  useEffect(() => {
    if (location.trigger === 'popstate') {
      let currentLocation = location.pathname?.toLowerCase();
      if (currentLocation && !['cms', 'crm', 'iq', 'uit'].some((v) => currentLocation!.includes(v))) {
        DrawerDispatch({
          type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
          default_selected_key: '',
          default_open_key: '',
          routes: [],
          service_name: '',
          service_type: '',
          client_dashboard_path: '',
        });
      }
    }
    if (DrawerState.client_info) {
      let datastoresInfo: IDashboardDatastores = getDatastoresMapping(DrawerState.client_info.datastore);
      setDatastoreInfo(datastoresInfo);
    }
  }, [DrawerState.client_info, location, DrawerDispatch]);
  const handleMenuClick = ({ key }: any) => {
    document.body.classList.remove('menu-overlay');
    if (key === 'logout') {
      AuthDispatch({ type: AuthActionTypes.LOGOUT });
      history.push('/login');
    }
  };
  const userMenu = () => {
    return (
      <Menu className="header-dropdown user-dropdown">
        <div className="px-4 py-2">
          <div className="media border-bottom pb-3 d-flex align-items-center">
            <img className="rounded-circle mr-3 align-self-center" src={DefaultUserIcon} title="User" alt="User" />
            <div className="media-body">
              <h5 className="mt-0 text-primary">{AuthState.user?.name}</h5>
              <p className="mb-0">{AuthState.user?.email}</p>
            </div>
          </div>
          <Menu
            onClick={handleMenuClick}
            items={[
              {
                className: 'pl-0 m-0',
                key: 'mware_profile',
                label: 'Mware Profile',
                onClick: () => <Link to={`/user-profile`} />,
              },
              {
                className: 'pl-0 m-0',
                key: 'help',
                label: 'Help',
                onClick: () => <Link className=" pb-3" to={`/`} />,
              },
              {
                key: 'logout',
                className: 'pl-0 mt-3 mb-0 text-danger',
                label: 'Logout',
                onClick: () => <Link className=" pb-3" to={`/`} />,
              },
            ]}
            className="header-dropdown mt-3 border-0"
          ></Menu>
        </div>
      </Menu>
    );
  };
  const dropdownVisibleChange = (isOpen: boolean) => {
    if (isOpen) {
      document.body.classList.add('menu-overlay');
    } else {
      document.body.classList.remove('menu-overlay');
    }
  };
  const changeMainMenu = (openKeys: any) => {
    if (openKeys && openKeys.length > 0) {
      document.body.classList.add('menu-overlay');
    } else {
      document.body.classList.remove('menu-overlay');
    }
  };

  return (
    <Header className="p-0 d-flex z-overly-10">
      <nav className="d-flex align-items-center w-100 bg-white px-3 px-lg-4 py-0">
        {props.isSideBarShow && (
          <div className="mr-auto">
            <Link
              className="py-2 px-3 h5 mb-0 text-white nav-button"
              onClick={(e) => {
                e.preventDefault();
                if (window.innerWidth > 1199) {
                  DrawerDispatch({ type: DrawerActionTypes.TOGGLE_DRAWER });
                } else {
                  document.body.classList.add('mobile-sider');
                }
              }}
              to={`/`}
            >
              <FontAwesomeIcon icon={faBars} className="trigger" />
            </Link>
          </div>
        )}
        {!props.isSideBarShow && (
          <Link to={`/`} className="mr-auto">
            <img src={DrawerState.white_labeling.logo || MWare_Logo_Blue} title="Mware" alt="Mware" style={DrawerState.white_labeling.logo ? { width: 200, height: 50 } : {}} />
          </Link>
        )}

        <Menu onOpenChange={changeMainMenu} triggerSubMenuAction={'click'} mode="horizontal" className="border-0 d-flex justify-content-end mr-0 mr-lg-4 flex-column flex-md-row">
          <SubMenu
            key="deployments"
            popupClassName="deployment-menu header-menu"
            title={
              <span className="submenu-title-wrapper">
                My Deployments
                <FontAwesomeIcon className="ml-2 align-middle d-none d-md-inline-block" icon={faAngleDown} />
              </span>
            }
          >
            {datastoreInfo && <HeaderContentManagement datastoreInfo={datastoreInfo} />}
          </SubMenu>
          <SubMenu
            key="settings"
            popupClassName="setting-menu header-menu"
            title={
              <span className="submenu-title-wrapper">
                Setting
                <FontAwesomeIcon className="ml-2 align-middle d-none d-md-inline-block" icon={faAngleDown} />
              </span>
            }
          >
            {/* <SubMenu
              key="system_users"
              popupClassName="setting-menu header-menu"
              title={
                <span className="submenu-title-wrapper">System Users</span>
              }
            >
              <Menu.Item key="system-users">
                <Link to={`/system-users`}>Users List</Link>
              </Menu.Item>
              <Menu.Item key="add-system-user">
                <Link to={`/system-users/add-user`}>Add Users</Link>
              </Menu.Item>
            </SubMenu> */}
            <Menu.Item key="system-users">
              <Link to={`/system-users`}>System Users</Link>
            </Menu.Item>

            <Menu.Item key="smtp-setting">
              <Link to={`${url}/setting/smtp-setting`}>SMTP Settings</Link>
            </Menu.Item>

            <Menu.Item key="akamai-settings">
              <Link to={`${url}/setting/akamai-settings`}>Akamai Settings</Link>
            </Menu.Item>
            {/* <Menu.Item key="sendgrid-setting">
              <Link to={`${url}/setting/sendgrid-setting`}>SendGrid Settings</Link>
            </Menu.Item> */}
            <Menu.Item key="timezones-setting">
              <Link to={`${url}/setting/timezones-setting`}>Timezones</Link>
            </Menu.Item>
            <Menu.Item key="whitelabel-setting">
              <Link to={`${url}/setting/whitelabel-setting`}>White Labelling</Link>
            </Menu.Item>
            <Menu.Item key="user-logs">
              <Link to={`${url}/setting/user-logs`}>User Logs</Link>
            </Menu.Item>
            <Menu.Item key="super-hostname">
              <Link to={`${url}/setting/super-hostname`}>Super Hostname</Link>
            </Menu.Item>
          </SubMenu>
          {hasRole(AuthState.user!, ims_name, ['superadmin', 'admin']) && (
            <SubMenu
              key="publishing"
              popupClassName="publishing-menu header-menu"
              title={
                <span className="submenu-title-wrapper">
                  Publishing
                  <FontAwesomeIcon className="ml-2 align-middle d-none d-md-inline-block" icon={faAngleDown} />
                </span>
              }
            >
              <HeaderContentPublishing />
            </SubMenu>
          )}
          <Menu.Item key="alipay" className="support-center">
            <a target="_blank" rel="noopener noreferrer" href="https://mwareiptv.gitbook.io/tvms/">
              Support Center
            </a>
          </Menu.Item>
        </Menu>

        <Dropdown overlay={() => userMenu()} trigger={['click']} onVisibleChange={dropdownVisibleChange}>
          <Link to={``} className="nav-link pl-2 pl-lg-4 pr-0 user-menu">
            <img className="rounded-circle mr-2" src={DefaultUserIcon} title="User" alt="User" />
            <span className="d-none d-lg-inline-block">{AuthState.user?.name}</span>
            <FontAwesomeIcon className="ml-2 align-middle" icon={faAngleDown} />
          </Link>
        </Dropdown>
      </nav>
    </Header>
  );
};

export default memo(IMSDashboardHeader);
