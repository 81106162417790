import { IUserInfo } from "../../interfaces/responses/user/IUserInfoResponse";
import TokenStorageService from "../../utils/TokenStorageService";
import { AuthActions, AuthActionTypes } from "./action-types";

export type AuthState = {
  is_userinfo_loading: boolean;
  isAuthenticated: boolean;
  user?: IUserInfo;
  token?: string;
  refresh_token?: string;
};

export const initialAuthState: AuthState = {
  is_userinfo_loading: false,
  isAuthenticated: TokenStorageService.isAuthenticated(),
  user: undefined,
  token: TokenStorageService.getAccessToken(),
  refresh_token: TokenStorageService.getRefreshToken(),
};

const AuthReducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      const { auth } = action;
      TokenStorageService.setToken(auth);
      return {
        ...state,
        isAuthenticated: true,
        refresh_token: auth.refresh_token,
        token: auth.token,
        user: auth.user,
      } as AuthState;
    case AuthActionTypes.LOGOUT:
    case AuthActionTypes.LOGIN_FAILURE:
      TokenStorageService.clearToken();
      return {
        ...state,
        isAuthenticated: false,
        refresh_token: "",
        token: "",
      } as AuthState;
    case AuthActionTypes.GET_USERINFO_START:
      return {
        ...state,
        is_userinfo_loading: true,
      } as AuthState;

    case AuthActionTypes.GET_USERINFO_SUCCESS:
      const { user } = action;
      return {
        ...state,
        is_userinfo_loading: false,
        user: user,
      } as AuthState;
    default:
      return state;
  }
};
export default AuthReducer;
