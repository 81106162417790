import Enumerable from 'linq';
import { IDatastore } from '../../interfaces/responses/clients/IClientInfoResponse';

export interface IDashboardDatastores {
  all_cms_names: string[];
  cms_info: ICMSInfo[];
}
export interface ICMSInfo {
  name: string;
  cms_services: string[];
  cms?: IDatastore;
  iq?: IDatastore[];
  crm?: IDatastore[];
  uit?: IDatastore[];
}

const getDatastoresMapping = (datastores: IDatastore[]): IDashboardDatastores => {
  let cmsInfo: ICMSInfo[] = [];
  let allDatastores = Enumerable.from(datastores);
  let allCMSServices = allDatastores.where((x) => x.cms_name !== undefined && x.cms_name !== null);
  let allCMSRefNames: string[] = allCMSServices
    .select((x) => x.cms_name ?? '')
    .distinct()
    .toArray();

  let allCMSDatastore = allDatastores
    .where((x) => {
      return x.type.toLowerCase() === 'cms';
    })
    .orderBy((x) => x.name);

  let allCMSDatastoreNames = allCMSDatastore
    .select((x) => x.name)
    .distinct()
    .orderBy((x) => x)
    .toArray();

  allCMSDatastoreNames = Enumerable.from(allCMSRefNames.concat(allCMSDatastoreNames))
    .distinct()
    .orderBy((x) => x)
    .toArray();

  allCMSDatastoreNames.forEach((cmsName, index) => {
    let cms = allDatastores
      .where((x) => x.name === cmsName && x.type.toLowerCase() === 'cms')
      .firstOrDefault();
    let allCMSServiceNames = allCMSServices
      .where((x) => x.cms_name === cmsName)
      .select((x) => x.name)
      .distinct()
      .orderBy((x) => x)
      .toArray();
    let allCRMForCMS = allDatastores
      .where((x) => x.cms_name === cmsName && x.type.toLowerCase() === 'crm')
      .orderBy((x) => x.name)
      .toArray();

    let allUITForCMS = allDatastores
      .where((x) => x.cms_name === cmsName && x.type.toLowerCase() === 'uit')
      .orderBy((x) => x.name)
      .toArray();

    let allIQForCMS = allDatastores
      .where((x) => x.cms_name === cmsName && x.type.toLowerCase() === 'iq')
      .orderBy((x) => x.name)
      .toArray();

    cmsInfo.push({
      name: cmsName,
      cms_services: allCMSServiceNames,
      cms,
      crm: allCRMForCMS,
      iq: allIQForCMS,
      uit: allUITForCMS,
    });
  });
  let datastoreMapping: IDashboardDatastores = {
    all_cms_names: allCMSDatastoreNames,
    cms_info: cmsInfo,
  };
  return datastoreMapping;
};

export default getDatastoresMapping;
