import { Button, Form, Input, notification, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { StatusCodes } from 'http-status-codes';
import { memo, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useHistory, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../common/components/Notification';
import { ICountry } from '../../interfaces/responses/common/ICountriesResponse';
import { IGetUserDetailsByIdUserInfo } from '../../interfaces/responses/user/IGetUserDetailsByIdResponse';
import { IUpdateUserDetailsForm } from '../../interfaces/responses/user/IUpdateUserDetails';
import common from '../../services/common';
import user from '../../services/user';
import ValidatePhoneNumber from '../../utils/Yup/phone-validation';

const SystemUserDetails = () => {
  const [form] = useForm();
  const { user_id } = useParams<any>();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [, setFetchedDetails] = useState<IGetUserDetailsByIdUserInfo>();
  const [geoCountries, setGeoCountries] = useState<ICountry[]>([]);
  const [fetchingCountries, setFetchingCountries] = useState<boolean>(false);
  const getCountries = async () => {
    setFetchingCountries(true);
    let response = await common.FetchCountries();
    if (response.status_code === StatusCodes.OK) {
      setGeoCountries(response.data.countries);
    }
    setFetchingCountries(false);
  };

  const history = useHistory();
  const fetchDetails = async () => {
    setIsFetchingData(true);
    let response = await user.GetUserDetailsById(user_id);
    if (response?.data) {
      setFetchedDetails(response.data);
      form.setFieldsValue(response.data);
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsFetchingData(false);
  };
  const updateUserDetails = async (values: IUpdateUserDetailsForm) => {
    setIsUpdatingData(true);
    let response = await user.UpdateUserDetails({ ...values, _id: user_id });
    if (response && response.status_code === StatusCodes.OK)
      notification.success({
        message: 'User details updated successfully',
      });
    else
      CustomNotification({
        error: response.errors,
      });
    setIsUpdatingData(false);
  };

  useEffectOnce(() => {
    getCountries();
    fetchDetails();
  });

  return (
    <div className="container-fluid wrapper p-3">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body card-ch-padding">
              <div className="row mb-4">
                <div className="col-12">
                  <h3 className="page-title">User Detail</h3>
                </div>
              </div>
              {isFetchingData && (
                <div className="row text-center h-100 w-100">
                  <div className="col-12 align-self-center">
                    <Spin size="large" spinning={true} />
                  </div>
                </div>
              )}
              {!isFetchingData && (
                <Form form={form} onFinish={updateUserDetails} onFinishFailed={() => {}}>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Name</label>
                    <FormItem
                      name="name"
                      className="col-md-5"
                      rules={[
                        {
                          required: true,
                          message: 'Name is required!',
                        },
                      ]}
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="Name" />
                    </FormItem>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Email</label>
                    <FormItem
                      className="col-md-5"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Email is required!',
                        },
                        {
                          type: 'email',
                          message: 'Email format is incorrect!',
                        },
                      ]}
                    >
                      <Input inputMode="email" type={'email'} className="form-control bg-white font-size-14 text-default" placeholder="Email" />
                    </FormItem>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Mobile Number</label>
                    <FormItem
                      name="mobile_number"
                      className="col-md-5"
                      rules={[
                        {
                          required: true,
                          message: 'Mobile Number is required!',
                        },
                        {
                          min: 5,
                          message: 'Mobile Number is not in correct form',
                        },
                        {
                          validator: async (rule, value) => {
                            if (value && ValidatePhoneNumber('+' + value)) return await Promise.resolve();
                            else return await Promise.reject(Error('Mobile Number is not in correct format.'));
                          },
                        },
                      ]}
                    >
                      <PhoneInput inputClass="ant-input form-control w-100 rounded  bg-white font-size-14 text-default" country={'us'} />
                    </FormItem>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Address</label>
                    <FormItem
                      className="col-md-5"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: 'Address is required!',
                        },
                      ]}
                    >
                      <Input.TextArea cols={4} rows={4} className="form-control bg-white font-size-14 text-default" placeholder="Address" />
                    </FormItem>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">City</label>
                    <FormItem
                      className="col-md-5"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: 'City is required!',
                        },
                      ]}
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="City" />
                    </FormItem>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">State</label>
                    <FormItem
                      className="col-md-5"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: 'State is required!',
                        },
                      ]}
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="State" />
                    </FormItem>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Country</label>
                    <FormItem
                      className="col-md-5"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: 'Country is required!',
                        },
                      ]}
                    >
                      <Select
                        loading={fetchingCountries}
                        disabled={fetchingCountries}
                        className="default-select bg-white"
                        placeholder="Choose Country"
                        style={{ width: '100%' }}
                        showSearch={true}
                        filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                      >
                        {geoCountries &&
                          geoCountries.length > 0 &&
                          geoCountries.map((country) => {
                            return (
                              <Select.Option value={country._id} key={country._id}>
                                {country.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </FormItem>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Postal Code</label>
                    <FormItem
                      className="col-md-5"
                      name="pin_code"
                      rules={[
                        {
                          required: true,
                          message: 'Postal Code is required!',
                        },
                      ]}
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="Postal Code" />
                    </FormItem>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <FormItem>
                        <Button type="primary" className="rounded-pill width-240 height-54" onClick={() => history.goBack()}>
                          Back to List
                        </Button>
                      </FormItem>
                    </div>
                    <div className="col-6">
                      <FormItem style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" loading={isUpdatingData} disabled={isUpdatingData || isFetchingData} className="rounded-pill width-240 height-54">
                          <span className="px-5 font-weight-bold">Save Changes</span>
                        </Button>
                      </FormItem>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(SystemUserDetails);
