import { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
// import SideBar from "../../../navigation/sidebar";
// import Header from "../../../navigation/header";
// import Footer from "../../../footer";
import { AuthContext } from '../../../context_api/AuthContext';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { state: AuthState } = useContext(AuthContext);
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated = AuthState.isAuthenticated;
        return isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
