import { Button, Form, Input, notification, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { StatusCodes } from 'http-status-codes';
import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../../common/components/Notification';
import ims from '../../../services/ims';
import { IMSNameParams } from '../../ims';

const AkamaiSettings = () => {
  const [form] = useForm();
  const [isUpdating, setIsUpdating] = useState(false);
  const [getingDetails, setGetingDetails] = useState(true);
  const { ims_name } = useParams<IMSNameParams>();

  const fetchAkamaiSettings = async () => {
    setGetingDetails(true);
    let response = await ims.getAkamaiSettings({
      client_name: ims_name,
    });
    if (response && response.status_code === StatusCodes.OK) form.setFieldsValue(response.data);
    else
      CustomNotification({
        error: response.errors,
      });
    setGetingDetails(false);
  };
  const handleSubmit = async (values: any) => {
    setIsUpdating(true);
    let response = await ims.addUpdateAkamaiSettings({
      ...values,
      client_name: ims_name,
    });

    if (response && response.status_code === StatusCodes.OK)
      notification.success({
        message: 'Success',
        description: 'Settings updated successfully.',
      });
    else
      CustomNotification({
        error: response.errors,
      });
    setIsUpdating(false);
  };
  useEffectOnce(() => {
    fetchAkamaiSettings();
  });
  return (
    <>
      <div className="container-fluid wrapper">
        <div className="row">
          <div className="col-md-6">
            <h2 className="page-title">Akamai Setup</h2>
            <p className="h6 font-weight-normal line-height-26"></p>
          </div>
        </div>
        <Spin spinning={getingDetails}>
          <Form
            form={form}
            onFinish={async (values) => {
              await handleSubmit(values);
            }}
            initialValues={{}}
            onFinishFailed={(errorInfo) => {
              console.log(errorInfo);
            }}
          >
            <div className="card mt-5">
              <div className="card-body card-ch-padding">
                <div className="row">
                  <label className="col-md-4 col-form-label">CP code streaming</label>
                  <Form.Item className="col-md-5" rules={[]} name="streaming_cp_code">
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="CP code streaming" />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">CP code storage</label>
                  <Form.Item className="col-md-5" rules={[]} name="storage_cp_code">
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="CP code storage" />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">Beacon URL</label>
                  <Form.Item className="col-md-5" rules={[]} name="beacon_url">
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="Beacon URL" />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">NetStorage</label>
                  <Form.Item className="col-md-5" rules={[]} name="netstorage">
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="NetStorage" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="text-right">
              <Button loading={isUpdating} disabled={getingDetails || isUpdating} htmlType="submit" className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                Update Settings
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </>
  );
};
export default memo(AkamaiSettings);
