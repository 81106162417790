import {
  faEye,
  faEyeSlash,
  faGlobe,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, notification, Pagination, Tooltip } from "antd";
import stringToHtml from "html-react-parser";
import { StatusCodes } from "http-status-codes";
import { memo, useContext, useState } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { IMSNameParams } from "../../../..";
import {
  CMS_PNG,
  CRM_PNG,
  IQ_PNG,
  UIT_PNG
} from "../../../../../../assets/ims_images";
import { AuthContext } from "../../../../../../context_api/AuthContext";
import { DrawerContext } from "../../../../../../context_api/DrawerContext";
import { DrawerActionTypes } from "../../../../../../context_api/DrawerContext/action-types";
import { IAssignDatastoreToUser } from "../../../../../../interfaces/forms/auth_user/IAuthUser";
import { IDeleteDatastoreServiceForm } from "../../../../../../interfaces/forms/datastore/IDeleteDatastoreService";
import {
  IClientInfoResponse,
  IDatastore
} from "../../../../../../interfaces/responses/clients/IClientInfoResponse";
import { IClientSubAndSuperHostnameDoc } from "../../../../../../interfaces/responses/ims/IGetClientSubAndSuperhostnameResponse";
import auth_user_service from "../../../../../../services/auth_user";
import ClientService from "../../../../../../services/client/index";
import common from "../../../../../../services/common";
import datastoresService from "../../../../../../services/datastores";
import { hasRole } from "../../../../../../utils/Auth/role.auth";
import { ICMSInfo } from "../../../../../../utils/DatastoresMapping";
import {
  AssignDatastoreToUserModal,
  DeleteServiceModal,
  RevokeDatastoreAccessForUserModel
} from "./dashboard_datastore";
type IPaginateObj = {
  page: number;
  per_page: number;
  pre_page: number | null;
  next_page: number | null;
  total: number;
  total_pages: number;
  data: string[];
};
const DeploymentDashboardDatastores = (data: ICMSInfo) => {
  const { cms_services, cms, iq, crm, uit } = data;

  let perPageItems = 20;

  const { state: AuthState } = useContext(AuthContext);
  const { state: DrawerState, dispatch: DrawerDispatch } =
    useContext(DrawerContext);
  const [paginationObject, setPaginationObject] = useState<{
    next_page: number | null;
    current_page: number;
    total_pages: number;
    prev_page: number | null;
    page_size: number;
  }>({
    current_page: 1,
    next_page: null,
    total_pages: 1,
    page_size: perPageItems,
    prev_page: null,
  });
  const [cmsServices, setCMSServices] = useState(() => {
    let paginateObj = handlePaginate(cms_services, 1, perPageItems);
    setPaginationObject({
      current_page: paginateObj.page,
      next_page: paginateObj.next_page,
      page_size: paginateObj.per_page,
      prev_page: paginateObj.pre_page,
      total_pages: paginateObj.total_pages,
    });

    return paginateObj.data;
  });
  const [cmsState, setCMS] = useState<IDatastore | undefined>(cms);
  const [iqState, setIQState] = useState<IDatastore[]>(iq || []);
  const [crmState, setCRMState] = useState<IDatastore[]>(crm || []);
  const [uitState, setUITState] = useState<IDatastore[]>(uit || []);

  const [isShowingModel, setIsShowingModel] = useState<boolean>(false);
  const [isShowingDeleteModel, setIsShowingDeleteModel] =
    useState<boolean>(false);
  const [isShowingDeleteServiceModel, setIsShowingDeleteServiceModel] =
    useState<boolean>(false);

  const [datastorePopupModelObject, setDatastorePopupModelObject] =
    useState<IDatastore>();
  const [deleteDatastoreObject, setDeleteDatastoreObject] =
    useState<IDatastore>();
  const [deleteDatastoreServiceData, setDeleteDatastoreServiceData] =
    useState<IDatastore>();
  const [clientSubAndSuperHostnames, setClientSubAndSuperHostnames] = useState<
    IClientSubAndSuperHostnameDoc[]
  >([]);
  const [gettingSubAndSuperhostnames, setGettingSubAndSuperhostnames] =
    useState<boolean>(true);

  const { ims_name } = useParams<IMSNameParams>();
  const { url } = useRouteMatch();

  const getClientInfo = async () => {
    let ClientInfo: IClientInfoResponse = await ClientService.GetClientInfo(
      ims_name
    );
    if (ClientInfo.status_code === StatusCodes.OK && ClientInfo.data) {
      DrawerDispatch({
        type: DrawerActionTypes.SET_CLIENT_INFO,
        client_info: ClientInfo.data,
      });
      let whiteLabel = await common.FetchWhiteLabelSettings(
        ClientInfo.data.name
      );
      if (whiteLabel)
        DrawerDispatch({
          type: DrawerActionTypes.UPDATE_WHITE_LABEL_INFO,
          ...whiteLabel,
        });
    }
  };

  const handleAuthClick = (data: IDatastore) => {
    setDatastorePopupModelObject(() => data);
    setIsShowingModel(!isShowingModel);
  };

  const handleUnAuthClick = (data: IDatastore) => {
    setDeleteDatastoreObject(() => data);
    setIsShowingDeleteModel(!isShowingDeleteModel);
  };

  const handleSubmit = async (e: any) => {
    let data: IAssignDatastoreToUser = {
      client_id: DrawerState.client_info?._id ?? "",
      datastore_id: datastorePopupModelObject?._id ?? "",
      datastore_type: datastorePopupModelObject?.type ?? "",
      user_id: e.user_id,
    };

    let response = await auth_user_service.AssignDatastoreToUser(data);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: "Success",
        description: "Datastore assigened to user successfully",
      });
      setIsShowingModel(false);
    }
  };

  const handleRevokeModelSubmit = async (e: any) => {
    let data: IAssignDatastoreToUser = {
      client_id: DrawerState.client_info?._id ?? "",
      datastore_id: deleteDatastoreObject?._id ?? "",
      datastore_type: deleteDatastoreObject?.type ?? "",
      user_id: e.user_id,
    };

    let response = await auth_user_service.RevokeDatastoreAccessToUser(data);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: "Success",
        description: `User restricted to datastore successfully`,
      });
      setIsShowingDeleteModel(false);
    }
  };

  const handleDeleteServiceSubmit = async (values: IDatastore) => {
    let data: IDeleteDatastoreServiceForm = {
      client_id: DrawerState.client_info?._id ?? "",
      crm_id: values._id,
    };

    let response = await datastoresService.DeleteDatastoreServices(data);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: "Success",
        description: `${values.name} service deleted successfully`,
      });
      setIsShowingDeleteServiceModel(false);
      getClientInfo();
    }
  };
  const GetClientSubAndSuperhostname = async () => {
    setGettingSubAndSuperhostnames(true);
    let response = await ClientService.getClientSubAndSuperhostname(
      DrawerState.client_info?.name ?? ""
    );
    if (response.status_code === StatusCodes.OK)
      setClientSubAndSuperHostnames(response.data);
    setGettingSubAndSuperhostnames(false);
  };
  //
  const handleSearchInput = async (value: string) => {
    if (value)
      setCMSServices((prevState) => {
        let newArr = [...cms_services];
        let newState = newArr.filter((service) => {
          if (service.startsWith(value)) return service;
        });
        let paginateObj = handlePaginate(
          newState,
          value && paginationObject.current_page > 1
            ? 1
            : paginationObject.current_page,
          paginationObject.page_size
        );
        newState = paginateObj.data;
        setPaginationObject({
          current_page: paginateObj.page,
          next_page: paginateObj.next_page,
          page_size: paginateObj.per_page,
          prev_page: paginateObj.pre_page,
          total_pages: paginateObj.total_pages,
        });

        return newState;
      });
    else {
      setCMSServices(() => {
        let paginateObj = handlePaginate(
          cms_services,
          1,
          paginationObject.page_size
        );
        setPaginationObject({
          current_page: 1,
          next_page: paginateObj.next_page,
          page_size: paginateObj.per_page,
          prev_page: paginateObj.pre_page,
          total_pages: paginateObj.total_pages,
        });

        return paginateObj.data;
      });
      setCMS(cms);
      setIQState([...(iq ?? [])]);
      setUITState([...(uit ?? [])]);
      setCRMState([...(crm ?? [])]);
    }
  };
  function handlePaginate(
    items: string[],
    current_page: number,
    per_page_items: number
  ): IPaginateObj {
    let page = current_page || 1;
    let per_page = per_page_items || perPageItems;
    let offset = (page - 1) * per_page;
    let paginatedItems = items.slice(offset).slice(0, per_page_items);
    let total_pages = Math.ceil(items.length / per_page);

    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: total_pages > page ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems,
    };
  }
  useEffectOnce(() => {
    GetClientSubAndSuperhostname();
  });

  return (
    <>
      <div className="col-md-12 mb-4 slider">
        <div className="row mt-3 justify-content-end">
          {cmsState && (
            <div className={`col-md-2 p-0 mb-2`}>
              <div className="card card-shadow-hover shadow-none h-100">
                <div className="card-body px-4 py-3 border rounded text-center">
                  {hasRole(AuthState.user!, ims_name, [
                    "superadmin",
                    "admin",
                  ]) && (
                    <div
                      className="text-right mb-4 position-absolute"
                      style={{ right: "26px" }}
                    >
                      <Tooltip placement="top" title={`Add System Users`}>
                        <FontAwesomeIcon
                          className="h5 ml-2 icon-gray"
                          icon={faEye}
                          onClick={() => {
                            handleAuthClick(cmsState!);
                          }}
                        />
                      </Tooltip>

                      <Tooltip placement="top" title={`Remove System Users`}>
                        <FontAwesomeIcon
                          className="h5 ml-2 icon-gray"
                          icon={faEyeSlash}
                          onClick={() => {
                            if (cmsState) handleUnAuthClick(cmsState);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  <Link
                    className="d-flex flex-column justify-content-center align-items-center h-100"
                    to={`${url}/cms/${cmsState?.name}`}
                  >
                    <img src={CMS_PNG} title="User" alt="User" />
                    <h5 className="text-primary mb-0 mt-3 font-size-18">CMS</h5>
                    <p className="mb-0 text-break">{cmsState?.name}</p>
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className={`${cmsState ? "col-md-10" : "col-md-12"}`}>
            <div className="row">
              <div className="col-12 mb-2">
                <Input.Search
                  className="col"
                  placeholder="Search"
                  onChange={(e) => {
                    handleSearchInput(e.target.value);
                  }}
                ></Input.Search>
              </div>
              {cmsServices.map((service_name, key) => {
                let crmElement = crmState?.filter(
                  (x) => x.name.toLowerCase() === service_name.toLowerCase()
                );
                let iqElement = iqState?.filter(
                  (x) => x.name.toLowerCase() === service_name.toLowerCase()
                );
                let uitElement = uitState?.filter(
                  (x) => x.name.toLowerCase() === service_name.toLowerCase()
                );
                let authorizedDatastores: IDatastore[] = [];
                if (crmElement && crmElement.length > 0)
                  authorizedDatastores.push(crmElement[0]);

                if (iqElement && iqElement.length > 0)
                  authorizedDatastores.push(iqElement[0]);

                // if (uitElement && uitElement.length > 0)
                //   authorizedDatastores.push(uitElement[0]);

                if (uitElement && uitElement.length > 0)
                  authorizedDatastores.push({
                    _id: `hardcoded`,
                    name: "App Builder",
                    type: "apb",
                    cms_name: uitElement[0].cms_name,
                    cms_ref: uitElement[0]?.cms_ref,
                  });

                return (
                  <div className="col-12 mb-2" key={key}>
                    <div className="row m-0">
                      {authorizedDatastores.map((datastore, index) => {
                        let type = datastore.type.toLowerCase();
                        let img = CRM_PNG;
                        if (type === "iqState") img = IQ_PNG;
                        if (type === "uitState") img = UIT_PNG;

                        return (
                          <div
                            className="col"
                            key={`datastore_${type}_${index}`}
                          >
                            <div className="card shadow-none card-shadow-hover">
                              <div
                                className="card-body px-4 py-3 border rounded text-center"
                                onClick={() => {
                                  DrawerDispatch({
                                    type: DrawerActionTypes.SET_PARENT_SERVICE_INFO,
                                    parent_service_name:
                                      datastore.cms_name ?? "",
                                  });
                                }}
                              >
                                {hasRole(AuthState.user!, ims_name, [
                                  "superadmin",
                                  "admin",
                                ]) && (
                                  <div className="text-right mb-4">
                                    {/* Globe Icon */}
                                    {type === "crmState" ||
                                    type === "uitState" ? (
                                      gettingSubAndSuperhostnames ? (
                                        <></>
                                      ) : (
                                        <Tooltip
                                          placement="top"
                                          title={clientSubAndSuperHostnames
                                            .filter(
                                              (_doc) =>
                                                _doc.crm_name === datastore.name
                                            )[0]
                                            .domains.map((_d) => {
                                              let crmHTML =
                                                "<ul style='text-align:left; padding:5px; font-weight: normal; padding-inline-start:0px; margin-block-start: 0px; margin-block-end: 0px;'>";
                                              crmHTML +=
                                                "<li style='list-style:none; margin-bottom:5px'> <b>Superhost : </b>" +
                                                _d.superhostname_name +
                                                "<br/>";
                                              _d.subDomains.map(
                                                (_subDomain) => {
                                                  crmHTML += "<ul>";

                                                  crmHTML +=
                                                    "<li>" +
                                                    _subDomain +
                                                    "</li>";
                                                  crmHTML += "</ul>";
                                                }
                                              );
                                              crmHTML += "</li>";
                                              crmHTML += "</ul>";
                                              return stringToHtml(crmHTML);
                                            })}
                                        >
                                          <FontAwesomeIcon
                                            className="h5 ml-2 icon-gray float-left"
                                            icon={faGlobe}
                                          />
                                        </Tooltip>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    {/* Authorize eye icons */}
                                    <Tooltip
                                      placement="top"
                                      title={`Add System Users`}
                                    >
                                      <FontAwesomeIcon
                                        className="h5 ml-2 icon-gray"
                                        icon={faEye}
                                        onClick={() => {
                                          handleAuthClick(datastore);
                                        }}
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      placement="top"
                                      title={`Remove System Users`}
                                    >
                                      <FontAwesomeIcon
                                        className="h5 ml-2 icon-gray"
                                        icon={faEyeSlash}
                                        onClick={() => {
                                          handleUnAuthClick(datastore);
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                )}
                                <Link to={`${url}/${type}/${service_name}`}>
                                  <div>
                                    <img
                                      src={img}
                                      title={service_name}
                                      alt={service_name}
                                    />
                                  </div>
                                  <h5 className="text-primary mb-0 mt-3 font-size-18">
                                    {type.toUpperCase()}
                                  </h5>
                                  <p className="mb-0">{service_name}</p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {authorizedDatastores.length > 0 &&
                        hasRole(AuthState.user!, ims_name, [
                          "superadmin",
                          "admin",
                        ]) && (
                          <div
                            className="col-1 d-flex justify-content-center"
                            onClick={() => {
                              if (crmElement && crmElement[0])
                                setDeleteDatastoreServiceData(crmElement[0]);
                              setIsShowingDeleteServiceModel(true);
                            }}
                          >
                            <div className="card w-100 shadow-none rounded py-2 py-md-0 bg-danger-light card-shadow-hover d-flex justify-content-center align-items-center">
                              <FontAwesomeIcon
                                className="h5 mb-0 danger-light"
                                icon={faTimes}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
              {(!cmsServices || cmsServices.length === 0) &&
                cmsState &&
                hasRole(AuthState.user!, ims_name, ["superadmin", "admin"]) && (
                  <div className="col-12 mb-2">
                    <div className="row m-0">
                      <div
                        className="col-1 d-flex justify-content-center"
                        onClick={() => {
                          if (cmsState) setDeleteDatastoreServiceData(cmsState);
                          setIsShowingDeleteServiceModel(true);
                        }}
                      >
                        <div className="card w-100 shadow-none rounded py-2 py-md-0 bg-danger-light card-shadow-hover d-flex justify-content-center align-items-center">
                          <FontAwesomeIcon
                            className="h5 mb-0 danger-light"
                            icon={faTimes}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              <div className="col-12 mb-2 ">
                {paginationObject && (
                  <Pagination
                    hideOnSinglePage
                    onChange={(page, pageSize) => {
                      let paginationObjResult = handlePaginate(
                        cms_services,
                        page,
                        pageSize
                      );
                      setCMSServices(paginationObjResult.data);
                      setPaginationObject((prevState) => {
                        prevState.current_page = paginationObjResult.page;
                        prevState.next_page = paginationObjResult.next_page;
                        prevState.page_size = paginationObjResult.per_page;
                        prevState.prev_page = paginationObjResult.pre_page;
                        prevState.total_pages = paginationObjResult.total_pages;
                        return prevState;
                      });
                    }}
                    defaultCurrent={paginationObject.current_page}
                    pageSize={paginationObject.page_size}
                    total={
                      paginationObject.total_pages * paginationObject.page_size
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {datastorePopupModelObject && isShowingModel && (
        <AssignDatastoreToUserModal
          rowRecord={datastorePopupModelObject}
          visible={isShowingModel}
          onSubmit={(c) => handleSubmit(c)}
          onCancel={() => {
            setIsShowingModel(!isShowingModel);
          }}
          client_id={DrawerState.client_info?._id ?? ""}
        />
      )}

      {deleteDatastoreObject && isShowingDeleteModel && (
        <RevokeDatastoreAccessForUserModel
          rowRecord={deleteDatastoreObject}
          visible={isShowingDeleteModel}
          onSubmit={(c) => handleRevokeModelSubmit(c)}
          onCancel={() => {
            setIsShowingDeleteModel(!isShowingDeleteModel);
          }}
          client_id={DrawerState.client_info?._id ?? ""}
        />
      )}

      {deleteDatastoreServiceData && isShowingDeleteServiceModel && (
        <DeleteServiceModal
          datastore={deleteDatastoreServiceData}
          visible={isShowingDeleteServiceModel}
          onSubmit={handleDeleteServiceSubmit}
          onCancel={() => {
            setIsShowingDeleteServiceModel(!isShowingDeleteServiceModel);
          }}
        />
      )}
    </>
  );
};
export default memo(DeploymentDashboardDatastores);
