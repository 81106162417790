import { IAssignDatastoreToUser } from "../../interfaces/forms/auth_user/IAuthUser";
import { IAssignDataStoreToUserResponse } from "../../interfaces/responses/auth_user/IAssignDatastoreToUserReponse";
import axios from "../../utils/axios";
import { API_ENDPOINTS } from "../_APIConstants";

class AuthUserService {
  AssignDatastoreToUser = (
    data: IAssignDatastoreToUser
  ): Promise<IAssignDataStoreToUserResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.AuthUser.ASSIGN_DATASTORE_TO_USER, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  RevokeDatastoreAccessToUser = (
    data: IAssignDatastoreToUser
  ): Promise<IAssignDataStoreToUserResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.AuthUser.REVOKE_DATASTORE_ACCESS_TO_USER, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new AuthUserService();
