import { Button, Form, Image, Input, notification, Spin, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import { StatusCodes } from "http-status-codes";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { UploadPNG } from "../../../assets/ims_images";
import { CustomNotification } from "../../../common/components/Notification";
import { DrawerContext } from "../../../context_api/DrawerContext";
import { DrawerActionTypes } from "../../../context_api/DrawerContext/action-types";
import { IFileUpload } from "../../../interfaces/responses/common/IFileUploadResult";
import common from "../../../services/common";
import ims from "../../../services/ims";
import { IMSNameParams } from "../../ims";

const WhitelabelSettings = () => {
  const [form] = useForm();
  const [isUpdating, setIsUpdating] = useState(false);
  const [getingDetails, setGetingDetails] = useState(true);
  const { dispatch: DrawerDispatch, state: DrawerState } =
    useContext(DrawerContext);
  const [details, setDetails] = useState<{
    fav_icon?: string;
    logo?: string;
  }>({});
  const { ims_name } = useParams<IMSNameParams>();
  const [uploadedLogoFileResponseData, setUploadedLogoFileResponseData] =
    useState<IFileUpload>({
      name: "",
    });
  const [uploadedFavIconFileResponseData, setUploadedFavIconResponseData] =
    useState<IFileUpload>({
      name: "",
    });
  const handleCustomRequest = async (options: any, type: string) => {
    let response = await common.UploadClientCommonFile({
      media_file: options.file,
      dimensions: [],
      query_params: {
        instance: ims_name,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      switch (type) {
        case "logo":
          form.setFieldsValue({
            logo: response.data.name,
          });
          setUploadedLogoFileResponseData(response.data);
          break;
        case "fav_icon":
          form.setFieldsValue({
            fav_icon: response.data.name,
          });
          setUploadedFavIconResponseData(response.data);
          break;
      }
      options.onSuccess(null, response.data);
    } else options.onError(() => "An error occured");
  };
  const fileValueFormEvent = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const fetchWhiteLabelSettings = async () => {
    setGetingDetails(true);
    let response = await ims.getWhiteLabelDetails({
      client_name: ims_name,
    });
    if (response && response.status_code === StatusCodes.OK) {
      form.setFieldsValue(response.data);
      setDetails(response.data);

      DrawerDispatch({
        type: DrawerActionTypes.UPDATE_WHITE_LABEL_INFO,
        fav_icon: response.data.fav_icon,
        logo: response.data.logo,
      });
    }
    setGetingDetails(false);
  };
  const handleClear = async (type: string) => {
    setIsUpdating(true);
    let response = await ims.addUpdateWhiteLabel({
      ...(type === "logo" ? { logo: "" } : {}),
      ...(type === "fav_icon" ? { fav_icon: "" } : {}),
      client_name: ims_name,
    });

    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: "Success",
        description: `${
          type === "logo" ? "Logo " : "Fav Icon"
        } cleared successfully.`,
      });
      fetchWhiteLabelSettings();
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsUpdating(false);
  };
  const handleSubmit = async (values: any) => {
    setIsUpdating(true);
    let response = await ims.addUpdateWhiteLabel({
      ...(uploadedLogoFileResponseData.name && {
        logo: uploadedLogoFileResponseData.name,
      }),
      ...(uploadedFavIconFileResponseData.name && {
        fav_icon: uploadedFavIconFileResponseData.name,
      }),
      client_name: ims_name,
    });

    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: "Success",
        description: "Settings updated successfully.",
      });
      fetchWhiteLabelSettings();
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsUpdating(false);
  };
  useEffectOnce(() => {
    fetchWhiteLabelSettings();
  });
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6">
          <h2 className="page-title">White Label Your TVMS</h2>
          <p className="h6 font-weight-normal line-height-26">
            Using White Label Service you can customize the look and feel of the
            TVMS as per your need. You can add your own logo and change the
            favicon.
          </p>
        </div>
      </div>
      <Spin spinning={getingDetails || isUpdating}>
        <Form
          form={form}
          onFinish={async (values) => {
            await handleSubmit(values);
          }}
          initialValues={{}}
          onFinishFailed={(errorInfo) => {
            console.log(errorInfo);
          }}
        >
          <div className="card mt-5">
            <div className="card-body card-ch-padding">
              <div className="row">
                <label className="col-md-4 col-form-label">
                  Custom logo (Max Height = 75px)
                </label>

                <Form.Item
                  className="col-md-5"
                  rules={[]}
                  name="logo_file"
                  valuePropName="fileList"
                  getValueFromEvent={fileValueFormEvent}
                >
                  <Upload.Dragger
                    listType="picture"
                    defaultFileList={[]}
                    className="default-upload"
                    maxCount={1}
                    multiple={false}
                    accept=".png"
                    customRequest={(options) => {
                      handleCustomRequest(options, "logo");
                    }}
                  >
                    <div className="media d-inline-flex align-items-center">
                      <img src={UploadPNG} className="mr-3" alt="upload" />
                      <div className="media-body text-left my-3">
                        <h6 className="mt-0 text-primary">Upload your File</h6>
                        <p className="mb-0 h6 icon-gray font-weight-normal">
                          Drag files here or
                          <span className="text-secondary"> Browse</span>
                        </p>
                      </div>
                    </div>
                  </Upload.Dragger>
                </Form.Item>
                {details && details.logo && (
                  <Form.Item
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                    hidden={
                      uploadedLogoFileResponseData?.name &&
                      uploadedLogoFileResponseData?.name !== ""
                        ? true
                        : false
                    }
                    shouldUpdate={(prevValue: any, nextValue: any) => true}
                  >
                    <Image
                      src={details?.logo}
                      width="75px"
                      height="75px"
                      preview={false}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="logo"
                  shouldUpdate={(pre, current) => pre.value !== current.value}
                  children={() => (
                    <Input hidden>{uploadedLogoFileResponseData.name}</Input>
                  )}
                ></Form.Item>
                <label className="col-md-8 col-form-label"></label>
                <Button
                  disabled={getingDetails || isUpdating}
                  htmlType="button"
                  onClick={async () => {
                    await handleClear("logo");
                  }}
                  className="m-3 ant-btn btn btn-secondary rounded-pill width-240 height-48"
                >
                  Clear logo
                </Button>
              </div>
              <br />
              <div className="row">
                <label className="col-md-4 col-form-label">
                  Fav Icon (Size = 32x32)
                </label>

                <Form.Item
                  className="col-md-5"
                  rules={[]}
                  name="fav_icon_file"
                  valuePropName="fileList"
                  getValueFromEvent={fileValueFormEvent}
                >
                  <Upload.Dragger
                    listType="picture"
                    defaultFileList={[]}
                    className="default-upload"
                    maxCount={1}
                    multiple={false}
                    accept=".png"
                    customRequest={(options) => {
                      handleCustomRequest(options, "fav_icon");
                    }}
                  >
                    <div className="media d-inline-flex align-items-center">
                      <img src={UploadPNG} className="mr-3" alt="upload" />
                      <div className="media-body text-left my-3">
                        <h6 className="mt-0 text-primary">Upload your File</h6>
                        <p className="mb-0 h6 icon-gray font-weight-normal">
                          Drag files here or
                          <span className="text-secondary"> Browse</span>
                        </p>
                      </div>
                    </div>
                  </Upload.Dragger>
                </Form.Item>
                {details && details.fav_icon && (
                  <Form.Item
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                    hidden={
                      uploadedFavIconFileResponseData?.name &&
                      uploadedFavIconFileResponseData?.name !== ""
                        ? true
                        : false
                    }
                    shouldUpdate={(prevValue: any, nextValue: any) => true}
                  >
                    <Image
                      src={details?.fav_icon}
                      width="32px"
                      height="32px"
                      preview={false}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="fav_icon"
                  shouldUpdate={(pre, current) => pre.value !== current.value}
                  children={() => (
                    <Input hidden>{uploadedFavIconFileResponseData.name}</Input>
                  )}
                ></Form.Item>
                <label className="col-md-8 col-form-label"></label>
                <Button
                  disabled={getingDetails || isUpdating}
                  htmlType="button"
                  onClick={async () => {
                    await handleClear("fav_icon");
                  }}
                  className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48"
                >
                  Clear Fav Icon
                </Button>
              </div>
            </div>
          </div>
          <div className="text-right">
            <Button
              loading={isUpdating}
              disabled={getingDetails || isUpdating}
              htmlType="submit"
              className="m-3 ant-btn btn btn-secondary rounded-pill width-240 height-48"
            >
              Update Settings
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
export default WhitelabelSettings;
