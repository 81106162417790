import { Card } from "antd";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { NewDeployementIcon } from "../../../../../assets/ims_images";

export function NewDeployment() {
  const { url } = useRouteMatch();

  return (
    <div className="row">
      <div className="col-12 mb-2">
        <Card hoverable className="p-0" bodyStyle={{ padding: "0px" }}>
          <div className="py-4 p-5 border-left border-secondary border-width-4">
            <div className="row">
              <div className="col-md-3 col-lg-2 col-xl-1">
                <img src={NewDeployementIcon} title="User" alt="User" />
              </div>
              <div className="col-md-9 col-lg-6 col-xl-6 pl-xl-5 mt-3 mt-lg-0">
                <h4 className="text-primary">New Deployment</h4>
                <p className="h6 font-weight-normal line-height-26">
                  The IMS supports multitenancy for your Deployments by
                  providing separate Services dor multiple OTT/IPTV service
                  organisations.
                </p>
              </div>
              <div className="col-md-12 col-lg-4 col-xl-5 d-flex justify-content-lg-end justify-content-center align-items-center mt-3 mt-lg-0">
                <Link
                  className="btn btn-secondary rounded-pill btn-default"
                  to={`${url}/new_deployment`}
                >
                  Create New Deployment
                </Link>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
