import { Form, notification, Steps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { StatusCodes } from 'http-status-codes';
import { memo, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DrawerContext } from '../../../../../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../../../../../context_api/DrawerContext/action-types';
import { IAddCompanyInfo } from '../../../../../../interfaces/forms/areas/crm/settings/IAddCompanyInfo';
import { IAddNewServiceForm } from '../../../../../../interfaces/forms/datastore/IAddNewServiceWithCompanyInfo';
import { IClientInfoResponse } from '../../../../../../interfaces/responses/clients/IClientInfoResponse';
import { IFileUpload } from '../../../../../../interfaces/responses/common/IFileUploadResult';
import ClientService from '../../../../../../services/client';
import common from '../../../../../../services/common';
import datastores from '../../../../../../services/datastores';
import { StepCongrats, StepInstanceSettings, StepLaunchChecklist, StepSetup } from './new_datastore_service/index';

const { Step } = Steps;
interface INewDatastoreServiceFormParams {
  cms_datastore_id: string;
  ims_name: string;
}
enum EnumSteps {
  LAUNCH_CHECKLIST = 0,
  INSTANCE_SETTINGS = 1,
  CMS_CRM_SETUP = 2,
  CONGRATS = 3,
}
const NewDatastoreServiceForms = () => {
  const params = useParams<INewDatastoreServiceFormParams>();
  let history = useHistory();
  let [multiStepperForm] = useForm();

  const [currentStep, setCurrentStep] = useState(0);
  const [uploadedFileData, setUploadedFileData] = useState<IFileUpload>({
    name: '',
  });

  const [uploadedFile, setUploadedFile] = useState<UploadFile>();
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [datastoreObjectState, setDatastoreObjectState] = useState<IAddNewServiceForm>();
  const [isSettingUpDataStore, setIsSettingUpDataStore] = useState<boolean>(true);
  const [AddedCurrenciesProp, setAddedCurrenciesProp] = useState<any>([]);
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  const { client_info } = DrawerState;

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const getClientInfo = async () => {
    let ClientInfo: IClientInfoResponse = await ClientService.GetClientInfo(params.ims_name);
    if (ClientInfo.status_code === StatusCodes.OK && ClientInfo.data) {
      DrawerDispatch({
        type: DrawerActionTypes.SET_CLIENT_INFO,
        client_info: ClientInfo.data,
      });
      let whiteLabel = await common.FetchWhiteLabelSettings(ClientInfo.data.name);
      if (whiteLabel)
        DrawerDispatch({
          type: DrawerActionTypes.UPDATE_WHITE_LABEL_INFO,
          ...whiteLabel,
        });
    }
  };

  const handleSubmit = async (e: any) => {
    setCurrentStep(currentStep + 1);
    if (currentStep === EnumSteps.CMS_CRM_SETUP) {
      let values = e as IAddCompanyInfo;
      let response = await datastores.AddNewServiceAndCompanyInfoWithCMS({
        datastore_object: {
          client_id: client_info?._id || '',
          client_name: client_info?.name || '',
          crm_name: datastoreObjectState?.crm_name || '',
          cms_id: params.cms_datastore_id,
        },
        company_data_object: { ...values, company_logo: uploadedFileData.name },
        currencies: multiStepperForm.getFieldValue('currencies')?.map((x: any) => JSON.parse(x)),
      });
      if (response.status_code === StatusCodes.OK) {
        setIsSettingUpDataStore(() => false);
        notification.success({
          message: 'Success',
          description: '',
        });
        setIsFinished(true);
        getClientInfo();
      } else {
        setCurrentStep(EnumSteps.CMS_CRM_SETUP);
        notification.error({
          message: 'An Error Ocurred',
          description: response.errors,
        });
      }
    }
    if (currentStep === EnumSteps.INSTANCE_SETTINGS) {
      let values = e as IAddNewServiceForm;
      setDatastoreObjectState(values);
      let response = await datastores.checkForDatastoreNameUniqueness({
        crm_name: values.crm_name,
      });
      if (response.status_code !== StatusCodes.OK) {
        notification.error({
          message: 'An error occured',
          description: 'CRM with this name already exists',
        });
        setCurrentStep(EnumSteps.INSTANCE_SETTINGS);
      }
    }
  };

  const onCompanyImageUpload = (data: IFileUpload) => {
    if (data) setUploadedFileData(data);
  };
  useEffect(() => {
    if (currentStep > EnumSteps.CONGRATS) history.push(`/ims/${params.ims_name}`);
  }, [currentStep]);
  return (
    <div className="p-4 my-2">
      <div className="row">
        <div className="col-12 mb-2">
          <div className="card">
            <div className="card-body  py-4">
              <div className="row">
                <div className="col-12">
                  <Form form={multiStepperForm} onFinish={handleSubmit} onFinishFailed={(info) => {}}>
                    <div>
                      <Steps type="navigation" current={currentStep}>
                        <Step title="Launch Checklist" disabled />
                        <Step title="Instance Settings" disabled />
                        <Step title="CMS/CRM Setup" disabled />
                        <Step title="Congrats!" disabled />
                      </Steps>
                      <div className="steps-content bg-light p-4">
                        {currentStep === EnumSteps.LAUNCH_CHECKLIST && <StepLaunchChecklist />}
                        {currentStep === EnumSteps.INSTANCE_SETTINGS && <StepInstanceSettings />}

                        {currentStep === EnumSteps.CMS_CRM_SETUP && (
                          <StepSetup
                            form={multiStepperForm}
                            AddedCurrenciesProp={AddedCurrenciesProp}
                            setAddedCurrenciesProp={(values: any) => setAddedCurrenciesProp(values)}
                            onDragUpload={(file?: UploadFile<any>) => setUploadedFile(file)}
                            uploaded_file={uploadedFile}
                            uploaded_file_result={uploadedFileData}
                            onServerUpload={onCompanyImageUpload}
                          />
                        )}
                        {currentStep === EnumSteps.CONGRATS && <StepCongrats isLoading={isSettingUpDataStore} />}
                      </div>
                      <div className="steps-action mt-2">
                        {currentStep > EnumSteps.LAUNCH_CHECKLIST && !isFinished && (
                          <button type="button" className="btn btn-secondary float-left width-120" onClick={prevStep}>
                            Previous
                          </button>
                        )}
                        {currentStep === EnumSteps.CONGRATS ? (
                          <button type="submit" className="btn btn-secondary float-right width-120">
                            Finish
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-secondary float-right width-120">
                            Next
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NewDatastoreServiceForms);
