import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IUserClient } from "../../../../../../interfaces/responses/user/IUserInfoResponse";
const IMSClient = (props: IUserClient) => {
  const { client_name, client_id } = props;
  if (client_name === "") {
    return (
      <div id={`${client_id}`}>
        <FontAwesomeIcon icon={faPlusCircle} /> Add New Client
      </div>
    );
  }
  return <div id={`${client_id}`}>{client_name}</div>;
};

export default IMSClient;
