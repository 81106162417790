export interface IPaginatedAndFilterResponse {
  pageNumber: number;
  pageSize: number;
  count: number;
}
export const PaginatedAndFilterResponseDefaultValues = {
  pageNumber: 1,
  pageSize: 50,
  count: 0,
};
