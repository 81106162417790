import { Layout } from "antd";
import { Switch } from "react-router-dom";
import HomeDashboardHeader from "../../common/components/HomeDashboardHeader";
import PageNotFound from "../../common/components/PageNotFound";
import PrivateRoute from "../../common/routes/PrivateRoute";
import { AddSystemUser, ListSystemUsers, SystemUserDetails } from "../user";
import AddNewClient from "./pages/add_new_client";
import AllClients from "./pages/clients";

const HomeDashboard = () => {
  return (
    <Layout>
      <Layout>
        <HomeDashboardHeader />
        <section className="content">
          <div className=" m-auto p-0">
            <Switch>
              <PrivateRoute
                path="/add-new-client"
                component={AddNewClient}
              ></PrivateRoute>
              <PrivateRoute
                path="/system-users/details/:user_id"
                component={SystemUserDetails}
              ></PrivateRoute>
              <PrivateRoute
                path="/system-users/add-user"
                component={AddSystemUser}
              ></PrivateRoute>
              <PrivateRoute
                path="/system-users"
                component={ListSystemUsers}
              ></PrivateRoute>
              <PrivateRoute
                path="/"
                exact
                component={AllClients}
              ></PrivateRoute>
              <PrivateRoute
                path={`/*`}
                component={() => <PageNotFound path="" />}
              ></PrivateRoute>
            </Switch>
          </div>
        </section>
        {/* <SiteFooter /> */}
      </Layout>
    </Layout>
  );
};

export default HomeDashboard;
