import Icon from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useClickAway, useEffectOnce } from 'react-use';
import { MWare_Logo, MWare_Logo_Collapsed } from '../../../assets/ims_images';
import { DrawerContext } from '../../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../../context_api/DrawerContext/action-types';
import { IRoute } from '../../../routes/AllAreaRoutes';

const { Sider } = Layout;
const { SubMenu } = Menu;

const LeftSidebarPanel = () => {
  const ref = useRef(null);
  const { url } = useRouteMatch();
  const [parentUrl, setParentUrl] = useState(url);
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  // const [latestOpenKey, setLatestOpenKey] = useState(
  //   DrawerState.defaultOpenKey!
  // );
  useClickAway(ref, () => {
    document.body.classList.remove('mobile-sider');
  });
  useEffectOnce(() => {
    setParentUrl(`${url}/${DrawerState.service_type}/${DrawerState.service_name}`);
  });

  const SidebarMenu = (routes: IRoute[]) =>
    routes.map((menu: IRoute) => {
      if (menu.routes) {
        return (
          <SubMenu
            key={menu.key}
            title={
              <span key={menu.key}>
                <Icon className="hideon-hover" component={() => <img alt="submenu-hideon" src={menu.icon} />} />
                <Icon className="d-none hover-icon " component={() => <img alt="submenu-d-none" src={menu.icon_hover} />} />

                <span className="ml-2">{menu.title}</span>
              </span>
            }
          >
            {menu.routes.map((sub: IRoute, index) => {
              if (sub.routes) {
                return <span key={index}> {SidebarMenu(sub.routes)}</span>;
              } else {
                return (
                  <Menu.Item key={sub.key} hidden={sub.is_invisible}>
                    <Link to={`${parentUrl}/${sub.path}`}>{sub.title}</Link>
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item className="height-60 d-flex align-items-center" key={menu.key}>
            <Icon className="hideon-hover" component={() => <img alt="submenu-hideon" src={menu.icon} />} />
            <Icon className="d-none hover-icon " component={() => <img alt="submenu-d-none" src={menu.icon_hover} />} />

            <Link to={`${parentUrl}/${menu.path}`}>
              <span>{menu.title}</span>
            </Link>
          </Menu.Item>
        );
      }
    });
  const hideDrawer = () => {
    document.body.classList.remove('mobile-sider');
    DrawerDispatch({
      type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
      default_selected_key: '',
      default_open_key: '',
      routes: [],
      service_name: '',
      service_type: '',
      client_dashboard_path: '',
    });
  };
  // const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
  //   const latestOpenKey = keys.find(
  //     (key) => [DrawerState.defaultOpenKey].indexOf(key) === -1
  //   );
  //   if ([DrawerState.defaultSelectedKey].indexOf(latestOpenKey!) === -1) {
  //     console.log(keys, "keys");
  //     setLatestOpenKey(keys[keys.length - 1])
  //     // if (keys[keys.length - 1])
  //     //   DrawerDispatch({
  //     //     type: DrawerActionTypes.SET_LATEST_OPEN_KEY,
  //     //     default_open_key: keys[keys.length - 1],
  //     //   });
  //   } else {
  //     // DrawerDispatch({
  //     //   type: DrawerActionTypes.SET_LATEST_OPEN_KEY,
  //     //   default_open_key: latestOpenKey || "",
  //     // });
  //     console.log(latestOpenKey, "latestOpenKey");
  //     // DrawerDispatch({
  //     //   type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
  //     //   default_open_key: latestOpenKey || "",
  //     //   default_selected_key: DrawerState.defaultSelectedKey!,
  //     //   client_dashboard_path: DrawerState.client_dashboard_path,
  //     //   routes: DrawerState.routes!,
  //     //   service_name: DrawerState.service_name,
  //     //   service_type: DrawerState.service_type,
  //     // });
  //   }
  // };
  // useEffect(() => {
  //   setLatestOpenKey(DrawerState.defaultOpenKey!);
  // }, [DrawerState.defaultOpenKey]);
  return (
    <Sider ref={ref} width="310" trigger={null} collapsible collapsed={false}>
      <div className="logo">
        <Link to={`${url}`} onClick={hideDrawer} key="Mware">
          <img
            className="expand"
            src={DrawerState.white_labeling.logo || MWare_Logo}
            title="Mware"
            alt="Mware"
            style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}}
          />
        </Link>
        <Link to={`${url}`} onClick={hideDrawer} key="MwareCollapsed">
          <img
            className="collapsed"
            src={DrawerState.white_labeling.fav_icon || MWare_Logo_Collapsed}
            title="Mware"
            alt="Mware"
            style={DrawerState.white_labeling.fav_icon ? { maxWidth: 50, maxHeight: 50 } : {}}
          />
        </Link>
      </div>
      <Menu
        className="mt-4"
        mode="inline"
        defaultSelectedKeys={[DrawerState.defaultSelectedKey!]}
        // defaultOpenKeys={[DrawerState.defaultOpenKey!]}
        defaultOpenKeys={
          DrawerState.defaultOpenKey === 'all' && DrawerState.routes && DrawerState.routes?.length > 0
            ? DrawerState.routes.filter((x) => x.key).map((x) => x.key!)
            : [DrawerState.defaultOpenKey!]
        }
        // onOpenChange={onOpenChange}
      >
        {SidebarMenu(DrawerState.routes ?? [])}
      </Menu>
    </Sider>
  );
};

const LeftSidebar = React.memo(LeftSidebarPanel);

export default LeftSidebar;
