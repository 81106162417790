import { Button, Form, Input, notification } from "antd";
import { StatusCodes } from "http-status-codes";
import { useState } from "react";
import { CustomNotification } from "../../../../common/components/Notification";
import clientService from "../../../../services/client";

const AddNewClient = () => {
  const [isAddingClient, setIsAddingClient] = useState(false);
  const onFinish = async (values: any) => {
    setIsAddingClient(true);
    let response = await clientService.addNewClient(values);
    if (response.status_code === StatusCodes.OK)
      notification.success({
        message: "Success",
        description: "Client added successfully",
      });else CustomNotification({
        error:response.errors
      })
    setIsAddingClient(false);
  };

  return (
    <>
      <div style={{ background: "white", margin: "30px", padding: "30px" }}>
        <h1 style={{ padding: "5px" }}>Add New Client</h1>
        <Form onFinish={onFinish} onError={(errors) => {}}>
          <div className="row mt-5">
            <label className="col-md-3 col-form-label">Name</label>
            <Form.Item
              name="name"
              className="col-md-5"
              rules={[
                {
                  required: true,
                  message: "Name is required!",
                },
              ]}
            >
              <Input
                className="form-control bg-white font-size-14 text-default"
                placeholder="Name"
              />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-3 col-form-label">Alias Name</label>
            <Form.Item
              name="alias_name"
              className="col-md-5"
              rules={[
                {
                  required: true,
                  message: "Alias name is required!",
                },
              ]}
            >
              <Input
                className="form-control bg-white font-size-14 text-default"
                placeholder="Alias Name"
              />
            </Form.Item>
          </div>

          <div className="row">
            <div className="col-8">
              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  loading={isAddingClient}
                  disabled={isAddingClient}
                  type="primary"
                  htmlType="submit"
                  className="rounded-pill width-240 height-54"
                >
                  <span className="px-5 font-weight-bold">Add User</span>
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddNewClient;
