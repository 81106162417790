import { notification } from "antd";

interface IError {
  field: string;
  message: string;
}
const parseError = (error: any) => {
  if (Array.isArray(error)) {
    let message = "";
    error.forEach((x) => {
      if (typeof x === "string") {
        let obj: IError = JSON.parse(x);
        return (message += obj.message + ",");
      } else message += x.message + ",";
    });
  } else {
    let obj: any = JSON.parse(error);
    if (obj && obj.message) {
      return obj.message;
    } else return obj;
  }
};

type IErrorNotificationProps = {
  error: any;
  title?: string;
};
export const CustomNotification = (props: IErrorNotificationProps) => {
  let { error, title } = props;
  let description = "";
  if (error) {
    if (typeof error === "string") {
      if (error !== "") description = error;
      else description = "An error occurred";
    } else {
      if (Array.isArray(error)) {
        error.forEach((err) => {
          if (typeof err === "string") description += err + ",";
          else {
            description = description + err.message;
            description += error.length > 1 ? "," : "";
            if (description.includes(",") && error.length === 1) {
              description.replace(",", "");
            }
          }
        });
      } else description = parseError(error);
    }
  } else description = "An error occurred";
  return notification.error({
    message: title || "Failure",
    description: description,
  });
};
