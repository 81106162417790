import { ICMSQueryParams } from '../../interfaces/forms/common/ICMSQueryParams';
import { IAddDatastoreForm } from '../../interfaces/forms/datastore/IAddDatastore';
import { IAddDatastoreWithCompanyInfo } from '../../interfaces/forms/datastore/IAddDatastoreWithCompanyInfo';
import { IAddNewServiceWithCompanyInfo } from '../../interfaces/forms/datastore/IAddNewServiceWithCompanyInfo';
import { ICheckDatastoreNameUniqueness } from '../../interfaces/forms/datastore/ICheckDatastoreNameUniqueness';
import { IDeleteDatastoreServiceForm } from '../../interfaces/forms/datastore/IDeleteDatastoreService';
import { IAddDatastoreResponse } from '../../interfaces/responses/datastore/IAddDatastoreResponse';
import { ICheckDatastoreNameUniquenessResponse } from '../../interfaces/responses/datastore/ICheckDatastoreNameUniquenessResponse';
import { IGetCRMListResponse } from '../../interfaces/responses/datastore/IGetAllCRMListResponse';
import { IGetCRMCustomerStatsResponse } from '../../interfaces/responses/datastore/IGetCRMCustomerStatsResponse';
import { default as axios, default as axiosInstance } from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';

class DatastoreService {
  AddDatastores = (data: IAddDatastoreForm): Promise<IAddDatastoreResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.Datastore.ADD_DATASTORE, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  AddDatastoresWithCompanyInfo = (data: IAddDatastoreWithCompanyInfo) => {
    return axios
      .post(API_ENDPOINTS.V1.Datastore.ADD_DATASTORE_WITH_COMPANY_DATA, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  AddNewServiceAndCompanyInfoWithCMS = (data: IAddNewServiceWithCompanyInfo) => {
    return axios
      .post(API_ENDPOINTS.V1.Datastore.ADD_NEW_SERVICE_AND_COMPANY_DATA_WITH_CMS, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  DeleteDatastoreServices = (data: IDeleteDatastoreServiceForm): Promise<IAddDatastoreResponse> => {
    return axios
      .delete(API_ENDPOINTS.V1.Datastore.DELETE_DATASTORE_SERVICE, {
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  checkForDatastoreNameUniqueness = (data: ICheckDatastoreNameUniqueness): Promise<ICheckDatastoreNameUniquenessResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.Datastore.DATASTORE_NAME_UNIQUENESS_CHECK, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  GetAllDatastores = (data: IAddDatastoreForm): Promise<IAddDatastoreResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.Datastore.ADD_DATASTORE, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  GetAllCRMList = (instance: string): Promise<IGetCRMListResponse> => {
    return axios
      .get(API_ENDPOINTS.V1.Datastore.GET_ALL_CRM_LIST, {
        params: {
          instance,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  // stats for ims homepage
  //stats
  getCustomerAccountStats = async (data: ICMSQueryParams): Promise<IGetCRMCustomerStatsResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.Datastore.GET_CRM_DATASTORE_CUSTOMER_STATS, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
}
export default new DatastoreService();
