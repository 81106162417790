import Icon from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Switch,
  Table,
  Tooltip,
  Upload
} from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { StatusCodes } from "http-status-codes";
import _ from "lodash";
import { FormInstance } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom";
import { IMSNameParams } from "../../../../..";
import { DeleteSVG, UploadPNG } from "../../../../../../../assets/ims_images";
import { IAddNewCurrency } from "../../../../../../../interfaces/responses/areas/cms/settings/currencies/IAddNewCurrency";
import { ICountry } from "../../../../../../../interfaces/responses/common/ICountriesResponse";
import { IFileUpload } from "../../../../../../../interfaces/responses/common/IFileUploadResult";
import common from "../../../../../../../services/common";
import ValidatePhoneNumber from "../../../../../../../utils/Yup/phone-validation";
import AddNewCurrencyModal from "./add_new_currency_modal";

type IStepSetupProps = {
  uploaded_file_result?: IFileUpload;
  onServerUpload: (data: IFileUpload) => void;
  uploaded_file?: UploadFile;
  onDragUpload: (file: UploadFile | undefined) => void;
  form: FormInstance<any>;
  AddedCurrenciesProp: any[];
  setAddedCurrenciesProp: (values: any) => void;
};
const StepSetup = ({
  uploaded_file_result,
  onServerUpload,
  uploaded_file,
  onDragUpload,
  form,
  AddedCurrenciesProp,
  setAddedCurrenciesProp,
}: IStepSetupProps) => {
  const { ims_name } = useParams<IMSNameParams>();

  const handleCustomRequest = async (options: any) => {
    let response = await common.UploadFile({
      media_file: options.file,
      dimensions: [{ ratio_height: 1, ratio_width: 1 }],
      query_params: {
        instance: ims_name,
        datastore: form.getFieldValue("crm_name") ?? "",
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      onServerUpload(response.data);
      options.onSuccess(null, response.data);
      form.setFieldsValue({
        company_logo: response.data.name,
      });
    } else options.onError(() => "An Error Occurred");
  };
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [openAddNewCurrencyModal, setOpenAddNewCurrencyModal] =
    useState<boolean>(false);
  const [added_currencies, setAdded_currencies] =
    useState<IAddNewCurrency[]>(AddedCurrenciesProp);
  const fileValueFormEvent = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  useEffect(() => {
    (() => {
      let _index = _.findIndex(added_currencies, {
        is_default: true,
      });
      if (_index === -1 && added_currencies.length > 0)
        added_currencies[0].is_default = true;

      form.setFieldsValue({
        currencies: added_currencies.map((v) => JSON.stringify(v)),
      });
      setAddedCurrenciesProp(added_currencies);
    })();
  }, [added_currencies]);
  const fetchCountries = async () => {
    let response = await common.FetchCountries();
    if (response && response.status_code === StatusCodes.OK) {
      setCountries(response.data.countries);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (isLoading) (() => fetchCountries())();
  }, [isLoading, setIsLoading]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h4 className="text-primary">Company Information</h4>
          <p>Add your company details for your service.</p>
          <div className="row ml-5">
            <div className="col-md-6">
              <label className="col-form-label">Company</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "The Company field is required!",
                  },
                ]}
              >
                <Input
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="Company"
                />
              </Form.Item>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <label className="col-md-4 col-form-label">Street</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="street"
                rules={[
                  {
                    required: true,
                    message: "The Street field is required!",
                  },
                ]}
              >
                <Input
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="Street"
                />
              </Form.Item>
              <label className="col-md-4 col-form-label">City</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "The City field is required!",
                  },
                ]}
              >
                <Input
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="City"
                />
              </Form.Item>
              <label className="col-md-4 col-form-label">Phone</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "The Telephone field is required!",
                  },
                  {
                    validator: async (rule, value) => {
                      if (value && ValidatePhoneNumber("+" + value)) {
                        return await Promise.resolve();
                      } else
                        return await Promise.reject(
                          Error("Mobile Number is not in correct format.")
                        );
                    },
                  },
                ]}
              >
                <PhoneInput
                  inputClass="ant-input form-control w-100 rounded  bg-white font-size-14 text-default"
                  country={"us"}
                />
              </Form.Item>
              <label className="col-md-4 col-form-label">General Email</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="general_email"
                rules={[
                  {
                    required: true,
                    message: "The General Email field is required!",
                  },
                ]}
              >
                <Input
                  type="email"
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="General Email"
                />
              </Form.Item>
              <p className="col-12">
                Used for sending service/system emails from this service.
              </p>
              <label className="col-md-4 col-form-label">Content Email</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="content_email"
                rules={[
                  {
                    required: true,
                    message: "The Content Email field is required!",
                  },
                ]}
              >
                <Input
                  type="email"
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="Content Email"
                />
              </Form.Item>
              <p className="col-12">
                Used for sending emails regarding problem reports for movies
                and/or tv channels.
              </p>
              <label className="col-md-4 col-form-label">Company Logo (1:1)</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="company_logo_file"
                valuePropName="fileList"
                getValueFromEvent={fileValueFormEvent}
                rules={[
                  {
                    required: true,
                    message: "Company Logo is required",
                  },
                ]}
              >
                <Upload.Dragger
                  listType="picture"
                  defaultFileList={
                    uploaded_file ? [uploaded_file] : new Array<UploadFile>()
                  }
                  className="default-upload"
                  name="files"
                  maxCount={1}
                  multiple={false}
                  accept=".png,.jpg,.jpeg"
                  customRequest={(options) => {
                    handleCustomRequest(options);
                  }}
                  onRemove={() => {
                    onDragUpload(undefined);
                    form.setFieldsValue({
                      company_logo: "",
                    });
                  }}
                >
                  <div className="media d-inline-flex align-items-center height-120">
                    <img src={UploadPNG} className="mr-3" alt="upload" />
                    <div className="media-body text-left my-3">
                      <h6 className="mt-0 text-primary">Upload your File</h6>
                      <p className="mb-0 h6 icon-gray font-weight-normal">
                        Drag files here or
                        <span className="text-secondary"> Browse</span>
                      </p>
                    </div>
                  </div>
                </Upload.Dragger>
              </Form.Item>
            </div>
            <Form.Item
              name="company_logo"
              shouldUpdate={(pre, current) => pre.value !== current.value}
              children={
                <Input hidden value={uploaded_file_result?.name}></Input>
              }
            ></Form.Item>
            <div className="col-md-6">
              <label className="col-md-4 col-form-label">Zip</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="zip"
                rules={[
                  {
                    required: true,
                    message: "The Postal field is required!",
                  },
                ]}
              >
                <Input
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="Zip"
                />
              </Form.Item>
              <label className="col-md-4 col-form-label">Country</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "The Country field is required!",
                  },
                ]}
              >
                <Select
                  loading={isLoading}
                  className="default-select form-control"
                  placeholder="- Select -"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {countries &&
                    countries.map((item, i) => (
                      <Select.Option key={item.mapping_id} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <label className="col-md-4 col-form-label">Mobile</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Mobile is required!",
                  },
                  {
                    validator: async (rule, value) => {
                      if (value && ValidatePhoneNumber("+" + value)) {
                        return await Promise.resolve();
                      } else
                        return await Promise.reject(
                          Error("Mobile Number is not in correct format.")
                        );
                    },
                  },
                ]}
              >
                <PhoneInput
                  inputClass="ant-input form-control w-100 rounded  bg-white font-size-14 text-default"
                  country={"us"}
                />
              </Form.Item>
              <label className="col-md-4 col-form-label">Support Email</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="support_email"
                rules={[
                  {
                    required: true,
                    message: "The Support Email field is required!",
                  },
                ]}
              >
                <Input
                  type="email"
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="Support Email"
                />
              </Form.Item>
              <p className="col-12">
                Used for sending emails to client using the ticket.
              </p>
              <label className="col-md-4 col-form-label">Website</label>
              <Form.Item
                className="col-md-12 pb-0"
                name="website"
                rules={[
                  {
                    required: true,
                    message: "Website is required!",
                  },
                  {
                    pattern: new RegExp(
                      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
                    ),
                    message: "Entered string must be a Website URL",
                  },
                ]}
              >
                <Input
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="Website"
                />
              </Form.Item>
              <p className="col-12">
                Used for sending emails regarding problem reports for movies
                and/or tv channels.
              </p>
            </div>
          </div>
          <div>
            <Form.Item
              name="currencies"
              initialValue={added_currencies}
              rules={[
                {
                  required: true,
                  message: "Please add some currencies",
                },
              ]}
            >
              <Table
                className="w-100%"
                columns={[
                  {
                    dataIndex: "name",
                    key: "name",
                    title: "Currency Name",
                  },
                  {
                    title: "Default",
                    dataIndex: "is_default",
                    key: "is_default",
                    render: (tags: any, row: IAddNewCurrency) => (
                      <span className="justify-content-center">
                        <Switch
                          className="antd-switch ch-switch"
                          checked={row.is_default}
                          onChange={() => {
                            setAdded_currencies((previous) => {
                              let index = _.findIndex(previous, {
                                currency_id: row.currency_id,
                              });
                              if (index !== -1)
                                previous[index].is_default =
                                  !previous[index].is_default;

                              previous = previous.map((value, i) => {
                                if (i !== index) value.is_default = false;
                                return value;
                              });
                              return previous;
                            });
                          }}
                          defaultChecked={row.is_default}
                        ></Switch>
                      </span>
                    ),
                  },
                  {
                    title: "",
                    dataIndex: "action",
                    key: "action",
                    render: (tags: any, row: IAddNewCurrency) => (
                      <span className="justify-content-end">
                        <Tooltip placement="top" title={`Delete`}>
                          <Icon
                            onClick={() => {
                              setAdded_currencies((previous) => {
                                previous = previous.filter((currency) =>
                                  currency.currency_id !== row.currency_id
                                    ? currency
                                    : undefined
                                );
                                previous = _.compact(previous);
                                return previous;
                              });
                            }}
                            value={row.currency_id}
                            className="mx-2 cursor-pointer"
                            component={() => (
                              <img
                                alt="Delete"
                                className="action-icon"
                                src={DeleteSVG}
                              />
                            )}
                          />
                        </Tooltip>
                      </span>
                    ),
                  },
                ]}
                dataSource={added_currencies}
                pagination={{
                  position: ["bottomLeft"],
                  pageSize: 10,
                  hideOnSinglePage: true,
                  showTotal: (total, range) => {
                    return `${range[0]}-${range[1]} of ${total} items`;
                  },
                }}
              ></Table>
            </Form.Item>
            <Button
              title="Add New Currency "
              className="btn btn-secondary float-left mt-1 width-180 height-48  "
              onClick={() => {
                setOpenAddNewCurrencyModal(true);
              }}
            >
              Add New Currency
            </Button>
          </div>
        </div>
      </div>
      {openAddNewCurrencyModal && (
        <AddNewCurrencyModal
          onCancel={() => {
            setOpenAddNewCurrencyModal(!openAddNewCurrencyModal);
          }}
          onSubmit={(values) => {
            setAdded_currencies((pre) => {
              pre = [...pre, { ...values, key: values.currency_id }];
              pre = _.uniqBy(pre, (value) => value.currency_id);
              if (values.is_default) {
                let index = _.findIndex(pre, {
                  currency_id: values.currency_id,
                });
                pre = _.uniqBy(
                  pre.filter((value, i) => {
                    if (i !== index) value.is_default = false;
                    return value;
                  }),
                  "currency_id"
                );
              }
              return pre;
            });
            setOpenAddNewCurrencyModal(!openAddNewCurrencyModal);
          }}
          alreadyAddedCurrencies={added_currencies}
          visible={openAddNewCurrencyModal}
        />
      )}
    </>
  );
};

export default StepSetup;
