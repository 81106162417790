import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Layout, Menu } from "antd";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { DefaultUserIcon, MWare_Logo_Blue } from "../../../assets/ims_images";
import { AuthContext } from "../../../context_api/AuthContext";
import { AuthActionTypes } from "../../../context_api/AuthContext/action-types";
import { DrawerContext } from "../../../context_api/DrawerContext";
import { DrawerActionTypes } from "../../../context_api/DrawerContext/action-types";
const { Header } = Layout;

const HomeDashboardHeader = () => {
  const history = useHistory();
  const { state: AuthState, dispatch: AuthDispatch } = useContext(AuthContext);
  const handleMenuClick = ({ key }: any) => {
    document.body.classList.remove("menu-overlay");
    if (key === "logout") {
      AuthDispatch({ type: AuthActionTypes.LOGOUT });
      history.push("/login");
    }
  };
  const { state: DrawerState, dispatch: DrawerDispatch } =
    useContext(DrawerContext);

  const userMenu = () => {
    return (
      <Menu className="header-dropdown user-dropdown">
        <div className="px-4 py-2">
          <div className="media border-bottom pb-3 d-flex align-items-center">
            <img
              className="rounded-circle mr-3 align-self-center"
              src={DefaultUserIcon}
              title="User"
              alt="User"
            />
            <div className="media-body">
              <h5 className="mt-0 text-primary">{AuthState.user?.name}</h5>
              <p className="mb-0">{AuthState.user?.email}</p>
            </div>
          </div>
          <Menu
            onClick={handleMenuClick}
            className="header-dropdown mt-3 border-0"
          >
            <Menu.Item key="mware_profile" className="pl-0 m-0">
              <Link to={`/user-profile`}>Mware Profile</Link>
            </Menu.Item>
            <Menu.Item key="help" className="pl-0 m-0">
              <Link className=" pb-3" to={`/`}>
                Help
              </Link>
            </Menu.Item>
            <hr />
            <Menu.Item key="logout" className="pl-0 mt-3 mb-0 text-danger">
              Logout
            </Menu.Item>
          </Menu>
        </div>
      </Menu>
    );
  };
  const dropdownVisibleChange = (isOpen: boolean) => {
    if (isOpen) {
      document.body.classList.add("menu-overlay");
    } else {
      document.body.classList.remove("menu-overlay");
    }
  };
  useEffectOnce(() => {
    DrawerDispatch({
      type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
      default_selected_key: "",
      default_open_key: "",
      routes: [],
      service_name: "",
      service_type: "",
      client_dashboard_path: "",
    });
  });
  return (
    <Header className="p-0 d-flex z-overly-10">
      <nav className="d-flex align-items-center w-100 bg-white px-3 px-lg-4 py-0">
        <Link to={`/`} className="mr-auto">
          <img
            src={DrawerState.white_labeling.logo || MWare_Logo_Blue}
            title="Mware"
            alt="Mware"
            style={
              DrawerState.white_labeling.logo ? { width: 200, height: 50 } : {}
            }
          />
        </Link>
        <Dropdown
          overlay={() => userMenu()}
          trigger={["click"]}
          onVisibleChange={dropdownVisibleChange}
        >
          <Link to={``} className="nav-link pl-2 pl-lg-4 pr-0 user-menu">
            <img
              className="rounded-circle mr-2"
              src={DefaultUserIcon}
              title="User"
              alt="User"
            />
            <span className="d-none d-lg-inline-block">
              {AuthState.user?.name}
            </span>
            <FontAwesomeIcon className="ml-2 align-middle" icon={faAngleDown} />
          </Link>
        </Dropdown>
      </nav>
    </Header>
  );
};

export default HomeDashboardHeader;
