import { Button, Form, Input, notification, Spin, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { StatusCodes } from "http-status-codes";
import { memo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { CustomNotification } from "../../../common/components/Notification";
import ims from "../../../services/ims";
import { IMSNameParams } from "../../ims";

const SendgridSettings = () => {
  const [form] = useForm();
  const [isDomainAuthenticated, setIsDomainAuthenticated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [getingDetails, setGetingDetails] = useState(true);
  const { ims_name } = useParams<IMSNameParams>();

  const fetchSMTPSettings = async () => {
    setGetingDetails(true);
    let response = await ims.getSendgridSettings({
      client_name: ims_name,
    });
    if (response && response.status_code === StatusCodes.OK) {
      form.setFieldsValue({
        ...response.data,
        authenticated_domains: response.data.authenticated_domains.join(","),
      });
      response.data.is_domain_authenticated
        ? setIsDomainAuthenticated(true)
        : setIsDomainAuthenticated(false);
    } else
      CustomNotification({
        error: response.errors,
      });
    setGetingDetails(false);
  };
  const handleSubmit = async (values: any) => {
    setIsUpdating(true);
    let response = await ims.addUpdateSendgridSettings({
      ...values,
      client_name: ims_name,
      ...(values.is_domain_authenticated && {
        authenticated_domains: values.authenticated_domains.includes(",")
          ? values.authenticated_domains.split(",")
          : [values.authenticated_domains],
      }),
    });

    if (response && response.status_code === StatusCodes.OK)
      notification.success({
        message: "Success",
        description: "Settings updated successfully.",
      });
    else
      CustomNotification({
        error: response.errors,
      });
    setIsUpdating(false);
  };
  useEffectOnce(() => {
    fetchSMTPSettings();
  });
  return (
    <>
      <div className="container-fluid wrapper">
        <div className="row">
          <div className="col-md-6">
            <h2 className="page-title">SendGrid Settings</h2>
            <p className="h6 font-weight-normal line-height-26"></p>
          </div>
        </div>
        <Spin spinning={getingDetails}>
          <Form
            form={form}
            onFinish={async (values) => {
              await handleSubmit(values);
            }}
            initialValues={{
              is_domain_authenticated: false,
            }}
            onFinishFailed={(errorInfo) => {
              console.log(errorInfo);
            }}
          >
            <div className="card mt-5">
              <div className="card-body card-ch-padding">
                <div className="row">
                  <label className="col-md-4 col-form-label">
                    SendGrid API Key
                  </label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        required: true,
                        message: "SendGrid API key is required",
                      },
                    ]}
                    name="api_key"
                  >
                    <Input
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="API Key"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">
                    SendGrid From
                  </label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        required: true,
                        message: "SendGrid From is required",
                      },
                    ]}
                    name="send_from"
                  >
                    <Input
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="From"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">
                    Sender Name (Optional)
                  </label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        required: true,
                        message: "Sender Name is required",
                      },
                    ]}
                    name="sender_name"
                  >
                    <Input
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="Sender Name"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">
                    Is Domain Authenticated
                  </label>
                  <Form.Item
                    className="col-md-5"
                    rules={[]}
                    name="is_domain_authenticated"
                    valuePropName="checked"
                  >
                    <Switch
                      className="ch-switch"
                      onClick={(checked) => setIsDomainAuthenticated(checked)}
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">
                    Authenticated Domains
                  </label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        ...(isDomainAuthenticated && {
                          required: true,
                          message: "Authenticated Domains is required",
                        }),
                      },
                    ]}
                    name="authenticated_domains"
                  >
                    <Input
                      disabled={!isDomainAuthenticated}
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="Authenticated Domains"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">
                    Instructions
                  </label>
                  <label className="col-md-5 col-form-label">Sendgrid</label>
                  <label className="col-md-4 col-form-label"></label>
                  <div className="col-md-5 col-form-label">
                    <ul>
                      <li className="mb-2">
                        You can know more about the Sendgrid by visiting their
                        official website
                      </li>

                      <li className="mb-2">
                        We advise you to go with the plan considering the amount
                        of emails you will be sending each month. Sendgrid
                        provides an API Key that needs to be used for sending
                        mails across the IMS.You can sign up and set that key
                        here https://app.sendgrid.com/settings/api_keys
                      </li>

                      <li className="mb-2">
                        After setting up the key kindly whitelist the IP
                        54.37.79.19 over
                        here(https://app.sendgrid.com/settings/access) if you
                        have put restrictions for the IP else ignore it.
                      </li>

                      <li className="mb-2">
                        We do verify the key by sending a sample email to our
                        system admin email to confirm if the API key works fine.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right">
              <Button
                loading={isUpdating}
                disabled={getingDetails || isUpdating}
                htmlType="submit"
                className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48"
              >
                Update Settings
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </>
  );
};
export default memo(SendgridSettings);
