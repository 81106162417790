import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "../../../context_api/AuthContext";

interface PrivateRouteProps extends RouteProps {
  component: any;
}
const AuthRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const { state: AuthState } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        return AuthState.isAuthenticated === false ? (
          <div className="app-main bg-auth">
            <Component {...props} />
          </div>
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};
export default AuthRoute;
