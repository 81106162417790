import { notification } from "antd";
import { StatusCodes } from "http-status-codes";
import React from "react";
import { useHistory } from "react-router-dom";
import { CustomNotification } from "../../common/components/Notification";
import { IAddSystemUserFormValues } from "../../interfaces/forms/user/UserInterfaces";
import { IAddUserAPIResponse } from "../../interfaces/responses/user/IAddUserResponse";
import userService from "../../services/user";
import SystemUserForm, { EnumSystemUserFormType } from "./system_user_form";

const AddSystemUser = () => {
  let history = useHistory();
  const handleSubmit = async (values: IAddSystemUserFormValues) => {
    let response: IAddUserAPIResponse = await userService.AddUser(values);

    if (
      response.status_code === StatusCodes.OK ||
      response.status_code === StatusCodes.CREATED
    ) {
      notification.success({
        message: "User Added",
        description: `User Added successfully`,
      });
      history.push("system-users")
    } else
      CustomNotification({
        error: response.errors,
      });
  };

  return (
    <div className="container-fluid wrapper p-3">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body card-ch-padding">
              <div className="row mb-4">
                <div className="col-12">
                  <h3 className="page-title">Add System User</h3>
                </div>
              </div>
              <SystemUserForm
                type={EnumSystemUserFormType.ADD}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(AddSystemUser);
