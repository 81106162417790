import { Button, DatePicker, Space } from "antd";
import React, { ForwardedRef, forwardRef, useState } from "react";
import { FilterProps } from "react-table";
import { IReactBCTableColumn } from "../Interfaces/IReactBCTableColumn.interface";

const ReactBCDateRangeFilter = forwardRef(<T extends {}>({ column }: FilterProps<T>, filterRef: ForwardedRef<HTMLDivElement>) => {
  const { setFilter, customData } = column;
  let passedProps = customData.parentColumn as IReactBCTableColumn<T>;
  if (!passedProps.filterOption) passedProps.filterOption = "eq";
  const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | null>(null);
  return (
    <div ref={filterRef} style={{ backgroundColor: "#ffffff", border: "1px solid #1ac884", padding: 8 }}>
      <div>
        <span>
          Start{" "}
          <DatePicker
            value={selectedStartDate}
            format={"DD MMM YYYY"}
            disabledDate={(date) => date.isAfter(selectedEndDate)}
            onChange={(date: moment.Moment | null, dateString: string) => setSelectedStartDate(date)}
          />
        </span>
        <span
          style={{
            marginLeft: "10px",
          }}
        >
          End{" "}
          <DatePicker
            value={selectedEndDate}
            format={"DD MMM YYYY"}
            disabledDate={(date) => date.isBefore(selectedStartDate)}
            onChange={(date: moment.Moment | null, dateString: string) => setSelectedEndDate(date)}
          />
        </span>
      </div>
      <Space style={{ marginTop: "10px" }}>
        <Button
          type="primary"
          disabled={!selectedEndDate || !selectedStartDate}
          onClick={() =>
            setFilter({
              filterValue: { ...(selectedStartDate && { start: selectedStartDate }), ...(selectedEndDate && { start: selectedEndDate }) },
              filterType: "dateRange",
              dateFormat: "DD MMM YYYY",
            })
          }
          size="small"
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setSelectedStartDate(null);
            setSelectedEndDate(null);
            setFilter(undefined);
          }}
          size="small"
        >
          Reset
        </Button>
      </Space>
    </div>
  );
});

export default ReactBCDateRangeFilter;
