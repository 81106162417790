import { CaretDownOutlined, CaretRightOutlined, CaretUpOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown, Empty, Pagination, Spin } from "antd";
import classNames from "classnames";
import React, { MutableRefObject, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Column,
  FilterProps,
  SortingRule,
  TableOptions,
  useExpanded,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useClickAway } from "react-use";
import { ReactBCBooleanFilter, ReactBCDateFilter, ReactBCDateRangeFilter, ReactBCNumberFilter, ReactBCTextFilter } from "./Filters";
import { IReactBCTableExpandProps } from "./Interfaces/IReactBCTableExpandProps.interface";
import { IReactBCTableOptions } from "./Interfaces/IReactBCTableOptions.interface";

const ReactBCTable = <T extends {}>(props: PropsWithChildren<IReactBCTableOptions<T>>) => {
  let {
    isLoading,
    hasChild,
    onRowSelectionChange,
    childComponent,
    hasSelection,
    hasSingleSelection,
    onChange,
    tableId,
    columns,
    data,
    totalRecords,
    pageSize: tablePageSize,
  } = props;

  const memoData = useMemo(() => [...(data || [])], [data]);
  let tableColumns: Column[] = useMemo(() => [], [data]);
  if (hasChild) {
    tableColumns.push({
      Header: () => null,
      id: "expander",
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}</span>;
      },
    });
  }

  if (hasSelection || hasSingleSelection) {
    tableColumns.push({
      id: "selection",
      disableSortBy: true,
      Header: ({ getToggleAllPageRowsSelectedProps }) => {
        if (!hasSingleSelection) {
          return (
            <div className={`ant-table-selection`}>
              <Checkbox
                {...getToggleAllPageRowsSelectedProps()}
                onChange={(e) => {
                  toggleAllRowsSelected(e.target.checked);
                }}
              />
            </div>
          );
        } else return <span></span>;
      },
      Cell: ({ row, selectedFlatRows }) => {
        return (
          <Checkbox
            {...row.getToggleRowSelectedProps()}
            onChange={(e) => {
              if (hasSingleSelection && selectedFlatRows.length > 0) toggleRowSelected(selectedFlatRows[0].id, false);
              toggleRowSelected(row.id, e.target.checked);
            }}
          />
        );
      },
    });
  }
  const [dropdownVisible, setDropdownVisible] = useState<{ [key: string]: boolean }>();
  let filtersVisible: { [key: string]: boolean } = useMemo(() => {
    return {};
  }, []);
  columns.forEach((column) => {
    // eslint-disable-next-line no-empty-pattern
    let dynamicFilter: React.FC<FilterProps<{}>> = ({}: FilterProps<{}>) => <span></span>;

    if (column.filter) {
      filtersVisible[column.dataKey] = false;
      switch (column.filter) {
        case "text":
          dynamicFilter = ReactBCTextFilter;
          break;
        case "number":
          dynamicFilter = ReactBCNumberFilter;
          break;
        case "date":
          dynamicFilter = ReactBCDateFilter;
          break;
        case "dateRange":
          dynamicFilter = ReactBCDateRangeFilter;
          break;
        case "boolean":
          dynamicFilter = ReactBCBooleanFilter;
          break;
        default:
          break;
      }
    }
    tableColumns.push({
      customData: { parentColumn: column },
      Header: column.title,
      accessor: column.dataKey,
      width: column.width,
      ...(column.cell && {
        Cell: (row) => column.cell && column.cell(row.row.original as T, row.row.index),
      }),
      ...(!column.hasSort && { disableSortBy: true }),
      ...(column.Footer && {
        Footer: (info) => {
          if (column.Footer) {
            let allTableRows: T[] = info.rows.map((row) => row.original as T);
            return column.Footer(allTableRows);
          }
        },
      }),
      ...(column.filter && { Filter: dynamicFilter }),
      ...(!column.filter && { disableFilters: true }),
    });
  });
  tableColumns = useMemo(() => tableColumns, [data, tableColumns]);
  let filterRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickAway(filterRef, () => {
    setDropdownVisible((prevState) => ({ ...prevState, ...filtersVisible }));
  });
  const tableOptions: TableOptions<{}> = {
    columns: tableColumns,
    data: memoData,
    manualSortBy: true,
    disableMultiSort: true,
    manualFilters: true,
    manualPagination: true,
    initialState: {
      pageIndex: 0,
      pageSize: tablePageSize,
      sortBy: [],
      filters: [],
    },
    autoResetExpanded: false,
    pageCount: totalRecords,
  };
  const tableInstance = useTable(tableOptions, useFilters, useSortBy, useExpanded, usePagination, useRowSelect);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    gotoPage,
    page,
    visibleColumns,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,
    state: { sortBy, filters, pageIndex, pageSize },
  } = tableInstance;

  const RenderRowSubComponent = useCallback(
    ({ row }: IReactBCTableExpandProps) => {
      return childComponent?.(row.original as T, row.index);
    },
    [childComponent]
  );

  useEffect(() => {
    onRowSelectionChange?.(selectedFlatRows.map((sfr) => sfr.original as T));
  }, [selectedFlatRows]);

  useEffect(() => {
    setDropdownVisible((prevState) => ({ ...prevState, ...filtersVisible }));
    let sort: SortingRule<{}> | undefined = undefined;
    if (sortBy && sortBy.length > 0) {
      sort = sortBy[0];
    }
    onChange(pageIndex + 1, pageSize, sort, filters);
  }, [filtersVisible, setDropdownVisible, onChange, sortBy, filters, pageIndex, pageSize]);

  return (
    <div className={`ant-table ant-table-override`} >
      <div className={`ant-table-container`}>
        <Spin size="large" spinning={isLoading}>
          <table {...getTableProps({ className: `table-layout: auto;` })}>
            <thead className={`ant-table-thead`}>
              {headerGroups.map((headerGroup, hgIndex) => (
                <tr
                  {...headerGroup.getHeaderGroupProps({
                    key: `${tableId}_rbct_hg_${hgIndex}`,
                  })}
                >
                  {headerGroup.headers.map((header, hIndex) => {
                    return (
                      <th
                        
                        {...header.getHeaderProps({
                          key: `${tableId}_rbct_h_${hIndex}`,
                          ...(header.customData?.parentColumn?.width && {style: {width: header.customData?.parentColumn?.width}}),
                          className: classNames(`ant-table-cell`, {
                            "ant-table-row-expand-icon-cell": header.id === "expander",
                            "ant-table-selection-column": header.id === "selection",
                            "ant-table-column-has-sorters": !header.disableSortBy,
                          }),
                        })}
                      >
                        <span
                          className={classNames({
                            "ant-table-filter-column": header.canFilter,
                          })}
                        >
                          {header.disableSortBy && <span className="ant-table-column-title">{header.render("Header")}</span>}
                          {!header.disableSortBy && (
                            <div
                              {...header.getSortByToggleProps()}
                              className={classNames({
                                "ant-table-column-title": !header.disableSortBy,
                              })}
                            >
                              <div
                                className={classNames({
                                  "ant-table-column-sorters": !header.disableSortBy,
                                })}
                              >
                                <span className="ant-table-column-title">{header.render("Header")}</span>
                                <span className={"ant-table-column-sorter-inner"}>
                                  {header.isSorted
                                    ? header.isSortedDesc
                                      ? [
                                          <CaretUpOutlined key={Math.random()} className={`anticon anticon-caret-up ant-table-column-sorter-up`} />,
                                          <CaretDownOutlined
                                            key={Math.random()}
                                            className={`anticon anticon-caret-down ant-table-column-sorter-down active`}
                                          />,
                                        ]
                                      : [
                                          <CaretUpOutlined
                                            key={Math.random()}
                                            className={`anticon anticon-caret-up ant-table-column-sorter-up active`}
                                          />,
                                          <CaretDownOutlined
                                            key={Math.random()}
                                            className={`anticon anticon-caret-down ant-table-column-sorter-down`}
                                          />,
                                        ]
                                    : !header.disableSortBy
                                    ? [
                                        <CaretUpOutlined key={Math.random()} className={`anticon anticon-caret-up ant-table-column-sorter-up`} />,
                                        <CaretDownOutlined
                                          key={Math.random()}
                                          className={`anticon anticon-caret-down ant-table-column-sorter-down`}
                                        />,
                                      ]
                                    : ""}
                                </span>
                              </div>
                            </div>
                          )}
                          {header.canFilter ? (
                            <>
                              <Dropdown
                                placement="bottomRight"
                                visible={dropdownVisible?.[header.id]}
                                overlay={header.render("Filter", { ref: filterRef }) as JSX.Element}
                                trigger={["click"]}
                              >
                                <span
                                  role="button"
                                  onClick={() => {
                                    setDropdownVisible((prevState) => ({ ...prevState, ...filtersVisible }));
                                    setDropdownVisible((prevState) => ({ ...prevState, [header.id]: !dropdownVisible?.[header.id] }));
                                  }}
                                  className={`ant-dropdown-trigger ant-table-filter-trigger`}
                                >
                                  <SearchOutlined
                                    style={{
                                      color: header.filterValue ? "#1ac884" : "",
                                    }}
                                  />
                                </span>
                              </Dropdown>
                            </>
                          ) : null}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps({ className: `ant-table-tbody` })}>
              {!isLoading && page.length === 0 && (
                <tr>
                  <td colSpan={visibleColumns.length} className={`ant-table-cell`}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              )}
              {page.length > 0 &&
                page.map((row, rIndex) => {
                  prepareRow(row);
                  let rowProps = row.getRowProps({
                    key: `${tableId}_rbct_r_${rIndex}`,
                    className: `ant-table-row`,
                  });
                  return (
                    <React.Fragment key={rowProps.key}>
                      <tr key={`rbct_tr_${rIndex}`} className={rowProps.className}>
                        {row.cells.map((cell, cIndex) => {
                          return (
                            <td
                              {...cell.getCellProps({
                                key: `${tableId}_rbct_c_${cIndex}`,
                                className: classNames(`ant-table-cell`, {
                                  "ant-table-row-expand-icon-cell": cell.column.id === "expander",
                                  "ant-table-selection-column": cell.column.id === "selection",
                                }),
                              })}
                            >
                              {cell.render("Cell", {})}
                            </td>
                          );
                        })}
                      </tr>
                      {hasChild && row.isExpanded ? (
                        <tr key={`rbct_tr_n_${rIndex}`} className="ant-table-expanded-row">
                          <td colSpan={visibleColumns.length} style={{ padding: "10px", background: "#ebe9f5" }}>
                            {RenderRowSubComponent({
                              row,
                              rowProps,
                              visibleColumns,
                            })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </tbody>
            <tfoot>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>{column.render("Footer")}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>

          {totalRecords > pageSize && (
            <div className="react-table-bc-pagination" style={{ padding: "20px" }}>
              <style>{`
           .react-table-bc-pagination .ant-pagination-item-ellipsis {
             margin-top:4px !important;
           }
           .ant-pagination-total-text{
             float:right;
           }
         `}</style>
              <Pagination
                hideOnSinglePage
                showTotal={(total, range) => <div>Showing {`${range[0]} - ${range[1]} of ${total} items`}</div>}
                defaultCurrent={1}
                total={totalRecords}
                pageSize={pageSize}
                onChange={(page, pageSize) => {
                  gotoPage(page - 1);
                }}
              />
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default React.memo(ReactBCTable) as typeof ReactBCTable;
