import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { ISignInFormValues } from "../../../interfaces/forms/auth/AuthInterfaces";

type ILoginFormProps = {
  onSubmit: (values: ISignInFormValues) => void;
  isLogingIn: boolean;
};
const LoginForm = (props: ILoginFormProps) => {
  const [loginForm] = Form.useForm();

  const onFinish = async (values: ISignInFormValues) => {
    props.onSubmit(values);
  };
  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {};
  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        email: "",
        password: "",
      }}
      onFinishFailed={onFinishFailed}
      form={loginForm}
    >
      <div className="row">
        <Form.Item
          className="form-group col-md-12"
          name="email"
          rules={[
            { required: true, message: "Email is required!" },
            { type: "email", message: "The input is not valid E-mail!" },
          ]}
        >
          <Input className="form-control" placeholder="Email or Username" />
        </Form.Item>
        <Form.Item
          className="form-group col-md-12"
          name="password"
          validateFirst={true}
          rules={[
            { required: true, message: "Password is required!" },
            {
              pattern: new RegExp("(?=.*[A-Z])(?=.*[0-9])"),
              message: "Password should be at least one capital and one digit.",
            },
            {
              min: 5,
              message: "Password should be at least 5 characters long.",
            },
          ]}
        >
          <Input
            className="form-control"
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item className="form-group col-md-12">
          <Form.Item
            noStyle
            name="remember"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Link className="text-secondary float-right" to={`/forgot-password`}>
            Forgot Password?
          </Link>
        </Form.Item>
        <Form.Item className="col-md-12">
          <Button
            size="large"
            htmlType="submit"
            color="primary"
            loading={props.isLogingIn}
            disabled={props.isLogingIn}
            className="btn btn-secondary rounded-pill height-48"
          >
            <span>Sign In</span>
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default LoginForm;
