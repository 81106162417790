import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import Enumerable from "linq";
import React, { memo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { IDatastore } from "../../../../../interfaces/responses/clients/IClientInfoResponse";
import { IDashboardDatastores } from "../../../../../utils/DatastoresMapping";
export type IContentManagementProps = {
  datastoreInfo: IDashboardDatastores;
};

const ContentManagementHeader = ({
  datastoreInfo,
}: IContentManagementProps) => {
  let { url } = useRouteMatch();
  return (
    <Menu.ItemGroup title="Content Management">
      {datastoreInfo?.all_cms_names.map((cms_name, index) => {
        let cmsInfo = Enumerable.from(datastoreInfo.cms_info)
          ?.where((x) => x.name.toLowerCase() === cms_name.toLowerCase())
          .first();
        if (cmsInfo) {
          let { cms, crm, iq, uit, cms_services } = cmsInfo;
          return (
            <SubMenu
              key={`cms_name_${cms_name}_${index}`}
              title={
                cms ? (
                  <Link to={`${url}/cms/${cms?.name}`}>{cms?.name}</Link>
                ) : (
                  cms_name
                )
              }
            >
              <Menu.ItemGroup title={`${cms_name} Services`}>
                {cms_services.map((cms_service_name, cms_service_index) => {
                  let crmElement = crm?.filter(
                    (x) =>
                      x.name.toLowerCase() === cms_service_name.toLowerCase()
                  );
                  let iqElement = iq?.filter(
                    (x) =>
                      x.name.toLowerCase() === cms_service_name.toLowerCase()
                  );
                  let uitElement = uit?.filter(
                    (x) =>
                      x.name.toLowerCase() === cms_service_name.toLowerCase()
                  );
                  let authorizedDatastores: IDatastore[] = [];
                  if (crmElement && crmElement.length > 0)
                    authorizedDatastores.push(crmElement[0]);
                  if (iqElement && iqElement.length > 0)
                    authorizedDatastores.push(iqElement[0]);
                  if (uitElement && uitElement.length > 0)
                    authorizedDatastores.push(uitElement[0]);
                  return (
                    <SubMenu
                      key={`cms_name_${cms_name}_${index}_${cms_service_name}_${cms_service_index}`}
                      title={cms_service_name}
                    >
                      {authorizedDatastores.length > 0 && (
                        <Menu.ItemGroup
                          title={`${cms_service_name.toUpperCase()}`}
                        >
                          {authorizedDatastores.map((ds, ds_index) => {
                            return (
                              <Menu.Item
                                key={`cms_name_${cms_name}_${index}_${cms_service_name}_${cms_service_index}_${ds.type}_${ds_index}`}
                              >
                                <Link
                                  to={`${url}/${ds.type}/${cms_service_name}`}
                                >
                                  {ds.type.toUpperCase()}
                                </Link>
                              </Menu.Item>
                            );
                          })}
                        </Menu.ItemGroup>
                      )}
                    </SubMenu>
                  );
                })}
              </Menu.ItemGroup>
            </SubMenu>
          );
        } else return null;
      })}
    </Menu.ItemGroup>
  );
};
export default memo(ContentManagementHeader);
