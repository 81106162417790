import { IOTPSubmitValues, ISignInFormValues } from '../../interfaces/forms/auth/AuthInterfaces';
import { IUserLoginResponse } from '../../interfaces/responses/auth/ILoginResponse';
import axios from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';

class AuthService {
  Login = ({ email, password }: ISignInFormValues): Promise<IUserLoginResponse> => {
    const data = { email, password };
    return axios
      .post(API_ENDPOINTS.V1.Auth.LOGIN_ENDPOINT, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  OTPVerify = ({ otp_id, otp, user_id }: IOTPSubmitValues) => {
    const data = { otp_id, otp, user_id };
    return axios
      .post(API_ENDPOINTS.V1.Auth.OTP_VERIFY, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new AuthService();
