import Icon from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  notification,
  Switch,
  Tooltip,
  Upload
} from "antd";
import { useForm } from "antd/lib/form/Form";
import confirm from "antd/lib/modal/confirm";
import { StatusCodes } from "http-status-codes";
import { memo, useCallback, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SortingRule } from "react-table";
import { DeleteSVG, DetailsSVG, UploadPNG } from "../../../assets/ims_images";
import { CustomNotification } from "../../../common/components/Notification";
import ReactBCTable from "../../../common/components/ReactBCTable";
import IptvRichEditor from "../../../common/components/RichTextEditor";
import { DrawerContext } from "../../../context_api/DrawerContext";
import { IGetSuperhostnamesListForm } from "../../../interfaces/forms/areas/uit/hostnames/IGetSuperhostnamesList";
import { IListSuperhostnameDoc } from "../../../interfaces/responses/areas/uit/hostnames/IGetSuperhostnameListResponse";
import { IFileUpload } from "../../../interfaces/responses/common/IFileUploadResult";
import {
  IPaginatedAndFilterResponse,
  PaginatedAndFilterResponseDefaultValues
} from "../../../interfaces/responses/common/IPaginatedAndFilterResponse";
import hostnameServices from "../../../services/areas/uit/hostnames";
import common from "../../../services/common";
import useMwareRouteMatch from "../../../utils/Hooks/useMwareRouteMatch";

const AddSuperHostName = () => {
  const { ims_name, service_name } = useParams<any>();
  const mware_param = useMwareRouteMatch();

  const [form] = useForm();
  const { state: DrawerState } = useContext(DrawerContext);

  const [isSecure, setIsSecure] = useState(false);
  const [resetTextBox, setResetTextBox] = useState(false);
  const [isAddingHostname, setIsAddingHostname] = useState(false);
  const [isFetchingHostnames, setIsFetchingHostnames] = useState(false);
  const [uploadedLogoResponseData, setUploadedLogoResponseData] =
    useState<IFileUpload>();
  const [
    uploadedLogoBackgroundResponseData,
    setUploadedLogoBackgroundResponseData,
  ] = useState<IFileUpload>();
  const [appIconUploadedFileResponseData, setAppIconUploadedFileResponseData] =
    useState<IFileUpload>();
  const [superHostnamesList, setSuperhostnamesListData] = useState<
    IListSuperhostnameDoc[]
  >([]);

  const [tableSearchState, setTableSearchState] =
    useState<IGetSuperhostnamesListForm>({
      pageNumber: 1,
      pageSize: 10,
      query_params: {
        instance: ims_name,
      },
    });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(
    PaginatedAndFilterResponseDefaultValues
  );
  const deleteSuperhostname = async (_id: string) => {
    try {
      let response = await hostnameServices.deleteSuperHostname({
        _id,
        query_params: {
          instance: ims_name,
        },
      });
      if (response.status_code === StatusCodes.OK) {
        notification.success({
          message: "Success",
          description: "Superhostname deleted successfully !",
        });
        getSuperHostnamesList(tableSearchState);
      } else
        CustomNotification({
          error: response.errors,
        });
    } catch (error: any) {
      return notification.error({
        message: "Failed",
        description: error?.message ?? error,
      });
    }
  };
  const fileValueFormEvent = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const handleCustomRequest = async (options: any, type: string) => {
    let response = await common.UploadClientCommonFile({
      media_file: options.file,
      dimensions: [],
      query_params: {
        instance: ims_name,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      if (type === "login_logo") {
        form.setFieldsValue({
          login_logo: response.data.name,
        });
        setUploadedLogoResponseData(response.data);
      }
      if (type === "login_background") {
        form.setFieldsValue({
          login_background: response.data.name,
        });
        setUploadedLogoBackgroundResponseData(response.data);
      }
      if (type === "app_icon") {
        form.setFieldsValue({
          app_icon: response.data.name,
        });
        setAppIconUploadedFileResponseData(response.data);
      }
      options.onSuccess(null, response.data);
    } else options.onError(() => "An error occured");
  };

  const handleSubmit = async (values: any) => {
    setIsAddingHostname(true);
    let response = await hostnameServices.addSuperHostname({
      ...values,
      query_params: {
        cms: DrawerState.parent_service_name,
        uit: service_name ?? "",
        instance: ims_name,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: "Success",
        description: "Super hostname added successfully",
      });
      form.resetFields();
      setResetTextBox(true);
      getSuperHostnamesList(tableSearchState);
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsAddingHostname(false);
  };
  const getSuperHostnamesList = async (
    tableSearchOptions: IGetSuperhostnamesListForm
  ) => {
    setIsFetchingHostnames(true);
    let response = await hostnameServices.getSuperHostnamesList(
      tableSearchOptions
    );
    if (response && response.status_code === StatusCodes.OK) {
      setPagination(response?.data);
      setSuperhostnamesListData(response.data.data);
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsFetchingHostnames(false);
  };

  const tableCallbackFunction = useCallback(
    (
      pageNumber: number,
      pageSize: number,
      sortBy: SortingRule<{}> | undefined,
      filters: any
    ) => {
      let newState = {
        ...tableSearchState,
        sortBy,
        filters,
        pageNumber: pageNumber,
        pageSize,
      };
      setTableSearchState((prevState) => ({
        ...prevState,
        ...newState,
      }));
      getSuperHostnamesList(newState);
    },
    []
  );
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">Manage your Super Hostnames</h2>
          <p className="h6 font-weight-normal line-height-26">
            Using a Super Hostname gives you not only an easy to remember url
            for your customers, it also makes it possible to switch betweeen
            your GUI's and Products.
          </p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          is_secure: false,
          selection_color: "#ff2233",
        }}
      >
        <div className="card mt-3">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary">Add Super Hostname</h4>
            <div className="row mt-5">
              <>
                <label className="col-md-4 form-col-label">Is Secure</label>
                <Form.Item
                  className="col-md-5"
                  name="is_secure"
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) => {
                      setIsSecure(checked);
                    }}
                    className="ch-switch"
                  />
                </Form.Item>

                <label className="col-md-4 col-form-label">
                  Production Hostname
                </label>
                <Form.Item
                  className="col-md-5"
                  name="hostname"
                  extra={
                    <p>
                      To use this feature CNAME your domain like
                      xxx.yourcompany.com to our edgename:
                      {isSecure ? (
                        <b>securecloudmap.akamaized.net</b>
                      ) : (
                        <b> cloudmap.akamaized.net</b>
                      )}{" "}
                      Check with your hosting provider how to CNAME a subdomain
                      to a hostname. When you add a Hostname it will take ± 50
                      minutes for it to propagate accross all our CloudTV
                      regions.
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Hostname!",
                    },
                  ]}
                >
                  <Input
                    className="form-control bg-white font-size-14 text-default"
                    placeholder="Production Hostname"
                  />
                </Form.Item>

                <label className="col-md-4 col-form-label">Support URL</label>
                <Form.Item
                  className="col-md-5"
                  name="support_url"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Support URL!",
                    },
                  ]}
                  extra={
                    <p>
                      The url to a webpage that contains your support
                      information, this url will be shown to the customer in raw
                      format and as QR code.
                    </p>
                  }
                >
                  <Input
                    className="form-control bg-white font-size-14 text-default"
                    placeholder="Support URL"
                    type={"url"}
                    inputMode="url"
                  />
                </Form.Item>
              </>
            </div>
            <h4 className="text-primary">Login Settings</h4>
            <div className="row mt-5">
              <label className="col-md-4 col-form-label">Text</label>

              <IptvRichEditor
                setValues={(contentState) => {
                  form.setFieldsValue({
                    login_text: contentState,
                  });
                }}
                reset={resetTextBox}
              ></IptvRichEditor>
              <Form.Item
                name="login_text"
                shouldUpdate={(prev, next) => prev !== next}
                rules={[
                  {
                    validator: async (rule, value) => {
                      if (value && value !== "<p></p>\n") {
                        return await Promise.resolve();
                      } else {
                        return await Promise.reject(
                          new Error("Text Is Required !")
                        );
                      }
                    },
                  },
                ]}
              >
                <Input hidden></Input>
              </Form.Item>

              <Form.Item
                className="col-md-12"
                extra="This information will be shown to your customers on the login screen, you can tell them where to get support or get account credentials"
              />
              <label className="col-md-4 col-form-label">Login Logo</label>
              <Form.Item
                name="login_logo_file"
                className="col-md-5"
                valuePropName="fileList"
                getValueFromEvent={fileValueFormEvent}
                rules={[
                  {
                    validator: async (rule, value) => {
                      let login_logo = form.getFieldValue("login_logo");
                      if (login_logo) {
                        if (typeof login_logo === "string" && login_logo !== "")
                          return await Promise.resolve();
                        else
                          return await Promise.reject(
                            new Error("Login Logo File Is Required")
                          );
                      } else {
                        return await Promise.reject(
                          new Error("Login Logo File Is Required")
                        );
                      }
                    },
                  },
                ]}
              >
                <Upload.Dragger
                  listType="picture"
                  className="default-upload"
                  defaultFileList={[]}
                  maxCount={1}
                  multiple={false}
                  accept=".png"
                  customRequest={(options) => {
                    handleCustomRequest(options, "login_logo");
                  }}
                  onRemove={() => {
                    setUploadedLogoBackgroundResponseData(undefined);
                    form.setFieldsValue({ login_logo: "" });
                  }}
                >
                  <div className="media d-inline-flex align-items-center height-120">
                    <img src={UploadPNG} className="mr-3" alt="upload" />
                    <div className="media-body text-left my-3">
                      <h6 className="mt-0 text-primary">Upload your File</h6>
                      <p className="mb-0 h6 icon-gray font-weight-normal">
                        Drag files here or{" "}
                        <span className="text-secondary">Browse</span>
                      </p>
                    </div>
                  </div>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item name="login_logo" hidden className="col-md-12 pb-0">
                <Input value={uploadedLogoResponseData?.name}></Input>
              </Form.Item>
              <label className="col-md-4 col-form-label">
                Login Background
              </label>
              <Form.Item
                className="col-md-5"
                name="login_background_file"
                valuePropName="fileList"
                getValueFromEvent={fileValueFormEvent}
                rules={[
                  {
                    validator: async (rule, value) => {
                      let login_background =
                        form.getFieldValue("login_background");
                      if (login_background) {
                        if (
                          typeof login_background === "string" &&
                          login_background !== ""
                        )
                          return await Promise.resolve();
                        else
                          return await Promise.reject(
                            new Error("Login Background File Is Required")
                          );
                      } else {
                        return await Promise.reject(
                          new Error("Login Background File Is Required")
                        );
                      }
                    },
                  },
                ]}
              >
                <Upload.Dragger
                  name="login_backgrond_file"
                  listType="picture"
                  className="default-upload"
                  defaultFileList={[]}
                  maxCount={1}
                  multiple={false}
                  accept=".png"
                  customRequest={(options) => {
                    handleCustomRequest(options, "login_background");
                  }}
                  onRemove={() => {
                    setUploadedLogoBackgroundResponseData(undefined);
                    form.setFieldsValue({ login_background: "" });
                  }}
                >
                  <div className="media d-inline-flex align-items-center height-120">
                    <img src={UploadPNG} className="mr-3" alt="upload" />
                    <div className="media-body text-left my-3">
                      <h6 className="mt-0 text-primary">Upload your File</h6>
                      <p className="mb-0 h6 icon-gray font-weight-normal">
                        Drag files here or{" "}
                        <span className="text-secondary">Browse</span>
                      </p>
                    </div>
                  </div>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item
                name="login_background"
                hidden
                className="col-md-12 pb-0"
              >
                <Input value={uploadedLogoBackgroundResponseData?.name}></Input>
              </Form.Item>
              <label className="col-md-4 col-form-label">Selection Color</label>
              <Form.Item
                className="col-md-5"
                name="selection_color"
                rules={[
                  {
                    required: true,
                    message: "Please Select Selection Color!",
                  },
                ]}
              >
                <Input
                  style={{
                    padding: 0,
                    height: 40,
                    width: 82,
                  }}
                  type={"color"}
                  className="form-control bg-white "
                />
              </Form.Item>
            </div>
            <h4 className="text-primary">Progressive Web App</h4>
            <div className="row mt-5">
              <label className="col-md-4 col-form-label">App Name</label>
              <Form.Item
                extra="This is required for Progressive Web App"
                className="col-md-5"
                name="app_name"
                shouldUpdate={(prev, next) => prev !== next}
                rules={[
                  {
                    warningOnly: true,
                    message:
                      "App name will be requried for Progressive web app ",
                  },
                ]}
              >
                <Input
                  className="form-control bg-white font-size-14 text-default"
                  placeholder="App Name"
                />
              </Form.Item>

              <label className="col-md-4 col-form-label">App Icon</label>
              <Form.Item
                extra="This is required for Progressive Web App"
                name="app_icon_file"
                valuePropName="fileList"
                className="col-md-5"
                getValueFromEvent={fileValueFormEvent}
              >
                <Upload.Dragger
                  listType="picture"
                  className="default-upload"
                  defaultFileList={[]}
                  maxCount={1}
                  multiple={false}
                  accept=".png"
                  customRequest={(options) => {
                    handleCustomRequest(options, "app_icon");
                  }}
                  onRemove={() => {
                    setAppIconUploadedFileResponseData(undefined);
                    form.setFieldsValue({ app_icon: "" });
                  }}
                >
                  <div className="media d-inline-flex align-items-center height-120">
                    <img src={UploadPNG} className="mr-3" alt="upload" />
                    <div className="media-body text-left my-3">
                      <h6 className="mt-0 text-primary">Upload your File</h6>
                      <p className="mb-0 h6 icon-gray font-weight-normal">
                        Drag files here or{" "}
                        <span className="text-secondary">Browse</span>
                      </p>
                    </div>
                  </div>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item name="app_icon" hidden className="col-md-12 pb-0">
                <Input value={appIconUploadedFileResponseData?.name}></Input>
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="text-right">
          <Button
            htmlType="submit"
            loading={isAddingHostname}
            disabled={isAddingHostname}
            className="mt-4 ant-btn btn btn-secondary rounded-pill width-160 height-54"
          >
            Add Hostname
          </Button>
        </div>
      </Form>
      <div className="mt-3">
        <ReactBCTable
          isLoading={isFetchingHostnames}
          totalRecords={pagination.count}
          pageSize={pagination.pageSize}
          tableId={"super_hostnames_table"}
          columns={[
            {
              title: "Hostname",
              dataKey: "hostname",
              filter: "text",
              filterOption: "eq",
              hasSort: true,
            },
            {
              title: "Login Version",
              dataKey: "login_version",
              filter: "text",
              filterOption: "eq",
              hasSort: true,
            },
            {
              title: "Update To Latest",
              dataKey: "update_to_latest",
              filter: "text",
              filterOption: "eq",
              hasSort: true,
            },
            {
              title: "Edit Version",
              dataKey: "edit_version",
            },
            {
              title: "Update Service",
              dataKey: "update",
            },
            {
              title: "Actions",
              dataKey: "actions",
              cell: (row) => {
                return (
                  <span className="justify-content-center">
                    <Tooltip placement="top" title={`Delete`}>
                      <Icon
                        className="mx-2 cursor-pointer"
                        onClick={() => {
                          return confirm({
                            title: `Are you sure to delete ${row.app_name} Super-Hostname ?`,
                            okText: "Yes",
                            okType: "danger",
                            cancelText: "No",
                            async onOk() {
                              await deleteSuperhostname(row._id);
                            },
                            onCancel() {
                              console.log("Cancel");
                            },
                          });
                        }}
                        component={() => (
                          <img
                            alt="delete"
                            className="action-icon"
                            src={DeleteSVG}
                          />
                        )}
                      />
                    </Tooltip>
                    <Tooltip placement="top" title={`Details`}>
                      <Icon
                        className="mx-2 cursor-pointer"
                        component={() => (
                          <Link
                            to={
                              mware_param.url +
                              "/setting/update-superhostname/" +
                              row._id
                            }
                          >
                            <img
                              alt="Details"
                              className="action-icon"
                              src={DetailsSVG}
                            />
                          </Link>
                        )}
                      />
                    </Tooltip>
                  </span>
                );
              },
            },
          ]}
          data={superHostnamesList}
          onChange={tableCallbackFunction}
        />
      </div>
    </div>
  );
};

export default memo(AddSuperHostName);
