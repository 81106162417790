import { Spin } from 'antd';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';
import { IGetClientAkamaiUsageStatsData } from '../../../../../interfaces/responses/clients/IGetClientAkamaiUsageStatsResponse';
type TotalUsagePerWeekGraphProps = {
  data?: IGetClientAkamaiUsageStatsData;
  isLoading: boolean;
};
const TotalUsagePerWeekGraph = (props: TotalUsagePerWeekGraphProps) => {
  const { data: apiData, isLoading } = props;
  const [graphData, setGraphData] = useState<ApexOptions>();

  useEffect(() => {
    if (apiData)
      setGraphData((prevState) => {
        prevState = {
          plotOptions: {
            bar: {
              borderRadius: 5,
              columnWidth: '20 px',
            },
          },
          tooltip: {
            marker: {
              show: true,
            },
            enabled: true,
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return renderToString(
                <div className="bg-secondary text-white text-center">
                  <div className="font-weight-bolder py-2 px-5">{series?.[seriesIndex]?.[dataPointIndex]} GB's</div>
                </div>
              );
            },
          },
          grid: {
            show: true,
            strokeDashArray: 5,
            row: {
              colors: undefined,
              opacity: 0.5,
            },
          },
          markers: {
            size: 5,
            hover: {
              size: 6,
            },
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false,
              tools: {
                download: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          colors: ['#9b8bff'],
          xaxis: {
            categories: [],
          },
          labels: apiData.thisMonthData.weeklyData.map((x) => x.week_label),
          series: [
            {
              data: apiData.thisMonthData.weeklyData.filter((_x) => moment(_x.start_date).isSameOrBefore(moment())).map((x) => x.month_total),
            },
          ],
        };

        return { ...prevState };
      });
  }, [apiData]);
  return (
    <>
      {isLoading && (
        <div className="text-center  h-100 w-100">
          <div className="col-md-12 align-self-center">
            <Spin spinning={true} />
          </div>
        </div>
      )}
      {!isLoading && graphData && <ReactApexChart height={300} options={graphData} series={graphData.series} type="area" />}
    </>
  );
};

export default TotalUsagePerWeekGraph;
