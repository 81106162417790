import { IAddHostnameForm } from "../../../../interfaces/forms/areas/uit/hostnames/IAddHostnameForm";
import { IAddSubHostnameForm } from "../../../../interfaces/forms/areas/uit/hostnames/IAddSubHostnameForm";
import { IAddSuperHostnameForm } from "../../../../interfaces/forms/areas/uit/hostnames/IAddSuperHostnameForm";
import { IDeleteSuperhostnameForm } from "../../../../interfaces/forms/areas/uit/hostnames/IDeleteSuperhostnameForm";
import { IGetSuperhostnamesListForm } from "../../../../interfaces/forms/areas/uit/hostnames/IGetSuperhostnamesList";
import { IUpdateHostnameForm } from "../../../../interfaces/forms/areas/uit/hostnames/IUpateHostnameForm";
import { IUpdateSuperHostnameForm } from "../../../../interfaces/forms/areas/uit/hostnames/IUpdateSuperhostnameForm";
import { IGetUITCommonListForm } from "../../../../interfaces/forms/common/IGetListCommonUITForm";
import { IGetHostnameDetailsResponse } from "../../../../interfaces/responses/areas/uit/hostnames/IGetHostnameDetailsResponse";
import { IGetHostnamesListResponse } from "../../../../interfaces/responses/areas/uit/hostnames/IGetHostnamesResponseList";
import { IGetSubHostnameListResponse } from "../../../../interfaces/responses/areas/uit/hostnames/IGetSubhostnameListResponse";
import { IGetSuperHostnameDetailsResponse } from "../../../../interfaces/responses/areas/uit/hostnames/IGetSuperhostnameDetailsResponse";
import { IGetSuperhostnameListResponse } from "../../../../interfaces/responses/areas/uit/hostnames/IGetSuperhostnameListResponse";
import { IBaseResponse } from "../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../utils/axios";
import { API_ENDPOINTS } from "../../../_APIConstants";
import { IUITGetDetailsWithIDForm } from "./../../../../interfaces/forms/common/IGetDetailsWithIDCommonUITForm";

class HostnameServices {
  addHostname = async (data: IAddHostnameForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.UIT.HOSTNAMES.ADD_HOSTNAME, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateHostname = async (
    data: IUpdateHostnameForm
  ): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.UIT.HOSTNAMES.UPDATE_HOSTNAME, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteHostname = async (
    data: IUITGetDetailsWithIDForm
  ): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.UIT.HOSTNAMES.DELETE_HOSTNAME, {
        data,
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  getHostnamesList = async (
    data: IGetUITCommonListForm
  ): Promise<IGetHostnamesListResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.UIT.HOSTNAMES.GET_HOSTNAME_LIST, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  getHostnameDetails = async (
    data: IUITGetDetailsWithIDForm
  ): Promise<IGetHostnameDetailsResponse> => {
    return await axiosInstance
      .get(
        API_ENDPOINTS.V1.UIT.HOSTNAMES.GET_HOSTNAME_DETAILS + "/" + data._id,
        {
          params: data.query_params,
        }
      )
      .then((res) => res.data)
      .catch((err) => err);
  };
  // super hostname

  addSuperHostname = async (
    data: IAddSuperHostnameForm
  ): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.ADD_SUPER_HOSTNAME, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateSuperHostname = async (
    data: IUpdateSuperHostnameForm
  ): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.Client_Settings.UPDATE_SUPER_HOSTNAME, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSuperHostnamesList = async (
    data: IGetSuperhostnamesListForm
  ): Promise<IGetSuperhostnameListResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.GET_SUPER_HOSTNAME_LIST, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSuperHostnameDetails = async (
    data: IUITGetDetailsWithIDForm
  ): Promise<IGetSuperHostnameDetailsResponse> => {
    return await axiosInstance
      .get(
        API_ENDPOINTS.V1.Client_Settings.GET_SUPER_HOSTNAME_DETAILS + data._id,
        {
          params: data.query_params,
        }
      )
      .then((res) => res.data)
      .catch((err) => err);
  };
  addSubHostname = async (
    data: IAddSubHostnameForm
  ): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.ADD_SUB_HOSTNAME, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSubHostnamesList = async (
    data: IGetSuperhostnamesListForm
  ): Promise<IGetSubHostnameListResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.GET_SUB_HOSTNAME_LIST, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteSuperHostname = async (
    data: IDeleteSuperhostnameForm
  ): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.Client_Settings.DELETE_SUPER_HOSTNAME, {
        data,
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
}
export default new HostnameServices();
