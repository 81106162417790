import { IGetSystemUserListForm } from "../../interfaces/forms/user/IGetSystemUserListForm";
import { IAddSystemUserFormValues } from "../../interfaces/forms/user/UserInterfaces";
import { IBaseResponse } from "../../interfaces/responses/IBaseResponse";
import { IAddUserAPIResponse } from "../../interfaces/responses/user/IAddUserResponse";
import { IAuthorizedUsersOfDatastoreResponse } from "../../interfaces/responses/user/IGetAuthorizedUsersOfDatastore";
import { IGetUserDetailsByIdResponse } from "../../interfaces/responses/user/IGetUserDetailsByIdResponse";
import { IGetUsersListResponse } from "../../interfaces/responses/user/IGetUsersListResponse";
import { IUsersByRoleResponse } from "../../interfaces/responses/user/IGetUsersListWithRoleResponse";
import { IUpdateUserDetailsForm } from "../../interfaces/responses/user/IUpdateUserDetails";
import axios from "../../utils/axios";
import { API_ENDPOINTS } from "../_APIConstants";

class UserService {
  GetUserInfo = () => {
    return axios
      .get(API_ENDPOINTS.V1.User.GET_USER_INFO)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  AddUser = (
    values: IAddSystemUserFormValues
  ): Promise<IAddUserAPIResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.User.ADD_USER, values)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  GetAllUsersList = (
    data: IGetSystemUserListForm
  ): Promise<IGetUsersListResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.User.GET_USERS_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  GetUsersListWithRole = ({
    role,
    datastore_id,
    client_id,
  }: any): Promise<IUsersByRoleResponse> => {
    return axios
      .post(
        `${API_ENDPOINTS.V1.User.GET_USERS_LIST_WITH_ROLE}/${role}?instance_id=${client_id}&datastore_id=${datastore_id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  GetAuthorizedUsersListForDatastores = ({
    datastore_id,
  }: any): Promise<IAuthorizedUsersOfDatastoreResponse> => {
    return axios
      .post(
        `${API_ENDPOINTS.V1.Datastore.GET_LIST_OF_AUTHORISED_DATASTORE_USERS}`,
        {
          datastore_id,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  GetUserDetailsById = async (
    user_id: string
  ): Promise<IGetUserDetailsByIdResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.User.GET_USER_DETAILS_BY_ID, { user_id })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  DeleteUserById = async (user_id: string): Promise<IBaseResponse> => {
    return axios
      .delete(API_ENDPOINTS.V1.User.DELETE_USER_BY_ID, {
        data: {
          user_id,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  UpdateUserDetails = async (
    model: IUpdateUserDetailsForm
  ): Promise<IBaseResponse> => {
    return axios
      .put(API_ENDPOINTS.V1.User.UPDATE_USER_DETAILS, model)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new UserService();
