import React from 'react';

const StepLaunchChecklist = () => {
  return (
    <div className="step-section">
      <div className="row">
        <div className="col-12 text-center">
          <div className="h4">Welcome to the Deployment Wizard</div>
          <p>The IMS is a platform that lets non-technical folks configure sophisticated TV Apps, automate the workflows and manage your content.</p>
          <div className="h6">Before we begin let's make sure we have all information needed.</div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Instance Names</h5>
              <dl>
                <dt>CMS Name</dt>
                <dd>This is the name for the Content Management System, names can be like: UK Content or your Service name</dd>
                <dt>CRM Name</dt>
                <dd>This is the name for the Customer Relationship Management, names can be like: UK CRM or your Service name</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Company Information</h5>
              <dl>
                <dt>Company Name</dt>
                <dt>Address</dt>
                <dt>Support Email</dt>
                <dd>Used for sending system messages on tasks excuting.</dd>
                <dt>Content Email</dt>
                <dd>Used if customers report problems with content in the Apps</dd>
                <dt>General Email</dt>
                <dd>Used for sending email to your customers, like registration emails</dd>
                <dt>Website Url</dt>
                <dt>Support Url</dt>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepLaunchChecklist;
