import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { IDatastore } from "../../../../../../../interfaces/responses/clients/IClientInfoResponse";
type IDeleteServiceModelProps = {
  visible: boolean;
  datastore: IDatastore;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}
const DeleteServiceModal = (props: IDeleteServiceModelProps) => {
  const { visible, onSubmit, onCancel, datastore } = props;
  const handleSubmit = (e: any) => {
    onSubmit(datastore);
  };

  return (
    <Modal
      visible={visible}
      width={640}
      footer={false}
      closable={true}
      onCancel={onCancel}
    >
      <div className="p-5 mx-4">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="mb-4">Validate Service Name</h4>
            <Form onFinish={handleSubmit} onFinishFailed={(err_info) => {}}>
              <label className="col-form-label pb-2 d-block text-default">
                Enter Service Name for confirmation
              </label>
              <Form.Item
                className="form-group pb-0"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Service Name!",
                  },
                  {
                    validator: async (rules, value) => {
                      if (value) {
                        if (value === datastore.name) {
                          return await Promise.resolve();
                        } else
                          return await Promise.reject(
                            new Error("Service Name Doesn't Match!")
                          );
                      } else return await Promise.reject();
                    },
                  },
                ]}
              >
                <Input
                  className="form-control height-48 bg-white font-size-14 text-default"
                  placeholder="Enter Service Name"
                />
              </Form.Item>
              <div className="col-md-12 p-0">
                <Button
                  htmlType="submit"
                  className="btn btn-secondary float-right rounded-pill mt-3 width-200 height-48"
                >
                  Validate and Proceed
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteServiceModal;
