import { Button, Form, notification, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { StatusCodes } from "http-status-codes";
import { memo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { CustomNotification } from "../../../common/components/Notification";
import ims from "../../../services/ims";
import { IMSNameParams } from "../../ims";

const TimezoneSettings = () => {
  const [form] = useForm();
  const [isUpdating, setIsUpdating] = useState(false);
  const [getingDetails, setGetingDetails] = useState(true);
  const [fetchingTimezones, setFetchingTimezones] = useState(false);
  const { ims_name } = useParams<IMSNameParams>();
  const [timezones, setTimezones] = useState<string[]>([]);

  const fetchTimezoneSettings = async () => {
    setGetingDetails(true);
    let response = await ims.getTimezoneDetails({
      client_name: ims_name,
    });
    if (response && response.status_code === StatusCodes.OK) {
      form.setFieldsValue({ your_time_zone: response.data.your_timezone });
    } else
      CustomNotification({
        error: response.errors,
      });
    setGetingDetails(false);
  };
  const fetchTimezoneList = async () => {
    setFetchingTimezones(true);
    let response = await ims.getTimezonesList();
    if (response && response.status_code === StatusCodes.OK)
      setTimezones(response.data.timezones);
    else
      CustomNotification({
        error: response.errors,
      });
    setFetchingTimezones(false);
  };
  const handleSubmit = async (values: any) => {
    setIsUpdating(true);
    let response = await ims.addUpdateTimezone({
      ...values,
      client_name: ims_name,
    });

    if (response && response.status_code === StatusCodes.OK)
      notification.success({
        message: "Success",
        description: "Settings updated successfully.",
      });
    else
      CustomNotification({
        error: response.errors,
      });
    setIsUpdating(false);
  };
  useEffectOnce(() => {
    fetchTimezoneSettings();
    fetchTimezoneList();
  });
  return (
    <>
      <div className="container-fluid wrapper">
        <div className="row">
          <div className="col-md-6">
            <h2 className="page-title">Timezone Settings</h2>
            <p className="h6 font-weight-normal line-height-26"></p>
          </div>
        </div>
        <Spin spinning={getingDetails}>
          <Form
            form={form}
            onFinish={async (values) => {
              await handleSubmit(values);
            }}
            initialValues={{
              use_smtp: false,
              use_ssl: false,
            }}
            onFinishFailed={(errorInfo) => {
              console.log(errorInfo);
            }}
          >
            <div className="card mt-5">
              <div className="card-body card-ch-padding">
                <div className="row">
                  <label className="col-md-4 col-form-label">Timezones</label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        required: true,
                        message: "Please select any timezone",
                      },
                    ]}
                    name="your_time_zone"
                  >
                    <Select
                      loading={fetchingTimezones || getingDetails}
                      className="default-select bg-white"
                      placeholder="- Select apps to add -"
                      disabled={
                        fetchingTimezones || getingDetails || isUpdating
                      }
                    >
                      {timezones.map((_timezone, index) => (
                        <Select.Option key={index} value={_timezone}>
                          {_timezone}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="text-right">
              <Button
                loading={isUpdating}
                disabled={getingDetails || isUpdating}
                htmlType="submit"
                className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48"
              >
                Update Settings
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </>
  );
};
export default memo(TimezoneSettings);
