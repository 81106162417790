import { Spin } from 'antd';
import React, { memo, Suspense, useContext } from 'react';
import { matchPath, Redirect, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import PageNotFound from '../../common/components/PageNotFound';
import PrivateRoute from '../../common/routes/PrivateRoute';
import { DrawerContext } from '../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../context_api/DrawerContext/action-types';
import AllAreaRoutes, { IRoute } from '../../routes/AllAreaRoutes';
import getDatastoresMapping from '../../utils/DatastoresMapping';
import { IMSNameParams } from '../ims';

const AreasLayout = () => {
  function MyLazyMethod(name: any, callToImport: any) {
    const lazyComponentsStore: any = React.useRef({}).current;
    if (!lazyComponentsStore[name]) {
      lazyComponentsStore[name] = React.lazy(callToImport);
    }
    return lazyComponentsStore[name];
  }
  const { path } = useRouteMatch();
  const { url } = useRouteMatch();

  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  const { service, service_name } = useParams<IMSNameParams>();
  const currentRouteLocation = useLocation();
  interface defaultKeysForSideBar {
    openKey?: string;
    selectedKey?: string;
  }
  const getDefaultOpenAndSelectedKey = (routes: IRoute[], pathToFind: string): defaultKeysForSideBar => {
    let defaultKeys: defaultKeysForSideBar = {};
    for (let i = 0; i < routes.length; i++) {
      let route = routes[i];
      if (route.routes && route.routes?.length > 0) {
        defaultKeys = getDefaultOpenAndSelectedKey(route.routes, pathToFind);
      } else {
        let pathsFound = matchPath(pathToFind, {
          path: route.path,
        });
        if (pathsFound && pathsFound.isExact) {
          return (defaultKeys = {
            openKey: route.key ?? '',
            selectedKey: route.is_invisible && route.highlitedKey ? route.highlitedKey : route.key,
          });
        }
      }
      if (defaultKeys && defaultKeys.openKey && defaultKeys.selectedKey) {
        defaultKeys.openKey = route.key;
        break;
      }
    }
    if (defaultKeys && defaultKeys.openKey && defaultKeys.selectedKey) return defaultKeys;
    return {
      openKey: '',
      selectedKey: '',
    };
  };
  useEffectOnce(() => {
    let subPath = currentRouteLocation.pathname.replace(url + '/', '');
    let routeToLookFor = subPath;
    let routesToRender: IRoute[] = [];
    let defaultSelectedKey = '';
    let defaultOpenKey = '';
    switch (service?.toLowerCase()) {
      case 'cms':
        let defaultKeysCMS = getDefaultOpenAndSelectedKey(AllAreaRoutes.CMSRoutes.Routes, routeToLookFor);
        routesToRender = AllAreaRoutes.CMSRoutes.Routes;
        defaultSelectedKey = defaultKeysCMS.selectedKey && defaultKeysCMS.selectedKey.trim() !== '' ? defaultKeysCMS.selectedKey : AllAreaRoutes.CMSRoutes.default_selected_key;
        defaultOpenKey = defaultKeysCMS.openKey && defaultKeysCMS.openKey.trim() !== '' ? defaultKeysCMS.openKey : AllAreaRoutes.CMSRoutes.default_open_key;
       if(AllAreaRoutes.CMSRoutes.default_open_key =='all')
       defaultOpenKey = 'all';
        break;
      case 'crm':
        let defaultKeysCRM = getDefaultOpenAndSelectedKey(AllAreaRoutes.CRMRoutes.Routes, routeToLookFor);
        routesToRender = AllAreaRoutes.CRMRoutes.Routes;
        defaultSelectedKey = defaultKeysCRM.selectedKey && defaultKeysCRM.selectedKey.trim() !== '' ? defaultKeysCRM.selectedKey : AllAreaRoutes.CRMRoutes.default_selected_key;
        defaultOpenKey = defaultKeysCRM.openKey && defaultKeysCRM.openKey.trim() !== '' ? defaultKeysCRM.openKey : AllAreaRoutes.CRMRoutes.default_open_key;
        break;
      // case "uit":
      //   routesToRender = AllAreaRoutes.UITRoutes.Routes;
      //   let defaultKeysUIT = getDefaultOpenAndSelectedKey(
      //     AllAreaRoutes.UITRoutes.Routes,
      //     routeToLookFor
      //   );
      //   defaultSelectedKey =
      //     defaultKeysUIT.selectedKey && defaultKeysUIT.selectedKey.trim() !==  ""
      //       ? defaultKeysUIT.selectedKey
      //       : AllAreaRoutes.UITRoutes.default_selected_key;
      //   defaultOpenKey =
      //     defaultKeysUIT.openKey && defaultKeysUIT.openKey.trim() !==  ""
      //       ? defaultKeysUIT.openKey
      //       : AllAreaRoutes.UITRoutes.default_open_key;
      //   break;
      case 'apb':
        routesToRender = AllAreaRoutes.APBRoutes.Routes;
        let defaultKeysAppB = getDefaultOpenAndSelectedKey(AllAreaRoutes.APBRoutes.Routes, routeToLookFor);
        defaultSelectedKey = defaultKeysAppB.selectedKey && defaultKeysAppB.selectedKey.trim() !== '' ? defaultKeysAppB.selectedKey : AllAreaRoutes.APBRoutes.default_selected_key;
        defaultOpenKey = defaultKeysAppB.openKey && defaultKeysAppB.openKey.trim() !== '' ? defaultKeysAppB.openKey : AllAreaRoutes.APBRoutes.default_open_key;
        break;

      default:
        break;
    }
    if (!DrawerState.parent_service_name && service !== 'cms') {
      let mappedDatatstores = getDatastoresMapping(DrawerState.client_info?.datastore ?? []);
      let cmsName = '';
      mappedDatatstores.cms_info.map((cmsInfo) => {
        cmsInfo.crm?.forEach((value) => {
          if (service_name === value.name) cmsName = value.cms_name ?? '';
        });
      });

      DrawerDispatch({
        type: DrawerActionTypes.SET_PARENT_SERVICE_INFO,
        parent_service_name: cmsName,
      });
    }
    DrawerDispatch({
      type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
      default_selected_key: defaultSelectedKey,
      default_open_key: defaultOpenKey,
      routes: routesToRender,
      service_name: service_name!,
      service_type: service!,
      client_dashboard_path: path,
    });
  });

  let menuItemRoutes: IRoute[] = [];
  const renderRecursiveRoute = (item: IRoute) => {
    switch (item.type) {
      case 'collapse':
        const { routes = [] } = item;
        const isExpandable = routes && routes.length > 0;
        if (isExpandable) {
          item.routes?.map((item: any, index: any) => {
            return renderRecursiveRoute(item);
          });
        }
        break;
      case 'item': {
        menuItemRoutes.push(item);
      }
    }
  };

  const mapRoutes = () => {
    AllAreaRoutes.CMSRoutes.Routes.map((item: IRoute, index: any) => {
      return renderRecursiveRoute(item);
    });
    AllAreaRoutes.CRMRoutes.Routes.map((item: IRoute, index: any) => {
      return renderRecursiveRoute(item);
    });
    // AllAreaRoutes.UITRoutes.Routes.map((item: IRoute, index: any) => {
    //   return renderRecursiveRoute(item);
    // });
    AllAreaRoutes.APBRoutes.Routes.map((item: IRoute, index: any) => {
      return renderRecursiveRoute(item);
    });
  };
  mapRoutes();
  return (
    <Suspense
      fallback={
        <div className="row text-center areas-spinner w-100">
          <div className="col-12 align-self-center">
            <div className="p-3 bg-white-semi-transparent d-inline-block rounded">
              <div>
                <Spin size="large" spinning={true} />
              </div>
            </div>
          </div>
        </div>
      }
    >
      <Switch>
        {menuItemRoutes.map((item: IRoute, index: any) => {
          let comp = MyLazyMethod(`${item.component_path}`, () => import(`${item.component_path}`));
          return <PrivateRoute key={index} exact path={`${path}/${item.path}`} component={comp}></PrivateRoute>;
          // }
        })}
        <PrivateRoute path={`${path}/*`} component={() => <PageNotFound path={`${path}`} />}></PrivateRoute>
        {service === 'cms' && <Redirect from={`${path}`} to={`${path}/channels`} />}
        {service === 'crm' && <Redirect from={`${path}`} to={`${path}/customers`} />}
        {service === 'apb' && <Redirect from={`${path}`} to={`${path}/applications`} />}
      </Switch>
    </Suspense>
  );
};

export default memo(AreasLayout);
