import { Spin } from 'antd';
import React from 'react';
import { IGetClientAkamaiUsageStatsData } from '../../../../../../interfaces/responses/clients/IGetClientAkamaiUsageStatsResponse';

type StatisticsGraphsProps = {
  data?: IGetClientAkamaiUsageStatsData;
  isLoading: boolean;
};
const StatisticsGraphs = (props: StatisticsGraphsProps) => {
  const { data: apiData, isLoading } = props;

  return (
    <div id="chart">
      {isLoading && <Spin className="p-3" spinning={isLoading}></Spin>}
      {!isLoading && apiData && (
        <>
          <div className="mt-4 mt-xl-0">
            <h6 className="font-weight-normal">Last Month</h6>
            <div className="row">
              <div className="col-xl-2 col-lg-10 p-0"></div>
              <div className="col-xl-7 col-lg-12 pl-xl-0 ml-n2 d-flex justify-content-center flex-column align-items-center align-items-xl-start">
                {(apiData.lastMonthData.tbData || apiData.lastMonthData.pbData) && (
                  <>
                    <p className="h6">{Math.round(apiData.lastMonthData.gbData)} GB's</p>
                    <p className="h6">{Math.round(apiData.lastMonthData.tbData)} TB's</p>
                    <p className="h6">{Math.round(apiData.lastMonthData.pbData)} PB's</p>
                  </>
                )}
                {(!apiData.lastMonthData.tbData || !apiData.lastMonthData.pbData) && (
                  <>
                    <p className="h6">{Math.round(apiData.lastMonthData.mbData)} MB's</p>
                    <p className="h6">{Math.round(apiData.lastMonthData.gbData)} GB's</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 mt-xl-0">
            <h6 className="font-weight-normal">Current Month</h6>
            <div className="row">
              <div className="col-xl-2 col-lg-10 p-0"></div>
              <div className="col-xl-7 col-lg-12 pl-xl-0 ml-n2 d-flex justify-content-center flex-column align-items-center align-items-xl-start">
                {(apiData.thisMonthData.tbData || apiData.thisMonthData.pbData) && (
                  <>
                    <p className="h6">{Math.round(apiData.thisMonthData.gbData)} GB's</p>
                    <p className="h6">{Math.round(apiData.thisMonthData.tbData)} TB's</p>
                    <p className="h6">{Math.round(apiData.thisMonthData.pbData)} PB's</p>
                  </>
                )}
                {(!apiData.thisMonthData.tbData || !apiData.thisMonthData.pbData) && (
                  <>
                    <p className="h6">{Math.round(apiData.thisMonthData.mbData)} MB's</p>
                    <p className="h6">{Math.round(apiData.thisMonthData.gbData)} GB's</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="ant-card antd"
            style={{
              backgroundColor: '#1ac884',
            }}
          >
            <div className="ant-card-body">
              {(apiData.lastMonthStorageUsage || apiData.thisMonthStorageUsage) && <h5 className="font-weight-bolder">Storage Usage</h5>}
              {apiData.thisMonthStorageUsage && (
                <div className="mt-4 mt-xl-0">
                  <h6 className="font-weight-normal">Current Month</h6>
                  <div className="row">
                    <div className="col-xl-2 col-lg-10 p-0"></div>
                    <div className="col-xl-7 col-lg-12 pl-xl-0 ml-n2 d-flex justify-content-center flex-column align-items-center align-items-xl-start">
                      <p className="h6">
                        {Math.round(apiData.thisMonthStorageUsage.storage_usage)} {apiData.thisMonthStorageUsage.usage_unit.split(" ")?.[0]}'s
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {apiData.lastMonthStorageUsage && (
                <div className="mt-4 mt-xl-0">
                  <h6 className="font-weight-normal">Last Month</h6>
                  <div className="row">
                    <div className="col-xl-2 col-lg-10 p-0"></div>
                    <div className="col-xl-7 col-lg-12 pl-xl-0 ml-n2 d-flex justify-content-center flex-column align-items-center align-items-xl-start">
                      <p className="h6">
                        {Math.round(apiData.lastMonthStorageUsage.storage_usage)} {apiData.lastMonthStorageUsage.usage_unit.split(" ")?.[0]}'s
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const StatisticsGraph = React.memo(StatisticsGraphs);
export default StatisticsGraph;
