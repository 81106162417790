import { Button, Form, Input, notification, Spin, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { StatusCodes } from "http-status-codes";
import { memo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { CustomNotification } from "../../../common/components/Notification";
import ims from "../../../services/ims";
import { IMSNameParams } from "../../ims";

const SMTPSettings = () => {
  const [form] = useForm();
  const [usingSMTP, setUsingSMTP] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [getingDetails, setGetingDetails] = useState(true);
  const { ims_name } = useParams<IMSNameParams>();

  const fetchSMTPSettings = async () => {
    setGetingDetails(true);
    let response = await ims.getSMTPSettings({
      client_name: ims_name,
    });
    if (response && response.status_code === StatusCodes.OK) {
      form.setFieldsValue(response.data);
      response.data.use_smtp ? setUsingSMTP(true) : setUsingSMTP(false);
    } else
      CustomNotification({
        error: response.errors,
      });
    setGetingDetails(false);
  };
  const handleSubmit = async (values: any) => {
    setIsUpdating(true);
    let response = await ims.addUpdateSMTPSettings({
      ...values,
      client_name: ims_name,
    });

    if (response && response.status_code === StatusCodes.OK)
      notification.success({
        message: "Success",
        description: "Settings updated successfully.",
      });
    else
      CustomNotification({
        error: response.errors,
      });
    setIsUpdating(false);
  };
  useEffectOnce(() => {
    fetchSMTPSettings();
  });
  return (
    <>
      <div className="container-fluid wrapper">
        <div className="row">
          <div className="col-md-6">
            <h2 className="page-title">SMTP Settings</h2>
            <p className="h6 font-weight-normal line-height-26"></p>
          </div>
        </div>
        <Spin spinning={getingDetails}>
          <Form
            form={form}
            onFinish={async (values) => {
              await handleSubmit(values);
            }}
            initialValues={{
              use_smtp: false,
              use_ssl: false,
            }}
            onFinishFailed={(errorInfo) => {
              console.log(errorInfo);
            }}
          >
            <div className="card mt-5">
              <div className="card-body card-ch-padding">
                <div className="row">
                  <label className="col-md-4 col-form-label">Use SMTP</label>
                  <Form.Item
                    className="col-md-5"
                    rules={[]}
                    name="use_smtp"
                    valuePropName="checked"
                  >
                    <Switch
                      className="ch-switch"
                      onClick={(checked) => setUsingSMTP(checked)}
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">SMTP Host</label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        ...(!usingSMTP && {
                          required: true,
                          message: "SMTP Host is required",
                        }),
                      },
                    ]}
                    name="host"
                  >
                    <Input
                      disabled={!usingSMTP}
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="Host"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">
                    SMTP Username
                  </label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        ...(!usingSMTP && {
                          required: true,
                          message: "SMTP Username is required",
                        }),
                      },
                    ]}
                    name="email"
                  >
                    <Input
                      disabled={!usingSMTP}
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="Username"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">
                    SMTP Password
                  </label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        ...(!usingSMTP && {
                          required: true,
                          message: "SMTP Password is required",
                        }),
                      },
                    ]}
                    name="password"
                  >
                    <Input
                      disabled={!usingSMTP}
                      type={"password"}
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">SMTP Port</label>
                  <Form.Item
                    className="col-md-5"
                    rules={[
                      {
                        ...(!usingSMTP && {
                          required: true,
                          message: "SMTP Port is required",
                        }),
                      },
                    ]}
                    name="port"
                  >
                    <Input
                      disabled={!usingSMTP}
                      type={"number"}
                      inputMode={"numeric"}
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="Port"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">SMTP From</label>
                  <Form.Item
                    extra="Use only if you don't want your emails to be sent via operator email else leave blank."
                    className="col-md-5"
                    rules={[]}
                    name="from"
                  >
                    <Input
                      disabled={!usingSMTP}
                      className="form-control bg-white font-size-14 text-default"
                      placeholder="Optional ex: no-reply@xyz.com"
                    />
                  </Form.Item>
                  <label className="col-md-4 col-form-label">Use SSL</label>
                  <Form.Item
                    className="col-md-5"
                    rules={[]}
                    name="use_ssl"
                    valuePropName="checked"
                  >
                    <Switch disabled={!usingSMTP} className="ch-switch" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="text-right">
              <Button
                loading={isUpdating}
                disabled={getingDetails || isUpdating}
                htmlType="submit"
                className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48"
              >
                Update Settings
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </>
  );
};
export default memo(SMTPSettings);
