import { StatusCodes } from "http-status-codes";
import Enumerable from "linq";
import { ITranslateText } from "../../interfaces/forms/areas/cms/movie/ITranslateTextForm";
import { IUploadAnyFileForm } from "../../interfaces/forms/common/IUploadAnyFileForm";
import {
  IUploadDRMPemFile,
  IUploadImageFile
} from "../../interfaces/forms/common/IUploadfile";
import { IGetTranslatedTextReponse } from "../../interfaces/responses/areas/cms/movie/IGetTranslatedTextResponse";
import { ICommonMenusListResponse } from "../../interfaces/responses/common/ICommonMenusResponse";
import { ICountriesResponse } from "../../interfaces/responses/common/ICountriesResponse";
import { ICurrenciesResponse } from "../../interfaces/responses/common/ICurrenciesResponse";
import { IFileUploadResponse } from "../../interfaces/responses/common/IFileUploadResult";
import { IFontListResponse } from "../../interfaces/responses/common/IGetFontsListResponse";
import { ILanguageResponse } from "../../interfaces/responses/common/ILanguagesResponse";
import { default as axios, default as axiosInstance } from "../../utils/axios";
import ims from "../ims";
import { API_ENDPOINTS } from "../_APIConstants";

class CommonService {
  UploadFile = (data: IUploadImageFile): Promise<IFileUploadResponse> => {
    let form_data = new FormData();
    form_data.append("media_file", data.media_file, data.media_file.name);
    if (data.dimensions) {
      if (
        Enumerable.from(data.dimensions)
          .where((x) => x.ratio_height === 9 && x.ratio_width === 16)
          .firstOrDefault()
      )
        data.dimensions.push({
          ratio_width: 3,
          ratio_height: 1,
        });
      if (
        Enumerable.from(data.dimensions)
          .where((x) => x.ratio_height === 1 && x.ratio_width === 3)
          .firstOrDefault()
      )
        data.dimensions.push({
          ratio_width: 16,
          ratio_height: 9,
        });
    }

    if (data.dimensions && data.dimensions.length > 0)
      form_data.append("dimensions", JSON.stringify(data.dimensions));

    return axios
      .post(API_ENDPOINTS.V1.Common.UPLOAD_IMAGE_FILE, form_data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  UploadClientCommonFile = (
    data: IUploadImageFile
  ): Promise<IFileUploadResponse> => {
    let form_data = new FormData();
    form_data.append("media_file", data.media_file, data.media_file.name);
    if (data.dimensions) {
      if (
        Enumerable.from(data.dimensions)
          .where((x) => x.ratio_height === 9 && x.ratio_width === 16)
          .firstOrDefault()
      )
        data.dimensions.push({
          ratio_width: 3,
          ratio_height: 1,
        });
      if (
        Enumerable.from(data.dimensions)
          .where((x) => x.ratio_height === 1 && x.ratio_width === 3)
          .firstOrDefault()
      )
        data.dimensions.push({
          ratio_width: 16,
          ratio_height: 9,
        });
    }

    if (data.dimensions && data.dimensions.length > 0)
      form_data.append("dimensions", JSON.stringify(data.dimensions));

    return axios
      .post(
        API_ENDPOINTS.V1.Common.UPLOAD_CLIENT_COMMON_IMAGE_FILE,
        form_data,
        {
          params: data.query_params,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  UploadAnyFile = (data: IUploadAnyFileForm): Promise<IFileUploadResponse> => {
    let form_data = new FormData();
    form_data.append("media_file", data.media_file, data.media_file.name);

    return axios
      .post(API_ENDPOINTS.V1.Common.UPLOAD_ANY_FILE, form_data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  UploadPemFile = (data: IUploadDRMPemFile): Promise<IFileUploadResponse> => {
    let form_data = new FormData();
    form_data.append("package_file", data.package_file, data.package_file.name);

    return axios
      .post(API_ENDPOINTS.V1.Common.UPLOAD_PEM_FILE, form_data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  FetchCountries = async (): Promise<ICountriesResponse> => {
    return axios
      .get(API_ENDPOINTS.V1.Common.FETCH_COUNTRIES)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  FetchCurrencies = async (): Promise<ICurrenciesResponse> => {
    return axios
      .get(API_ENDPOINTS.V1.Common.FETCH_CURRENCIES)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  FetchLanguages = async (): Promise<ILanguageResponse> => {
    return axios
      .get(API_ENDPOINTS.V1.Common.FETCH_LANGUAGES)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  TranslateText = async (
    data: ITranslateText
  ): Promise<IGetTranslatedTextReponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.Common.TRANSLATE_TEXT, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  GetBaseMenus = async (): Promise<ICommonMenusListResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.Common.BASE_MENUS_LIST)
      .then((res) => res.data)
      .catch((error) => error);
  };
  GetFontsList = async (): Promise<IFontListResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.Common.GET_ALL_FONTS_LIST)
      .then((res) => res.data)
      .catch((err) => err);
  };
  FetchWhiteLabelSettings = async (client_name: string) => {
    let response = await ims.getWhiteLabelDetails({
      client_name,
    });
    if (response && response.status_code === StatusCodes.OK) {
      return response.data;
    }
    return null;
  };
}
export default new CommonService();
