import { Spin } from "antd";
import { StatusCodes } from "http-status-codes";
import { useContext, useEffect, useState } from "react";
import { Switch, useHistory, useLocation } from "react-router-dom";
import AuthRoute from "../common/routes/AuthRoute";
import PrivateRoute from "../common/routes/PrivateRoute";
import { AuthContext } from "../context_api/AuthContext";
import { AuthActionTypes } from "../context_api/AuthContext/action-types";
import DrawerContextProvider from "../context_api/DrawerContext";
import { IUserInfoResponse } from "../interfaces/responses/user/IUserInfoResponse";
import login from "../pages/auth/login";
import HomeDashboard from "../pages/home";
import IMSDashboard from "../pages/ims";
import userService from "../services/user";

export const AppRoutes = () => {
  const history = useHistory();
  const { state: AuthState, dispatch: AuthDispatch } = useContext(AuthContext);
  const [gotHasUserDataResponse, setGotHasUserDataResponse] = useState(false);
  const location = useLocation();

  const GetUserInfo = async () => {
    const GetUserInfoResponse: IUserInfoResponse =
      await userService.GetUserInfo();
    if (GetUserInfoResponse.status_code === StatusCodes.OK && GetUserInfoResponse.data) {
      AuthDispatch({
        type: AuthActionTypes.GET_USERINFO_SUCCESS,
        user: GetUserInfoResponse.data,
      });
    } else if (
      GetUserInfoResponse.errors &&
      GetUserInfoResponse?.errors.length > 0
    ) {
      history.push("/login");
    }
    setGotHasUserDataResponse(true);
  };

  useEffect(() => {
    if (AuthState.isAuthenticated && AuthState.user === undefined) {
      AuthDispatch({
        type: AuthActionTypes.GET_USERINFO_START,
      });

      GetUserInfo();
    }
    if (AuthState.isAuthenticated && AuthState.user !== undefined) {
      setGotHasUserDataResponse(true);
    } else if (!AuthState.isAuthenticated) {
      if (
        history.location.pathname === "/" ||
        history.location.pathname !== "/login"
      ) {
        history.push("/login");
      }
      setGotHasUserDataResponse(true);
    }
  }, [location]);
  return (
    <>
      {!gotHasUserDataResponse && (
        <>
          <div className="row text-center h-100 w-100">
            <div className="col-12 align-self-center">
              <Spin
                size="large"
                className={`ajax-global-spin`}
                wrapperClassName={`ajax-global-spin`}
                spinning={true}
              />
            </div>
          </div>
        </>
      )}
      {gotHasUserDataResponse && AuthState.is_userinfo_loading === false && (
        <Switch>
          <AuthRoute path="/login" component={login}></AuthRoute>
          {AuthState.isAuthenticated && (
            <DrawerContextProvider>
              <Switch>
                <PrivateRoute
                  path="/ims/:ims_name"
                  component={IMSDashboard}
                ></PrivateRoute>
                <PrivateRoute path="/" component={HomeDashboard}></PrivateRoute>
              </Switch>
            </DrawerContextProvider>
          )}
        </Switch>
      )}
    </>
  );
};
