import { notification } from 'antd';
import * as HttpStatus from 'http-status-codes';
import { useContext, useState } from 'react';
import { MWare_Logo } from '../../../assets/ims_images';
import { CustomNotification } from '../../../common/components/Notification';
import { AuthContext } from '../../../context_api/AuthContext';
import { AuthActionTypes } from '../../../context_api/AuthContext/action-types';
import { IOTPFormValues, IOTPSubmitValues, ISignInFormValues } from '../../../interfaces/forms/auth/AuthInterfaces';
import { IUserLoginResponse, LoginSuccessObj } from '../../../interfaces/responses/auth/ILoginResponse';
import { IBaseResponse } from '../../../interfaces/responses/IBaseResponse';
import { IUserInfo } from '../../../interfaces/responses/user/IUserInfoResponse';
import AuthService from '../../../services/auth';
import LoginForm from './LoginForm';
import OTPForm from './OTPForm';

interface OTPVerifySuccessResponse extends IBaseResponse {
  data: {
    user: IUserInfo;
    token: string;
    refresh_token: string;
  };
}
const Login = (props: any) => {
  const [loginFormValues, setloginFormValues] = useState<ISignInFormValues>();
  const [LoginSuccessData, setLoginSuccessObj] = useState<LoginSuccessObj>();

  const { dispatch: AuthDispatch } = useContext(AuthContext);
  const [step, setStep] = useState<number>(1);
  const [isVerifyingOTP, setIsVerifying] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const SendOTPCodeForLogin = async (values: ISignInFormValues): Promise<void> => {
    setIsSubmitting(true);
    setloginFormValues(values);
    let response = await AuthService.Login(values);
    if (response.status_code === HttpStatus.StatusCodes.OK) {
      let successResponse = response as IUserLoginResponse;
      notification.success({
        message: 'OTP Sent',
        description: `OTP sent to your registered mobile number ${successResponse.data.mobile_number}`,
      });
      setLoginSuccessObj(successResponse.data);

      setStep(2);
    } else {
      CustomNotification({ error: response.errors });
    }
    setIsSubmitting(false);
  };
  const GoBackToLogin = () => setStep(1);
  const ResendOTP = async () => {
    if (loginFormValues) {
      let response = await AuthService.Login(loginFormValues);
      if (response && response.status_code === HttpStatus.StatusCodes.OK) {
        setLoginSuccessObj(response.data);
        notification.success({
          message: 'OTP Sent',
          description: `A One-Time Password has been sent to ${response.data.mobile_number}`,
        });
      } else CustomNotification({ error: response.errors });
    }
  };
  const HandleOTPSubmit = async (values: IOTPFormValues): Promise<void> => {
    setIsVerifying(true);
    let { otp } = values;
    let verifyValues: IOTPSubmitValues = {
      otp,
      otp_id: LoginSuccessData?.otp_id ?? '',
      user_id: LoginSuccessData?.user_id ?? '',
    };
    let response = await AuthService.OTPVerify(verifyValues);
    if (response.status_code === HttpStatus.StatusCodes.OK) {
      notification.success({
        message: 'Login Successful',
        description: `Logged in successfully.`,
      });
      let successResponse: OTPVerifySuccessResponse = response as OTPVerifySuccessResponse;

      AuthDispatch({
        type: AuthActionTypes.LOGIN_SUCCESS,
        auth: {
          isAuthenticated: true,
          is_userinfo_loading: false,
          user: successResponse.data.user,
          refresh_token: successResponse?.data.refresh_token,
          token: successResponse?.data.token,
        },
      });
    } else CustomNotification({ error: response.errors });

    setIsVerifying(false);
  };
  return (
    <div className="auth d-md-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 auth-left pt-md-5 text-center">
            <img className="my-5 mb-md-0 mt-5" src={MWare_Logo} title="Mware" alt="Mware" />
          </div>
          <div className="col-md-7 col-lg-7 col-xl-6 auth-right bg-white">
            <h2 className="text-primary h4">Login</h2>
            <p className="mb-5">Welcome back</p>
            <div className="animated fadeIn" style={{ display: step === 1 ? 'block' : 'none' }}>
              <LoginForm isLogingIn={isSubmitting} onSubmit={SendOTPCodeForLogin} />
            </div>
            <div className="animated fadeIn" style={{ display: step === 2 ? 'block' : 'none' }}>
              <OTPForm isVerifying={isVerifyingOTP} onSubmit={HandleOTPSubmit} LoginSuccessDataRef={{ ...LoginSuccessData }} GoBackToLogin={GoBackToLogin} ResendOTP={ResendOTP} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
