import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { IMSNameParams } from '../../../..';
import { CustomNotification } from '../../../../../../common/components/Notification';
import { IGetClientAkamaiUsageStatsData } from '../../../../../../interfaces/responses/clients/IGetClientAkamaiUsageStatsResponse';
import akamaiSettingsService from '../../../../../../services/ims';
import TotalUsagePerWeekGraph from '../total_usage_per_week_graph';
import StatisticsGraph from './statistics_graph';
export const CDNTrafficGraph = function () {
  const { ims_name } = useParams<IMSNameParams>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);

  const [data, setData] = useState<IGetClientAkamaiUsageStatsData>();
  const handleFetchData = async () => {
    setIsFetchingData(true);
    let response = await akamaiSettingsService.getAkamaiTrafficUsageStats({
      client_name: ims_name,
    });

    if (response.status_code === StatusCodes.OK) setData(response.data);
    else
      CustomNotification({
        error: response.data,
      });
    setIsFetchingData(false);
  };

  useEffectOnce(() => {
    handleFetchData();
  });

  return (
    <div className="row">
      <div className="col-12 mt-4 mb-2">
        <div className="card">
          <div className="card-body card-padding py-4">
            <div className="row">
              <div className="col-12 mb-2 mb-md-5 mt-md-3">
                <h4 className="text-primary">CDN Traffic Usage</h4>
              </div>

              <div className="col-lg-2 col-md-3">
                <h5 className="">Statistics</h5>
                <div className="mb-2">
                  <StatisticsGraph data={data} isLoading={isFetchingData} />
                </div>
              </div>
              <div className="col-lg-10 col-md-9 mb-2 mt-3 mt-md-0">
                <h5 className="mb-4">
                  Weekly Usage
                  <span className="text-default h6 font-weight-normal">
                     {" "}(GB’s for {moment().format('MMMM')} {moment().format('YYYY')})
                  </span>
                </h5>
                {/* <TotalUsageGraph /> */}
                <TotalUsagePerWeekGraph data={data} isLoading={isFetchingData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
