import { Button, Form, Modal, Select } from "antd";
import { StatusCodes } from "http-status-codes";
import React, { memo, useState } from "react";
import { useEffectOnce } from "react-use";
import { IDatastore } from "../../../../../../../interfaces/responses/clients/IClientInfoResponse";
import { IUsersByRoleResponseUser } from "../../../../../../../interfaces/responses/user/IGetUsersListWithRoleResponse";
import userService from "../../../../../../../services/user";
const { Option } = Select;

type IAssignDatastoreToUserModelProps = {
  visible: boolean;
  onSubmit: (v: any) => void;
  onCancel: () => void;
  rowRecord: IDatastore;
  client_id: string;
};

const AssignDatastoreToUserModal = (
  props: IAssignDatastoreToUserModelProps
) => {
  const { visible, onCancel, rowRecord, client_id } = props;
  const [usersList, setUsersList] = useState<Array<IUsersByRoleResponseUser>>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleFinish = (values: any) => {
    props.onSubmit(values);
  };

  useEffectOnce(() => {
    setIsLoading(true);
    const callMethod = async () => {
      let response = await userService.GetUsersListWithRole({
        role: rowRecord.type,
        datastore_id: rowRecord._id,
        client_id,
      });
      if (response.status_code === StatusCodes.OK) {
        setUsersList(() => response.data.users);
      }
      setIsLoading(false);
    };
    callMethod();
  });
  return (
    <Modal
      visible={visible}
      width={640}
      footer={false}
      closable={true}
      onCancel={onCancel}
    >
      <div className="p-5 mx-4">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="mb-4">Add a user </h4>
            <Form
              onFinish={handleFinish}
              onFinishFailed={({ values, errorFields, outOfDate }: any) => {}}
            >
              <label className="col-form-label">
                Select a user that will have access
              </label>
              <Form.Item
                className="form-group pb-0"
                name="user_id"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "Please Select User!",
                  },
                ]}
              >
                <Select
                  loading={isLoading}
                  disabled={isLoading}
                  className="default-select bg-white"
                  placeholder="- Select a User -"
                >
                  {usersList &&
                    usersList.map((doc, index) => {
                      return (
                        <Option key={index} value={doc._id}>
                          {doc.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <div className="col-md-12 p-0">
                <Button
                  htmlType="submit"
                  className="btn btn-secondary float-right rounded-pill mt-3 width-200 height-48"
                >
                  Give Access to User
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(AssignDatastoreToUserModal);
