import { Button, Form, Input, Modal, Select, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { StatusCodes } from "http-status-codes";
import _ from "lodash";
import { memo, useEffect, useState } from "react";
import { IAddNewCurrency } from "../../../../../../../interfaces/responses/areas/cms/settings/currencies/IAddNewCurrency";
import { ICurrency } from "../../../../../../../interfaces/responses/common/ICurrenciesResponse";
import common from "../../../../../../../services/common";

type IAddNewCurrencyModalProps = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: {
    currency_id: string;
    is_default: boolean;
    name: string;
  }) => void;
  alreadyAddedCurrencies: IAddNewCurrency[];
};

const AddNewCurrencyModal = ({
  visible,
  onCancel,
  onSubmit,
  alreadyAddedCurrencies,
}: IAddNewCurrencyModalProps) => {
  const [form] = useForm();
  const [currencies, setCurrencies] = useState<ICurrency[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const getCurrencies = async () => {
    setIsLoading(true);
    let response = await common.FetchCurrencies();
    if (response && response.status_code === StatusCodes.OK) {
      setCurrencies(
        _.differenceBy(response.data.currencies, alreadyAddedCurrencies, "_id")
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    (() => {
      form.setFieldsValue({
        name,
      });
    })();
  }, [name]);
  useEffect(() => {
    (async () => await getCurrencies())();
  }, [alreadyAddedCurrencies]);
  return (
    <div>
      <Modal
        visible={visible}
        width={640}
        footer={false}
        closable={true}
        onCancel={onCancel}
      >
        <div className="p-5 mx-4">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h4 className="mb-4">{"Add new currency"}</h4>
              <Form
                form={form}
                initialValues={{
                  is_default: false,
                }}
                onFinish={(v) => onSubmit(v)}
                onFinishFailed={(errorInfo) => {}}
              >
                <label className="col-form-label">Currency</label>
                <Form.Item
                  name="currency_id"
                  rules={[
                    {
                      required: true,
                      message: "Please Select The Currency!",
                    },
                  ]}
                >
                  <Select
                    className="default-select"
                    defaultValue={"-Select-"}
                    loading={isLoading}
                    disabled={isLoading}
                    onSelect={(selectedValue: any, { title }: any) => {
                      setName(title);
                    }}
                  >
                    {currencies.map((item) => (
                      <Select.Option
                        key={item._id}
                        value={item._id}
                        title={item.isoCurrency}
                      >
                        {item.isoCurrency}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <label className="col-form-label">Is Default</label>
                <Form.Item
                  name="is_default"
                  extra="Adding a Currency can be a long process if you have a lot of Content, Product and CRM Services"
                >
                  <Switch
                    className="ch-switch"
                    defaultChecked={false}
                    checked={isDefault}
                    onChange={() => setIsDefault(!isDefault)}
                  />
                </Form.Item>
                <Form.Item name="name" hidden>
                  <Input value={name}></Input>
                </Form.Item>
                <div className="col-md-12 p-0">
                  <Button
                    htmlType="submit"
                    className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48"
                  >
                    Add new currency
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default memo(AddNewCurrencyModal);
