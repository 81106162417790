import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { NewServicePNG } from "../../../../../assets/ims_images";
import { IDatastore } from "../../../../../interfaces/responses/clients/IClientInfoResponse";

export const NewDatastoreService = (cms: IDatastore) => {
  let { _id } = cms;
  let { url } = useRouteMatch();
  return (
    <>
      <div className="col-12 mt-md-4">
        <div className="card shadow-none">
          <div className="card-body card-padding py-4 border rounded mb-2">
            <div className="row">
              <div className="col-md-3 col-lg-2 col-xl-1">
                <img src={NewServicePNG} title="User" alt="User" />
              </div>
              <div className="col-md-9 col-lg-6 col-xl-6 pl-xl-5 mt-3 mt-lg-0">
                <h4 className="text-primary">New Service</h4>
                <p className="h6 font-weight-normal line-height-26">
                  Service is a set of tools taht contains Customer Management.
                  Analytics UI Designer and Restless API,s. You can add many
                  Services as you want.
                </p>
              </div>
              <div className="col-md-12 col-lg-4 col-xl-5 d-flex justify-content-lg-end justify-content-center align-items-center mt-3 mt-lg-0">
                <Link
                  className="btn btn-secondary rounded-pill btn-default"
                  to={`${url}/${_id}/new_datastore_service`}
                >
                  Create New Services
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
