import { IAddUpdateAkamaiSettingsForm } from '../../interfaces/forms/ims/IAddUpdateAkamaiSettingsForm';
import { IAddUpdateSendGridSettingsForm } from '../../interfaces/forms/ims/IAddUpdateSendGridSettingsForm';
import { IAddUpdateSMTPSettingsForm } from '../../interfaces/forms/ims/IAddUpdateSMTPSettingsForm';
import { IAddUpdateTimezoneForm } from '../../interfaces/forms/ims/IAddUpdateTimezoneForm';
import { IAddUpdateWhiteLabelForm } from '../../interfaces/forms/ims/IAddUpdateWhiteLabelForm';
import { IGetSendGridSettingDetailsForm } from '../../interfaces/forms/ims/IGetSendGridSettingDetailsForm';
import { IGetClientAkamaiUsageStatsResponse } from '../../interfaces/responses/clients/IGetClientAkamaiUsageStatsResponse';
import { IBaseResponse } from '../../interfaces/responses/IBaseResponse';
import { IGetClientAkamaiSettingsResponse } from '../../interfaces/responses/ims/IGetClientAkamaiSettingsResponseForm';
import { IAddUpdateClientSendGridSettingsResponse } from '../../interfaces/responses/ims/IGetClientSendGridSettingDetailsResponse';
import { IGetClientSMTPSettingDetailsResponse } from '../../interfaces/responses/ims/IGetClientSMTPSettingDetailsResponse';
import { IGetTimezoneDetailsResponse } from '../../interfaces/responses/ims/IGetTimezoneDetailsResponse';
import { IGetTimezonesListResponse } from '../../interfaces/responses/ims/IGetTimezonesListResponse';
import { IGetWhiteLabelDetailsResponse } from '../../interfaces/responses/ims/IGetWhiteLabelDetailsResponse';
import axiosInstance from '../../utils/axios';
import { IGetSMTPSettingDetailsForm } from './../../interfaces/forms/ims/IGetSMTPSettingDetailsForm';
import { API_ENDPOINTS } from './../_APIConstants';

class IMSService {
  getSMTPSettings = async (data: IGetSMTPSettingDetailsForm): Promise<IGetClientSMTPSettingDetailsResponse> => {
    return axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.GET_SMTP_DETAILS, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  addUpdateSMTPSettings = async (data: IAddUpdateSMTPSettingsForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.Client_Settings.ADD_UPDATE_SMTP, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSendgridSettings = async (data: IGetSendGridSettingDetailsForm): Promise<IAddUpdateClientSendGridSettingsResponse> => {
    return axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.GET_SENDGRID_DETAILS, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  addUpdateSendgridSettings = async (data: IAddUpdateSendGridSettingsForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.Client_Settings.ADD_UPDATE_SENDGRID, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getTimezonesList = async (): Promise<IGetTimezonesListResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.Client_Settings.GET_TIMEZONES_LIST)
      .then((res) => res.data)
      .catch((err) => err);
  };
  addUpdateTimezone = async (data: IAddUpdateTimezoneForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.Client_Settings.ADD_UPDATE_TIMEZONE, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getTimezoneDetails = async (data: IGetSMTPSettingDetailsForm): Promise<IGetTimezoneDetailsResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.GET_TIMEZONE_DETAIL, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  addUpdateWhiteLabel = async (data: IAddUpdateWhiteLabelForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.Client_Settings.ADD_UPDATE_WHITE_LABEL_SETTINGS, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getWhiteLabelDetails = async (data: IGetSMTPSettingDetailsForm): Promise<IGetWhiteLabelDetailsResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.GET_WHTIE_LABEL_SETTINGS, data)
      .then((res) => res.data)
      .catch((err) => err);
  };

  //
  getAkamaiSettings = async (data: IGetSendGridSettingDetailsForm): Promise<IGetClientAkamaiSettingsResponse> => {
    return axiosInstance
      .post(API_ENDPOINTS.V1.Client_Settings.GET_AKAMAI_SETTING_DETAILS, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  addUpdateAkamaiSettings = async (data: IAddUpdateAkamaiSettingsForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.Client_Settings.ADD_UPDATE_AKAMAI_SETTING_DETAILS, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAkamaiTrafficUsageStats = async (data: IGetSendGridSettingDetailsForm): Promise<IGetClientAkamaiUsageStatsResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.Client.GET_AKAMAI_TRAFFIC_USAGE_STATS, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
}

export default new IMSService();
