import { Button, Image, Result } from "antd";
import { Icon404 } from "../../../assets/ims_images";

type PageNotFoundProps = {
  path: string;
}
const PageNotFound = ({ path }: PageNotFoundProps) => {
  return (
    <Result
      icon={<Image src={Icon404} preview={false} width={400} />}
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default PageNotFound;
