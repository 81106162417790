import { Space, Spin } from "antd";
import React from "react";

const StepCongrats = ({ isLoading }: any) => {
  return (
    <div className="card">
      <div className="card-body">
        {isLoading && (
          <div>
            <div className="row text-center h-100 w-100">
              <div className="col-12 align-self-center">
                <Space
                  size="middle"
                  style={{
                    height: "35vh",
                  }}
                >
                  <Spin size="large" spinning={isLoading}></Spin>
                </Space>
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <>
            <h4 className="text-primary">Congratulations!!!</h4>
            <p>
              You have successfully created your Deployment and User
              Interface.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default StepCongrats;
