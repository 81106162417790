import { Spin } from 'antd';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useEffectOnce } from 'react-use';
import datastores from '../../../../../../services/datastores';

type RadialGraphElementsProps = {
  ims_name: string;
  cms: string;
};
interface ICustomerRadialStatObj {
  options: ApexCharts.ApexOptions;
  series: number[];
}
const RadialGraphElements = (props: RadialGraphElementsProps) => {
  const { cms, ims_name } = props;
  let exampleData: { options: ApexCharts.ApexOptions; series: number[] }[] = [
    {
      options: {
        labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '40%',
              margin: 0,
            },
            dataLabels: {
              name: {
                show: true,
                offsetY: -3,
                fontSize: '12px',
              },
              value: {
                fontSize: '16px',
                color: '#111',
                offsetY: 0,
                formatter: function (val: any) {
                  return val;
                },
              },
              total: {
                show: true,
                label: 'Premier',
                formatter: function (w: any) {
                  return w.globals.seriesTotals.reduce((a: any, b: any) => {
                    return a + b;
                  });
                },
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        colors: ['#1ac884', '#3a90fa', '#fdbd58', '#f13131'],
      },
      series: [44, 55, 67, 83],
    },
    {
      options: {
        labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '40%',
              margin: 0,
            },
            dataLabels: {
              name: {
                show: true,
                offsetY: -3,
                fontSize: '12px',
              },
              value: {
                fontSize: '16px',
                color: '#111',
                offsetY: 0,
                formatter: function (val: any) {
                  return val;
                },
              },
              total: {
                show: true,
                label: 'dd',
                formatter: function (w: any) {
                  return w.globals.seriesTotals.reduce((a: any, b: any) => {
                    return a + b;
                  });
                },
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        colors: ['#1ac884', '#3a90fa', '#fdbd58', '#f13131'],
      },
      series: [44, 55, 67, 83],
    },
  ];
  const [isFetchingStats, setIsFetchingStats] = useState(true);
  const [data, setData] = useState<ICustomerRadialStatObj[]>([]);

  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 1000);
  const getCRMCustomerStats = async () => {
    setIsFetchingStats(true);
    let response = await datastores.getCustomerAccountStats({
      query_params: {
        cms,
        instance: ims_name,
      },
    });
    if (response.status_code === StatusCodes.OK) {
      let _responseData = response.data.map((x) => {
        let obj: ICustomerRadialStatObj = {
          options: {
            labels: Object.keys(x.counting),
            plotOptions: {
              radialBar: {
                hollow: {
                  size: '40%',
                  margin: 0,
                },
                dataLabels: {
                  name: {
                    show: true,
                    offsetY: -3,
                    fontSize: '12px',
                  },
                  value: {
                    fontSize: '16px',
                    color: '#111',
                    offsetY: 0,
                    formatter: function (val: any) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    label: x.crm_name,
                    formatter: function (w: any) {
                      return w.globals.seriesTotals.reduce((a: any, b: any) => {
                        return a + b;
                      });
                    },
                  },
                },
              },
            },
            stroke: {
              lineCap: 'round',
            },
            colors: ['#1ac884', '#3a90fa', '#fdbd58', '#f13131'],
          },
          series: Object.values(x.counting),
        };
        return obj;
      });
      setData(_responseData);
    }
    setIsFetchingStats(false);
  };
  useEffectOnce(() => {
    getCRMCustomerStats();
  });
  return (
    <div className="">
      {isFetchingStats && (
        <div className="text-center  h-100 w-100">
          <div className="col-md-12 align-self-center">
            <Spin size="large" spinning={true} />
          </div>
        </div>
      )}

      {!isFetchingStats && (
        <div id="chart" className="justify-content-left">
          <div className="row">
            {data.map((data, i) => {
              return (
                <div key={i} className="col-md-6 col-xl-3">
                  <ReactApexChart options={data.options} series={data.series} type="radialBar" height="340" />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(RadialGraphElements);
