import Icon from "@ant-design/icons";
import { Button, notification, Tooltip } from "antd";
import confirm from "antd/lib/modal/confirm";
import { StatusCodes } from "http-status-codes";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortingRule } from "react-table";
import { DeleteSVG, DetailsSVG, UserSVG } from "../../assets/ims_images";
import { CustomNotification } from "../../common/components/Notification";
import ReactBCTable from "../../common/components/ReactBCTable";
import { IReactBCTableColumn } from "../../common/components/ReactBCTable/Interfaces/IReactBCTableColumn.interface";
import { IGetSystemUserListForm } from "../../interfaces/forms/user/IGetSystemUserListForm";
import {
  IPaginatedAndFilterResponse,
  PaginatedAndFilterResponseDefaultValues
} from "../../interfaces/responses/common/IPaginatedAndFilterResponse";
import {
  IGetUsersListResponse,
  IGetUsersList_User
} from "../../interfaces/responses/user/IGetUsersListResponse";
import { default as user, default as userService } from "../../services/user";

const ListSystemUsers = () => {
  const [usersList, setUsersList] = useState<IGetUsersList_User[]>([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(true);
  const [tableSearchState, setTableSearchState] =
    useState<IGetSystemUserListForm>({
      pageNumber: 1,
      pageSize: 50,
    });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(
    PaginatedAndFilterResponseDefaultValues
  );
  const handleDeleteUser = async (row: IGetUsersList_User) => {
    let response = await user.DeleteUserById(row._id);

    if (response?.status_code === StatusCodes.OK) {
      notification.success({
        message: "Success",
        description: "User deleted successfully.",
      });
      getUsersList(tableSearchState);
    } else CustomNotification({ error: response.errors });
  };
  let history = useHistory();
  let reactBCTableColumns: IReactBCTableColumn<IGetUsersList_User>[] = [
    {
      title: "Username",
      dataKey: "email",
      hasSort: true,
      cell: (obj) => (
        <span>
          <img
            className="mr-4"
            alt="user"
            style={{ height: "30px" }}
            src={UserSVG}
          />
          {obj.email}
        </span>
      ),
    },
    {
      title: "Roles",
      dataKey: "roles",
      cell: (obj) => (
        <span>
          {obj.clients &&
            obj.clients.map((client, i: number) => {
              let roleString = "";
              if (client.roles && client.roles.length > 0) {
                client.roles.map(
                  (role) =>
                    (roleString +=
                      role.name.charAt(0).toUpperCase() +
                      role.name.substring(1, role.name.length) +
                      " | ")
                );
                return <span key={i}>{roleString}</span>;
              }
              return <></>;
            })}
        </span>
      ),
    },
    {
      title: "Actions",
      dataKey: "action",
      cell: (row) => (
        <span className="justify-content-end">
          {row.can_be_deleted && (
            <Tooltip placement="top" title={`Delete`}>
              <Icon
                className="mx-2 cursor-pointer"
                component={() => (
                  <img alt="Delete" className="action-icon" src={DeleteSVG} />
                )}
                onClick={() => {
                  confirm({
                    title: `Are you sure to delete this user ?`,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    async onOk() {
                      await handleDeleteUser(row);
                    },
                    onCancel() {},
                  });
                }}
              />
            </Tooltip>
          )}
          <Tooltip placement="top" title={`More Action`}>
            <Icon
              className="mx-2 cursor-pointer"
              component={() => (
                <img alt="Details" className="action-icon" src={DetailsSVG} />
              )}
              onClick={() => {
                history.push(`/system-users/details/${row._id}`);
              }}
            >
              Details
            </Icon>
          </Tooltip>
        </span>
      ),
    },
  ];
  const onTableChange = useCallback(
    async (
      pageNumber: number,
      pageSize: number,
      sortBy: SortingRule<{}> | undefined,
      filters: any
    ) => {
      let newState = {
        ...tableSearchState,
        sortBy,
        filters,
        pageNumber: pageNumber,
        pageSize,
      };
      setTableSearchState((prevState) => ({
        ...prevState,
        ...newState,
      }));
      await getUsersList(newState);
    },
    []
  );
  let getUsersList = async (tableSearchState: IGetSystemUserListForm) => {
    setIsFetchingUsers(true);
    let response: IGetUsersListResponse = await userService.GetAllUsersList(
      tableSearchState
    );
    if (response.status_code === StatusCodes.OK) {
      setUsersList(() => response.data.data);
      setPagination(response.data);
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsFetchingUsers(() => false);
  };

  return (
    <div>
      <div className="container-fluid wrapper p-3">
        <div className="row">
          <div className="col-md-7 p-3">
            <h2 className="page-title">System Users</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Button
              type="primary"
              onClick={()=>{
                history.push("/system-users/add-user")
              }}
              className="rounded-pill width-240 height-54 float-right"
            >   
              <span className="px-5 font-weight-bold">Add User</span>
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4">
            <ReactBCTable
              isLoading={isFetchingUsers}
              onChange={onTableChange}
              data={usersList}
              totalRecords={pagination.count}
              pageSize={pagination.pageSize}
              tableId={"system_users_list"}
              columns={reactBCTableColumns}
            ></ReactBCTable>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ListSystemUsers);
