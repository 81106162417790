import { Card } from "antd";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../context_api/AuthContext";
import { IUserClient } from "../../../../interfaces/responses/user/IUserInfoResponse";
import IMSClient from "./components/clients";

const AllClients = () => {
  const { state: AuthState } = useContext(AuthContext);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3 p-2 btn">
          <Link to="/add-new-client">
            <Card hoverable className="p-5 rounded">
              <IMSClient {...{ client_name: "", client_id: "0", roles: [] }} />
            </Card>
          </Link>
        </div>
        {AuthState.user?.clients.map((client: IUserClient, index: number) => (
          <div className="col-3 p-2 btn" key={`${index}`}>
            <Link to={`/ims/${client.client_name}`}>
              <Card hoverable className="p-5 rounded">
                <IMSClient {...client} />
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllClients;
