import { CloudFilled } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";
import { CMS_PNG } from "../../../../../../../assets/ims_images";

const StepInstanceSettings = () => {
  return (
    <>
      <div className="card mt-3">
        <div className="card-body">
          <div className="row ml-3">
            <img src={CMS_PNG} title="User" alt="User" className="img-fluid" />
            <div className="col-md-10 ml-3">
              <h6 className="text-primary">CMS(Content Management System)</h6>
              <p>
                This is the name for the Content Management System, names can be
                like UK Content or your Service name
              </p>
            </div>
          </div>
          <div className="row ml-5">
            <div className="ml-5">
              <CloudFilled className="height-48 mt-3 bg-light form-control" />
            </div>

            <Form.Item
              name="cms_name"
              className="col-md-4 mt-3 p-0"
              rules={[
                {
                  required: true,
                  message: "CMS Name is required!",
                },
                {
                  whitespace: true,
                  message: "CMS Name can not contain whitespace",
                },
                {
                  validator: async (rules, value) => {
                    if (value && value.includes(" "))
                      return await Promise.reject(
                        new Error("CMS Name can not contain whitespace")
                      );
                    else return await Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                className="form-control height-48 bg-white font-size-14 text-default"
                placeholder="CMS Name"
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="row ml-3">
            <img src={CMS_PNG} title="User" alt="User" className="img-fluid" />
            <div className="col-md-10 ml-3">
              <h6 className="text-primary">
                CRM(Customer Relationship Management)
              </h6>
              <p>
                This is the name for the Customer Relationship Management, names
                can be like UK Content or your Service name
              </p>
            </div>
          </div>
          <div className="row ml-5">
            <div className="ml-5">
              <CloudFilled className="height-48 mt-3 bg-light form-control" />
            </div>
            <Form.Item
              name="crm_name"
              validateFirst={true}
              className="col-md-4 p-0 mt-3"
              rules={[
                {
                  required: true,
                  message: "CRM Name is required!",
                },
                {
                  whitespace: true,
                  message: "CRM Name can not contain whitespace",
                },
                {
                  validator: async (rules, value) => {
                    if (value && value.includes(" "))
                      return await Promise.reject(
                        new Error("CRM Name can not contain whitespace")
                      );
                    else return await Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                className="form-control height-48 bg-white font-size-14 text-default"
                placeholder="CRM Name"
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepInstanceSettings;
