import { Select } from "antd";
import { ContentState, convertToRaw, EditorState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
const placeholders = [
  { value: "serviceid", key: "Service ID" },
  { value: "customername", key: "Customer Name" },
  { value: "logins", key: "Logins" },
  { value: "subscriptioninfo", key: "Subscription Info" },
  { value: "trialinfo", key: "Trial Info" },
  { value: "resellername", key: "Reseller Name" },
  { value: "customerlogin", key: "Customer Login" },
  { value: "ticketurl", key: "Ticket URL" },
  { value: "webtvurl", key: "Web TV URL" },
  { value: "trackingid", key: "Tracking ID" },
  { value: "firstname", key: "First Name" },
  { value: "lastname", key: "Last Name" },
  { value: "productname", key: "Product Name" },
  { value: "expirationdate", key: "Expiration Date" },
  { value: "brandname", key: "Brand Name" },
  { value: "companylogo", key: "Company Logo" },
  { value: "paymentamount", key: "Payment Amount" },
];
export const PlaceholderSelectionOptionsComponent = (props: any) => {
  const { onChange, editorState } = props;

  const onSelectChange = (selectedValue: any) => {
    let currentContent = editorState!.getCurrentContent();
    let values = draftToHtml(convertToRaw(currentContent));
    let currentSelection = editorState.getSelection();
    let foundPlaceholder = placeholders.filter(
      (_value) => _value.value === selectedValue
    )[0];

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      `<${foundPlaceholder.value}>`
    );
    const contentBlock = htmlToDraft(values);
    if (contentBlock) {
      const contentState = new ContentState(newContent);
      let editorState = EditorState.createWithContent(contentState);
      if (editorState) {
        onChange(editorState);
      }
    }
  };

  return (
    <>
      <Select
        className="default-select "
        onChange={onSelectChange}
        placeholder="-Select Placeholder"
      >
        {placeholders.map((pholder) => (
          <Select.Option key={pholder.key} value={pholder.value}>
            {pholder.key}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
