import { Layout, notification, Spin } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useContext } from 'react';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import IMSDashboardHeader from '../../common/components/IMSDashboardHeader';
import LeftSidebar from '../../common/components/LeftSidebar';
import PageNotFound from '../../common/components/PageNotFound';
import PrivateRoute from '../../common/routes/PrivateRoute';
import { DrawerContext } from '../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../context_api/DrawerContext/action-types';
import { IClientInfoResponse } from '../../interfaces/responses/clients/IClientInfoResponse';
import ClientService from '../../services/client';
import common from '../../services/common';
import AreasLayout from '../areas';
import AkamaiSettings from '../settings/akamai_settings';
import SendgridSettings from '../settings/sendgrid_settings';
import SMTPSettings from '../settings/smtp_settings';
import AddSuperHostName from '../settings/super_hostnames';
import UpdateSuperHostname from '../settings/super_hostnames/sub_hostnames';
import TimezoneSettings from '../settings/timezones_setting';
import UserLogs from '../settings/userlog_settings';
import WhitelabelSettings from '../settings/whitelabel_settings';
import IMSHome from './pages/home';
import NewDatastoreServiceForms from './pages/home/components/deployment_dashboard/new_datastore_service_form';
import NewDeploymentForm from './pages/home/components/deployment_dashboard/new_deployment_form';
export type IMSNameParams = {
  ims_name: string;
  service?: string;
  service_name?: string;
};
const IMSDashboard = () => {
  const { ims_name } = useParams<IMSNameParams>();
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  const { path } = useRouteMatch();

  useEffectOnce(() => {
    let favicon: any = document.getElementById('favicon');
    DrawerDispatch({
      type: DrawerActionTypes.SET_CLIENT_INFO_LOADING,
      is_client_info_loading: true,
    });
    const getClientInfo = async () => {
      if (ims_name) {
        let ClientInfo: IClientInfoResponse = await ClientService.GetClientInfo(ims_name);
        if (ClientInfo.status_code === StatusCodes.OK && ClientInfo.data) {
          DrawerDispatch({
            type: DrawerActionTypes.SET_CLIENT_INFO,
            client_info: ClientInfo.data,
          });
          let whiteLabel = await common.FetchWhiteLabelSettings(ClientInfo.data.name);
          if (whiteLabel) {
            DrawerDispatch({
              type: DrawerActionTypes.UPDATE_WHITE_LABEL_INFO,
              ...whiteLabel,
            });
            if (whiteLabel.fav_icon && favicon) favicon.href = whiteLabel.fav_icon;
          }
        } else {
          if (ClientInfo.errors)
            notification.error({
              message: JSON.stringify(ClientInfo.errors[0]),
            });
        }
        DrawerDispatch({
          type: DrawerActionTypes.SET_CLIENT_INFO_LOADING,
          is_client_info_loading: false,
        });
      }
    };
    getClientInfo();
  });
  return (
    <>
      {DrawerState.is_client_info_loading && (
        <>
          <div className="row text-center h-100 w-100">
            <div className="col-12 align-self-center">
              <Spin size="large" className={`ajax-global-spin`} wrapperClassName={`ajax-global-spin`} spinning={true} />
            </div>
          </div>
        </>
      )}
      {DrawerState.client_info && !DrawerState.is_client_info_loading && (
        <Layout>
          {DrawerState.is_sidebar_visible && <LeftSidebar />}
          <Layout>
            <IMSDashboardHeader
              {...{
                isSideBarShow: DrawerState.is_sidebar_visible,
                path,
              }}
            />
            <Switch>
              <PrivateRoute path={`${path}/new_deployment`} component={NewDeploymentForm}></PrivateRoute>
              <PrivateRoute path={`${path}/setting/smtp-setting`} component={SMTPSettings}></PrivateRoute>
              <PrivateRoute path={`${path}/setting/sendgrid-setting`} component={SendgridSettings}></PrivateRoute>
              <PrivateRoute path={`${path}/setting/akamai-settings`} component={AkamaiSettings}></PrivateRoute>
              <PrivateRoute path={`${path}/setting/timezones-setting`} component={TimezoneSettings}></PrivateRoute>
              <PrivateRoute path={`${path}/setting/whitelabel-setting`} component={WhitelabelSettings}></PrivateRoute>
              <PrivateRoute path={`${path}/setting/user-logs`} component={UserLogs}></PrivateRoute>
              <PrivateRoute exact path={`${path}/setting/update-superhostname/:hostname_id`} component={UpdateSuperHostname}></PrivateRoute>
              <PrivateRoute path={`${path}/setting/super-hostname`} component={AddSuperHostName}></PrivateRoute>
              {/* sub domains */}

              <PrivateRoute path={`${path}/:cms_datastore_id/new_datastore_service`} component={NewDatastoreServiceForms}></PrivateRoute>
              <PrivateRoute path={`${path}/:service/:service_name`} component={AreasLayout}></PrivateRoute>

              <PrivateRoute path={`${path}/*`} component={PageNotFound}></PrivateRoute>
              <PrivateRoute path={`${path}`} component={IMSHome}></PrivateRoute>
            </Switch>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default IMSDashboard;
