import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../..';
import { AuthContext } from '../../../../../../context_api/AuthContext';
import { hasRole } from '../../../../../../utils/Auth/role.auth';
import { ICMSInfo } from '../../../../../../utils/DatastoresMapping';
import { NewDatastoreService } from '../new_service';
import DeploymentDashboardDatastores from './datastores';
import RadialGraphElements from './radial_graph';

const DeploymentDashboard = (data: ICMSInfo) => {
  const { state: AuthState } = useContext(AuthContext);
  const { ims_name } = useParams<IMSNameParams>();
  return (
    <>
      <div className="row" key={1}>
        <div className="col-12">
          <div className="card mt-4">
            <div className="card-body card-padding py-4">
              <div className="row">
                <div className="col-lg-6 pb-3 mt-md-3">
                  <h4 className="text-primary">Deployment Dashboard</h4>
                  <p className="h6 font-weight-normal line-height-26">
                    Keep track of the total amount of customers as you will get billed for the peak of customers amount during a month.
                  </p>
                </div>
                <div className="col-lg-6 mt-4 mb-5"></div>
                <div className="col-lg-6 mt-lg-4 mb-5"></div>
                {hasRole(AuthState.user!, ims_name, ['superadmin', 'admin', 'cms']) && data.cms && (
                  <div className="col-12 mb-5 p-0">
                    <RadialGraphElements ims_name={ims_name} cms={data.cms.name} />
                  </div>
                )}
                <DeploymentDashboardDatastores {...data} />

                {hasRole(AuthState.user!, ims_name, ['superadmin', 'admin']) && data.cms && <NewDatastoreService {...data.cms} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(DeploymentDashboard);
