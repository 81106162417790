import { IAddNewClient } from '../../interfaces/forms/clients/iadd_new_file';
import { IPublishQueueJobForm } from '../../interfaces/forms/clients/IPublishQueueJob';
import { IGetPublishedJobsResponse } from '../../interfaces/responses/clients/IGetPublishingResponse';
import { IBaseResponse } from '../../interfaces/responses/IBaseResponse';
import { IGetClientSubAndSuperhostnameResponse } from '../../interfaces/responses/ims/IGetClientSubAndSuperhostnameResponse';
import axios from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';

class ClientService {
  GetClientInfo = (name: string) => {
    return axios
      .post(API_ENDPOINTS.V1.Client.GET_CLIENT_INFO, { name })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  getClientSubAndSuperhostname = async (name: string): Promise<IGetClientSubAndSuperhostnameResponse> => {
    return await axios
      .get(API_ENDPOINTS.V1.Client.GET_CLIENT_SUPER_AND_SUB_HOSTNAMES, {
        params: {
          instance: name,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  addNewClient = async (data: IAddNewClient): Promise<IBaseResponse> => {
    return await axios
      .post(API_ENDPOINTS.V1.Client.ADD_NEW_CLIENT, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  getPublishedQueuedJobs = async (client_name: string): Promise<IGetPublishedJobsResponse> => {
    return await axios
      .get(API_ENDPOINTS.V1.Client.PUBLISHING.GET_PUBLISHED_JOBS, {
        params: {
          instance: client_name,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  publishQueueJob = async (data: IPublishQueueJobForm): Promise<IBaseResponse> => {
    return await axios
      .post(API_ENDPOINTS.V1.Client.PUBLISHING.GET_PUBLISHED_JOBS, data, {
        params: { instance: data.instance },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new ClientService();
