import { Button, Form, Input, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { StatusCodes } from 'http-status-codes';
import _ from 'lodash';
import { memo, useContext, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useEffectOnce } from 'react-use';
import { AuthContext } from '../../context_api/AuthContext';
import { ICountry } from '../../interfaces/responses/common/ICountriesResponse';
import common from '../../services/common';
import ValidatePhoneNumber from '../../utils/Yup/phone-validation';

export enum EnumSystemUserFormType {
  UPDATE_OR_DETAILS = 'update_or_details',
  ADD = 'add',
}

type ISystermUserFormProps = {
  onSubmit: (values: any) => void;
  type: EnumSystemUserFormType;
};

const SystemUserForm = (props: ISystermUserFormProps) => {
  const { state: AuthState } = useContext(AuthContext);

  let clients_to_list = AuthState.user?.clients.filter((x: any) => {
    if (x.roles.includes('admin') || x.roles.includes('superadmin')) return x;
    return undefined;
  });
  clients_to_list = _.compact(clients_to_list);
  const { Option } = Select;
  const [geoCountries, setGeoCountries] = useState<ICountry[]>([]);
  const [fetchingCountries, setFetchingCountries] = useState<boolean>(false);
  const getCountries = async () => {
    setFetchingCountries(true);
    let response = await common.FetchCountries();
    if (response.status_code === StatusCodes.OK) {
      setGeoCountries(response.data.countries);
    }
    setFetchingCountries(false);
  };
  const handleFinish = (values: any) => {
    props.onSubmit(values);
  };
  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {};

  useEffectOnce(() => {
    getCountries();
  });
  return (
    <Form onFinish={handleFinish} onFinishFailed={onFinishFailed}>
      <div className="row">
        <label className="col-md-3 col-form-label">Name</label>
        <FormItem
          name="name"
          className="col-md-5"
          rules={[
            {
              required: true,
              message: 'Name is required!',
            },
          ]}
        >
          <Input className="form-control bg-white font-size-14 text-default" placeholder="Name" />
        </FormItem>
      </div>
      <div className="row">
        <label className="col-md-3 col-form-label">Email</label>
        <FormItem
          className="col-md-5"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required!',
            },
            {
              type: 'email',
              message: 'Email format is incorrect!',
            },
          ]}
        >
          <Input className="form-control bg-white font-size-14 text-default" placeholder="Email" />
        </FormItem>
      </div>
      <div className="row">
        <label className="col-md-3 col-form-label">Password</label>
        <FormItem
          name="password"
          className="col-md-5"
          rules={[
            {
              required: true,
              message: 'Password is required!',
            },
            {
              pattern: new RegExp('(?=.*[A-Z])(?=.*[0-9])'),
              message: 'Password should be at least one capital and one digit.',
            },
            {
              min: 5,
              message: 'Password should be at least 5 characters long.',
            },
          ]}
        >
          <Input type="password" className="form-control bg-white font-size-14 text-default" placeholder="Password" />
        </FormItem>
      </div>
      <div className="row">
        <label className="col-md-3 col-form-label">Mobile Number</label>
        <FormItem
          name="mobile_number"
          className="col-md-5"
          rules={[
            {
              required: true,
              message: 'Mobile Number is required!',
            },
            {
              min: 5,
              message: 'Mobile Number is not in correct form',
            },
            {
              validator: async (rule, value) => {
                if (value && ValidatePhoneNumber('+' + value)) {
                  return await Promise.resolve();
                } else return await Promise.reject(Error('Mobile Number is not in correct format.'));
              },
            },
          ]}
        >
          <PhoneInput inputClass="ant-input form-control w-100 rounded  bg-white font-size-14 text-default" country={'us'} />
        </FormItem>
      </div>
      <div className="row">
        <label className="col-md-3 col-form-label">City</label>
        <FormItem
          className="col-md-5"
          name="city"
          rules={[
            {
              required: true,
              message: 'City is required!',
            },
          ]}
        >
          <Input className="form-control bg-white font-size-14 text-default" placeholder="City" />
        </FormItem>
      </div>
      <div className="row">
        <label className="col-md-3 col-form-label">State</label>
        <FormItem
          className="col-md-5"
          name="state"
          rules={[
            {
              required: true,
              message: 'State is required!',
            },
          ]}
        >
          <Input className="form-control bg-white font-size-14 text-default" placeholder="State" />
        </FormItem>
      </div>
      <div className="row">
        <label className="col-md-3 col-form-label">Country</label>
        <FormItem
          className="col-md-5"
          name="country"
          rules={[
            {
              required: true,
              message: 'Country is required!',
            },
          ]}
        >
          <Select
            loading={fetchingCountries}
            disabled={fetchingCountries}
            className="default-select bg-white"
            placeholder="Choose Country"
            style={{ width: '100%' }}
            showSearch={true}
            filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
          >
            {geoCountries &&
              geoCountries.length > 0 &&
              geoCountries.map((country) => {
                return (
                  <Select.Option value={country._id} key={country._id}>
                    {country.name}
                  </Select.Option>
                );
              })}
          </Select>
        </FormItem>
      </div>
      <div className="row">
        <label className="col-md-3 col-form-label">Postal Code</label>
        <FormItem
          className="col-md-5"
          name="pin_code"
          rules={[
            {
              required: true,
              message: 'Postal Code is required!',
            },
          ]}
        >
          <Input className="form-control bg-white font-size-14 text-default" placeholder="Postal Code" />
        </FormItem>
      </div>
      <div className="row">
        <label className="col-md-3 col-form-label">Select IMSs of user</label>
        <FormItem className="col-md-5" name="client_ids" rules={[{ required: true, message: 'Please select at least one IMS' }]}>
          <Select mode="multiple" className="" placeholder="- Select an IMS -">
            {clients_to_list &&
              clients_to_list.map((client) => (
                <>
                  <Option value={client.client_id}>{client.client_name}</Option>
                </>
              ))}
          </Select>
        </FormItem>
      </div>

      <div className="row">
        <label className="col-md-3 col-form-label">Roles</label>
        <FormItem
          className="col-md-5"
          name="roles"
          rules={[
            { required: true, message: 'Please select at least one role' },
            {
              validator: async (_, values) => {
                if (values.includes('admin') && values.length > 1) {
                  return Promise.reject(new Error('admin cannot be assigned with other roles'));
                }
              },
            },
          ]}
        >
          <Select mode="multiple" className="default-select form-control" placeholder="- Select Roles -">
            {['admin', 'cms', 'crm', 'uit', 'iq'].map((x, index) => (
              <Option value={x} key={index}>
                {x}
              </Option>
            ))}
          </Select>
        </FormItem>
      </div>

      <div className="row">
        <div className="col-6">
          <FormItem style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" className="rounded-pill width-240 height-54">
              <span className="px-5 font-weight-bold">Add User</span>
            </Button>
          </FormItem>
        </div>
      </div>
    </Form>
  );
};
export default memo(SystemUserForm);
