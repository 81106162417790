import { Button, Checkbox, notification } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { StatusCodes } from 'http-status-codes';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import {
  AppsPublishingSVG,
  ChannelPublishingSVG,
  ContentPublishingSVG,
  MoviesPublishingSVG,
  MusicPublishingSVG,
  ProductsPublishingSVG,
  SeriesPublishingSVG
} from '../../../../../assets/ims_images';
import { DrawerContext } from '../../../../../context_api/DrawerContext';
import { IDatastore } from '../../../../../interfaces/responses/clients/IClientInfoResponse';
import { IGetPublishedJobDetails } from '../../../../../interfaces/responses/clients/IGetPublishingResponse';
import { IMSNameParams } from '../../../../../pages/ims';
import client from '../../../../../services/client';
import { CustomNotification } from '../../../Notification';
const options = [
  { label: 'Products', value: 'products' },
  { label: 'Channels', value: 'channels' },
  { label: 'Movies', value: 'movies' },
  { label: 'Series', value: 'series' },
  { label: 'Music', value: 'music' },
  { label: 'Apps', value: 'apps' },
  { label: 'EPG', value: 'epg' },
  { label: 'Cams', value: 'cams' },
  { label: 'podcasts', value: 'podcasts' },
];
interface IPublishingDatastoreContentPackage extends IGetPublishedJobDetails {}
interface IPublishedCMSDatastore extends IDatastore {
  content_packages: IPublishingDatastoreContentPackage[];
}
interface IPublishedCRMDatastore extends IDatastore {
  job_details?: IGetPublishedJobDetails;
}

interface ICRMContentChangeDetails {
  crm_id: string;
  cms_id?: string;
  job: IPublishingDatastoreContentPackage;
}

const HeaderContentPublishing = () => {
  const { ims_name } = useParams<IMSNameParams>();
  const { state: DrawerState } = useContext(DrawerContext);
  const [datastores, setDatastores] = useState<{ cms: IPublishedCMSDatastore; crms: IPublishedCRMDatastore[] }[]>([]);
  const [wholeCMSWithCRM, setWholeCMSWithCRM] = useState<
    { cms_id: string; cms_job: IGetPublishedJobDetails; crm_job: IGetPublishedJobDetails; epg_import_job: IGetPublishedJobDetails; clear_import_job: IGetPublishedJobDetails }[]
  >([]);
  const [crmContentChangeState, setCrmContentChangeState] = useState<ICRMContentChangeDetails[]>([]);
  const [wholeCRMContentChangeState, setWholeCrmContentChangeState] = useState<ICRMContentChangeDetails[]>([]);

  const getQueuedJobs = async () => {
    let response = await client.getPublishedQueuedJobs(ims_name);
    if (response?.status_code === StatusCodes.OK) {
      let _crmContentChangeState = [...crmContentChangeState];
      setDatastores((prevState) => {
        let newState = [...prevState];
        response.data.map((responseJob, i) => {
          newState = newState.map((datastore) => {
            let content_packages = datastore.cms.content_packages.map((x) => {
              let obj = x;
              if (typeof responseJob.cms_id !== 'string' && responseJob.cms_id?._id === x.cms_id && x.item_type === responseJob.item_type)
                obj = { ...responseJob, is_disabled: (i > 1 && responseJob.status === 'queued') || responseJob.status === 'in_progress' ? false : true };
              return obj;
            });
            datastore.cms.content_packages = content_packages;
            let crms = datastore.crms.map((crmDatastore) => {
              let _crmDatastore = crmDatastore;
              if (
                responseJob.cms_id &&
                typeof responseJob.cms_id !== 'string' &&
                responseJob.cms_id._id === datastore.cms._id &&
                responseJob.crm_id &&
                typeof responseJob.crm_id !== 'string' &&
                responseJob.crm_id._id === crmDatastore._id
              ) {
                _crmContentChangeState.forEach((x) => {
                  if (x.crm_id === crmDatastore._id && x.cms_id === datastore.cms._id) x.job.sub_item_type = responseJob.sub_item_type;
                });
                _crmDatastore.job_details = responseJob;
              }
              return _crmDatastore;
            });
            datastore.crms = crms;
            return datastore;
          });
        });
        return [...newState];
      });
      let wholeCMSWithCRMJobs = response.data.filter((job) => {
        if (job.item_type === 'whole_crm' || job.item_type === 'whole_cms' || job.item_type == 'import_epg' || job.item_type === 'clear_epg') return job;
      });

      let _wholeCRMContentChangeState = [...wholeCRMContentChangeState];
      setWholeCMSWithCRM((prevState) => {
        let allCMSJobs = wholeCMSWithCRMJobs.filter((x) => x.item_type === 'whole_cms');
        let wholeCMSWithCRM = _.compact(
          allCMSJobs.map((cmsJob) => {
            let obj: any = {};
            wholeCMSWithCRMJobs.map((job) => {
              if (
                job.item_type === 'whole_crm' &&
                cmsJob.cms_id &&
                typeof cmsJob.cms_id !== 'string' &&
                job.cms_id &&
                typeof job.cms_id !== 'string' &&
                cmsJob.cms_id._id === job.cms_id._id
              ) {
                _wholeCRMContentChangeState.forEach((x) => {
                  if (x.cms_id === (typeof job.cms_id !== 'string' ? job.cms_id?._id : job.cms_id)) {
                    x.job.sub_item_type = job.sub_item_type;
                  }
                });
                obj = {
                  cms_id: cmsJob.cms_id?._id,
                  cms_job: { ...cmsJob, is_disabled: cmsJob.status === 'in_progress' || cmsJob.status === 'queued' ? true : false },
                  crm_job: { ...job, is_disabled: job.status === 'in_progress' || job.status === 'queued' ? true : false },
                };
              }
              if (
                job.item_type === 'import_epg' &&
                cmsJob.cms_id &&
                typeof cmsJob.cms_id !== 'string' &&
                job.cms_id &&
                typeof job.cms_id !== 'string' &&
                cmsJob.cms_id._id === job.cms_id._id
              )
                obj.epg_import_job = job;

              if (
                job.item_type === 'clear_epg' &&
                cmsJob.cms_id &&
                typeof cmsJob.cms_id !== 'string' &&
                job.cms_id &&
                typeof job.cms_id !== 'string' &&
                cmsJob.cms_id._id === job.cms_id._id
              )
                obj.clear_import_job = job;
            });
            console.log(obj);
            return obj;
          })
        );
        return [...wholeCMSWithCRM];
      });
      setCrmContentChangeState(_crmContentChangeState);
      setWholeCrmContentChangeState(_wholeCRMContentChangeState);
    }
  };
  const findIcon = (type: string) => {
    switch (type) {
      case 'channels':
        return ChannelPublishingSVG;
      case 'movies':
        return MoviesPublishingSVG;
      case 'series':
        return SeriesPublishingSVG;
      case 'cams':
        return MusicPublishingSVG;
      case 'podcasts':
        return ProductsPublishingSVG;
      case 'music':
        return MusicPublishingSVG;
      case 'apps':
        return AppsPublishingSVG;
      case 'epg':
        return ContentPublishingSVG;
    }
  };

  const findCRMSubValues = (crmId: string) => {
    let foundSubItemValues = crmContentChangeState.find((x) => x.crm_id === crmId)?.job.sub_item_type;
    return foundSubItemValues ?? [];
  };
  const handleWholeCMSPublish = async (cmsDoc: IPublishedCMSDatastore) => {
    let response = await client.publishQueueJob({
      item_type: 'whole_cms',
      cms_id: cmsDoc._id,
      instance: ims_name,
      status: 'queued',
    });
    if (response?.status_code === StatusCodes.OK)
      notification.success({
        message: 'Success',
        description: 'Job published successfully!',
      });
    else
      CustomNotification({
        error: response.errors,
      });
  };
  const handleCMSContentClick = async (cmsDoc: IPublishedCMSDatastore, cmsJobDetails?: IPublishingDatastoreContentPackage) => {
    let response = await client.publishQueueJob({
      item_type: cmsJobDetails?.item_type ?? '',
      cms_id: cmsDoc._id,
      instance: ims_name,
      status: 'queued',
    });
    if (response?.status_code === StatusCodes.OK)
      notification.success({
        message: 'Success',
        description: 'Job published successfully!',
      });
    else
      CustomNotification({
        error: response.errors,
      });
  };

  const handleEPGContentClick = async (cmsDoc: IPublishedCMSDatastore, type: string) => {
    let response = await client.publishQueueJob({
      item_type: type,
      cms_id: cmsDoc._id,
      instance: ims_name,
      status: 'queued',
    });
    if (response?.status_code === StatusCodes.OK)
      notification.success({
        message: 'Success',
        description: 'Job published successfully!',
      });
    else
      CustomNotification({
        error: response.errors,
      });
  };
  const handleCRMPublish = async (crmDoc: IPublishedCRMDatastore, cmsDoc: IPublishedCMSDatastore, crmJobDetails?: IPublishingDatastoreContentPackage) => {
    let response = await client.publishQueueJob({
      item_type: 'products',
      sub_item_type: findCRMSubValues(crmDoc._id),
      cms_id: cmsDoc._id,
      crm_id: crmDoc._id,
      instance: ims_name,
      status: 'queued',
    });
    if (response?.status_code === StatusCodes.OK)
      notification.success({
        message: 'Success',
        description: 'Job published successfully!',
      });
    else
      CustomNotification({
        error: response.errors,
      });
  };

  const onWholeCRMCheckboxChange = async (checkedValues: CheckboxValueType[], cmsDoc: IPublishedCMSDatastore) => {
    setWholeCrmContentChangeState((prevState) => {
      let foundDoc = prevState.find((x) => x?.cms_id === cmsDoc._id);
      if (foundDoc)
        prevState.forEach((x) => {
          if (x?.cms_id === foundDoc?.crm_id) x.job.sub_item_type = checkedValues.map((x) => x.toString());
        });
      else
        prevState.push({
          cms_id: cmsDoc._id,
          crm_id: '',
          job: {
            item_type: 'whole_crm',
            sub_item_type: checkedValues.map((x) => x.toString()),
          },
        });

      return [...prevState];
    });
  };
  const handleWholeCRMPublish = async (cmsDoc: IPublishedCMSDatastore) => {
    let response = await client.publishQueueJob({
      item_type: 'whole_crm',
      cms_id: cmsDoc._id,
      instance: ims_name,
      sub_item_type: wholeCRMContentChangeState.find((x) => x.cms_id === cmsDoc._id)?.job?.sub_item_type || [],
      status: 'queued',
    });
    if (response?.status_code === StatusCodes.OK)
      notification.success({
        message: 'Success',
        description: 'Job published successfully!',
      });
    else
      CustomNotification({
        error: response.errors,
      });
  };

  const onChange = async (checkedValues: any, crmDoc: IPublishedCRMDatastore, cmsDoc: IPublishedCRMDatastore) => {
    setCrmContentChangeState((prevState) => {
      let foundDoc = prevState.find((x) => x.crm_id === crmDoc._id);
      if (foundDoc)
        prevState.forEach((x) => {
          if (x.crm_id === foundDoc?.crm_id) x.job.sub_item_type = checkedValues;
        });
      else
        prevState.push({
          crm_id: crmDoc._id,
          job: {
            item_type: 'whole_crm',
            cms_id: cmsDoc._id,
            crm_id: crmDoc._id,
            sub_item_type: checkedValues,
          },
        });

      return [...prevState];
    });
  };

  useEffectOnce(() => {
    setInterval(() => {
      getQueuedJobs();
    }, 5000);
  });
  useEffect(() => {
    if (DrawerState.client_info)
      setDatastores((prevState) => {
        return _.compact(
          DrawerState.client_info?.datastore.map((datastore) => {
            if (datastore.type === 'cms')
              return {
                cms: {
                  ...datastore,
                  content_packages: options.map((x) => {
                    let obj: IPublishingDatastoreContentPackage = {
                      cms_id: datastore._id,
                      item_type: x.value,
                    };
                    return obj;
                  }),
                },
                crms: DrawerState.client_info!.datastore.filter((x) => {
                  if (x.type === 'crm' && x.cms_ref?.toString() === datastore._id.toString()) return x;
                }),
              };
          })
        );
      });
  }, [DrawerState.client_info]);

  const packageDoc = (doc: IPublishedCMSDatastore) => {
    {
      for (let i = 0; i < doc.content_packages.length / 2; i++)
        return (
          <div className="row mb-4" key={i.toString()}>
            {_.compact(
              doc.content_packages.map(
                (cp, i) =>
                  cp.item_type !== 'products' && (
                    <div
                      className="col-md-6 mb-3"
                      key={i + Math.random()}
                      onClick={() => {
                        if (!cp.is_disabled) handleCMSContentClick(doc, cp);
                      }}
                    >
                      <div className="card card-shadow-hover">
                        <div className="card-body border text-center px-2">
                          <img
                            src={findIcon(cp.item_type.toLowerCase())}
                            title={cp.item_type ? cp.item_type.charAt(0).toUpperCase() + cp.item_type.slice(1) : ''}
                            alt={cp.item_type}
                            className="img-fluid mx-auto mb-2"
                            width="40"
                          />
                          <div className="line-height-16">
                            <small>Publish {cp.item_type ? cp.item_type.charAt(0).toUpperCase() + cp.item_type.slice(1) : ''}</small>
                            <br></br>
                            <small>{cp.status ? cp.status.charAt(0).toUpperCase() + cp.status.slice(1) : ''}</small>
                            <br></br>
                            {cp.status === 'queued' && <small>{moment(cp.queue_time).format('lll')}</small>}
                            {cp.status === 'in_progress' && <small>{moment(cp.in_progress_time).format('lll')}</small>}
                            {cp.status === 'completed' && <small>{moment(cp.last_finish_time).format('lll')}</small>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )
            )}
          </div>
        );
    }
  };

  return (
    <>
      {datastores.map((doc, index) => {
        let foundWholeCmsCrmDoc = wholeCMSWithCRM.find((x) => x.cms_id === doc.cms._id);

        return (
          <div key={index}>
            {doc.crms.length > 1 && (
              <div className="row">
                <div className="col-lg-5">
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body border">
                          <p className="h5">Publish All Packages For {doc.cms.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-shadow-hover">
                    <div className="card-body border text-center px-2">
                      {_.compact(
                        options.map((datastore, i) => {
                          return (
                            datastore.value !== 'products' && (
                              <div className="row" key={i}>
                                <img
                                  src={findIcon(datastore.value.toLowerCase())}
                                  title={datastore.value ? datastore.value.charAt(0).toUpperCase() + datastore.value.slice(1) : ''}
                                  alt={datastore.value}
                                  className="ml-5"
                                  width="20"
                                />
                                <small className="ml-4 p-2 mt-1">{datastore.value.charAt(0).toUpperCase() + datastore.value.slice(1)}</small>
                              </div>
                            )
                          );
                        })
                      )}
                      <hr className="solid my-4" />
                      <div className="line-height-16">
                        <Button
                          onClick={() => {
                            handleWholeCMSPublish(doc.cms);
                          }}
                          className="ant-btn btn btn-secondary rounded-pill height-16"
                        >
                          Publish
                        </Button>
                        <br></br>
                        <small>
                          {foundWholeCmsCrmDoc?.cms_job?.status ? foundWholeCmsCrmDoc?.cms_job?.status.charAt(0).toUpperCase() + foundWholeCmsCrmDoc?.cms_job?.status.slice(1) : ''}
                        </small>
                        <br></br>
                        {foundWholeCmsCrmDoc?.cms_job?.status === 'queued' && <small>{moment(foundWholeCmsCrmDoc?.cms_job?.queue_time).format('lll')}</small>}
                        {foundWholeCmsCrmDoc?.cms_job?.status === 'in_progress' && <small>{moment(foundWholeCmsCrmDoc?.cms_job?.in_progress_time).format('lll')}</small>}
                        {foundWholeCmsCrmDoc?.cms_job?.status === 'completed' && <small>{moment(foundWholeCmsCrmDoc?.cms_job?.last_finish_time).format('lll')}</small>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body border">
                          <p className="h5">Publish All Products for all CRM under {doc.cms.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-shadow-hover">
                    <div className="card-body border text-center px-2">
                      <img src={ProductsPublishingSVG} title="Products" alt="products" className="img-fluid mx-auto mb-2" width="40" />
                      <div className="line-height-16">
                        <small>{doc.cms.name}</small>
                        <br></br>
                      </div>
                      <Checkbox.Group
                        options={options}
                        defaultValue={_.compact(
                          wholeCRMContentChangeState.filter((x) => {
                            if (x.cms_id === doc.cms._id) return x;
                          })?.[0]?.job?.sub_item_type
                        )}
                        onChange={(checkedValues) => {
                          onWholeCRMCheckboxChange(checkedValues, doc.cms);
                        }}
                      />

                      <hr className="solid my-4" />
                      <div className="line-height-16">
                        <Button
                          onClick={() => {
                            handleWholeCRMPublish(doc.cms);
                          }}
                          className="ant-btn btn btn-secondary rounded-pill height-16"
                        >
                          Publish
                        </Button>
                        <br></br>
                        <small>
                          {foundWholeCmsCrmDoc?.crm_job?.status ? foundWholeCmsCrmDoc?.crm_job?.status.charAt(0).toUpperCase() + foundWholeCmsCrmDoc?.crm_job?.status.slice(1) : ''}
                        </small>
                        <br></br>
                        {foundWholeCmsCrmDoc?.crm_job?.status === 'queued' && <small>{moment(foundWholeCmsCrmDoc?.crm_job?.queue_time).format('lll')}</small>}
                        {foundWholeCmsCrmDoc?.crm_job?.status === 'in_progress' && <small>{moment(foundWholeCmsCrmDoc?.crm_job?.in_progress_time).format('lll')}</small>}
                        {foundWholeCmsCrmDoc?.crm_job?.status === 'completed' && <small>{moment(foundWholeCmsCrmDoc?.crm_job?.last_finish_time).format('lll')}</small>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row ">
              <div className="col-lg-5">
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body border">
                        <p className="h5">Publish Packages {doc.cms.name}</p>
                        <small className="line-height-16 ">Publish for Linux STB's, Roku and WebTV</small>
                      </div>
                    </div>
                  </div>
                </div>
                {packageDoc(doc.cms)}
              </div>
              <div className="col-lg-7">
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card w-100">
                      <div className="card-body border">
                        <p className="h5">Publish Products and EPG's</p>
                        <small className="line-height-16 ">Publish for IOS, TVOS, Android, FireTV, AndroidTV, Tizen and LG</small>
                      </div>
                    </div>
                  </div>
                </div>
                {doc.crms.map((crmDoc, i) => {
                  return (
                    <div className="row mb-4" key={i.toString()}>
                      <div className="col-md-12">
                        <div className="card w-100 card-shadow-hover">
                          <div className="card-body border text-center ">
                            <img src={ProductsPublishingSVG} title="Products" alt="products" className="img-fluid mx-auto mb-2" width="40" />
                            <div className="line-height-16">
                              <small>{crmDoc.name}</small>
                              <br></br>
                              <small>{crmDoc.job_details?.status ? crmDoc.job_details?.status.charAt(0).toUpperCase() + crmDoc.job_details?.status.slice(1) : ''}</small>
                              <br></br>
                              {crmDoc.job_details?.status === 'queued' && <small>{moment(crmDoc.job_details?.queue_time).format('lll')}</small>}
                              {crmDoc.job_details?.status === 'in_progress' && <small>{moment(crmDoc.job_details?.in_progress_time).format('lll')}</small>}
                              {crmDoc.job_details?.status === 'completed' && <small>{moment(crmDoc.job_details?.last_finish_time).format('lll')}</small>}
                            </div>
                            <Checkbox.Group
                              options={options}
                              onChange={(checkedValues) => {
                                onChange(checkedValues, crmDoc, doc.cms);
                              }}
                              defaultValue={_.compact(
                                crmContentChangeState.filter((x) => {
                                  if (x.cms_id === doc.cms._id && x.crm_id === crmDoc._id) return x;
                                })?.[0]?.job?.sub_item_type
                              )}
                            />
                            <Button
                              onClick={() => {
                                handleCRMPublish(crmDoc, doc.cms, crmDoc.job_details);
                              }}
                              className="ant-btn btn btn-secondary rounded-pill height-16"
                            >
                              Publish
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="card w-100 card-shadow-hover">
                  <div className="card-body border">
                    <p className="h5 my-0">Miscellaneous</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div
                className="col-lg-3 col-md-6"
                onClick={() => {
                  handleEPGContentClick(doc.cms, 'import_epg');
                }}
              >
                <div className="card card-shadow-hover">
                  <div className="card-body border text-center pb-4">
                    <img src={ContentPublishingSVG} title="Apps" alt="apps" className="img-fluid mx-auto pb-1 mb-2" width="40" />
                    <div className="line-height-16 mb-4">
                      <small>Import EPG</small>
                      <small>
                        {foundWholeCmsCrmDoc?.epg_import_job?.status
                          ? foundWholeCmsCrmDoc?.epg_import_job?.status.charAt(0).toUpperCase() + foundWholeCmsCrmDoc?.epg_import_job?.status.slice(1)
                          : ''}
                      </small>
                      <br></br>
                      {foundWholeCmsCrmDoc?.epg_import_job?.status === 'queued' && <small>{moment(foundWholeCmsCrmDoc?.epg_import_job?.queue_time).format('lll')}</small>}
                      {foundWholeCmsCrmDoc?.epg_import_job?.status === 'in_progress' && (
                        <small>{moment(foundWholeCmsCrmDoc?.epg_import_job?.in_progress_time).format('lll')}</small>
                      )}
                      {foundWholeCmsCrmDoc?.epg_import_job?.status === 'completed' && <small>{moment(foundWholeCmsCrmDoc?.epg_import_job?.last_finish_time).format('lll')}</small>}

                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6"
                onClick={() => {
                  handleEPGContentClick(doc.cms, 'clear_epg');
                }}
              >
                <div className="card card-shadow-hover">
                  <div className="card-body border text-center">
                    <img src={ContentPublishingSVG} title="EPG" alt="content" className="img-fluid mx-auto mb-2" width="40" />
                    <div className="line-height-16">
                      <small>Clear EPG</small>
                      <br></br>
                      <small>
                        {foundWholeCmsCrmDoc?.clear_import_job?.status
                          ? foundWholeCmsCrmDoc?.clear_import_job?.status.charAt(0).toUpperCase() + foundWholeCmsCrmDoc?.clear_import_job?.status.slice(1)
                          : ''}
                      </small>
                      <br></br>
                      {foundWholeCmsCrmDoc?.clear_import_job?.status === 'queued' && <small>{moment(foundWholeCmsCrmDoc?.clear_import_job?.queue_time).format('lll')}</small>}
                      {foundWholeCmsCrmDoc?.clear_import_job?.status === 'in_progress' && (
                        <small>{moment(foundWholeCmsCrmDoc?.clear_import_job?.in_progress_time).format('lll')}</small>
                      )}
                      {foundWholeCmsCrmDoc?.clear_import_job?.status === 'completed' && (
                        <small>{moment(foundWholeCmsCrmDoc?.clear_import_job?.last_finish_time).format('lll')}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="solid my-4" />
          </div>
        );
      })}
    </>
  );
};

export default HeaderContentPublishing;
