import Icon from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  notification,
  Select,
  Spin,
  Switch,
  Tooltip,
  Upload
} from "antd";
import { useForm } from "antd/lib/form/Form";
import confirm from "antd/lib/modal/confirm";
import { StatusCodes } from "http-status-codes";
import { memo, useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { SortingRule } from "react-table";
import { useEffectOnce } from "react-use";
import { DeleteSVG, UploadPNG } from "../../../../assets/ims_images";
import { CustomNotification } from "../../../../common/components/Notification";
import ReactBCTable from "../../../../common/components/ReactBCTable";
import IptvRichEditor from "../../../../common/components/RichTextEditor";
import { DrawerContext } from "../../../../context_api/DrawerContext";
import { IGetSubhostnamesListForm } from "../../../../interfaces/forms/areas/uit/hostnames/IGetSubHostnameListForm";
import { IGetHostnamesListHostname } from "../../../../interfaces/responses/areas/uit/hostnames/IGetHostnamesResponseList";
import { IListSubHostnameDoc } from "../../../../interfaces/responses/areas/uit/hostnames/IGetSubhostnameListResponse";
import { IGetSuperHostnameDetails } from "../../../../interfaces/responses/areas/uit/hostnames/IGetSuperhostnameDetailsResponse";
import { IFileUpload } from "../../../../interfaces/responses/common/IFileUploadResult";
import {
  IPaginatedAndFilterResponse,
  PaginatedAndFilterResponseDefaultValues
} from "../../../../interfaces/responses/common/IPaginatedAndFilterResponse";
import { IGetCRMListResponseDoc } from "../../../../interfaces/responses/datastore/IGetAllCRMListResponse";
import hostnameServices from "../../../../services/areas/uit/hostnames";
import common from "../../../../services/common";
import datastores from "../../../../services/datastores";
const UpdateSuperHostName = () => {
  const { ims_name, service_name, hostname_id } = useParams<any>();
  const [form] = useForm();
  const [subHostnameForm] = useForm();
  const { state: DrawerState } = useContext(DrawerContext);

  const [isSecure, setIsSecure] = useState(false);
  const [isAddingHostname, setIsAddingHostname] = useState(false);
  const [isUpdateHostname, setIsUpdateHostname] = useState(false);
  const [isFetchingSuperHostnameDetails, setIsFetchingSuperHostnameDetails] =
    useState(false);
  const [isFetchingHostnames, setIsFetchingHostnames] = useState(false);
  const [uploadedLogoResponseData, setUploadedLogoResponseData] =
    useState<IFileUpload>();
  const [
    uploadedLogoBackgroundResponseData,
    setUploadedLogoBackgroundResponseData,
  ] = useState<IFileUpload>();
  const [appIconUploadedFileResponseData, setAppIconUploadedFileResponseData] =
    useState<IFileUpload>();
  const [subHostnamesList, setSubHostnamesListData] = useState<
    IListSubHostnameDoc[]
  >([]);
  const [hostnamesList, setHostnamesListData] = useState<
    IGetHostnamesListHostname[]
  >([]);
  const [superHostnameData, setSuperHostnameData] =
    useState<IGetSuperHostnameDetails>();
  const [crmList, setCRMList] = useState<IGetCRMListResponseDoc[]>([]);

  const [tableSearchState, setTableSearchState] =
    useState<IGetSubhostnamesListForm>({
      superhostname_id: hostname_id,
      pageNumber: 1,
      pageSize: 10,
      query_params: {
        instance: ims_name,
      },
    });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(
    PaginatedAndFilterResponseDefaultValues
  );

  const getSubHostnameList = async (
    tableSearchOptions: IGetSubhostnamesListForm
  ) => {
    let response = await hostnameServices.getSubHostnamesList(
      tableSearchOptions
    );

    if (response?.status_code === StatusCodes.OK) {
      setSubHostnamesListData(response.data.data);
      setPagination(response.data);
    } else
      CustomNotification({
        error: response.errors,
      });
  };

  const fileValueFormEvent = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const handleCustomRequest = async (options: any, type: string) => {
    let response = await common.UploadClientCommonFile({
      media_file: options.file,
      dimensions: [],
      query_params: {
        instance: ims_name,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      if (type === "login_logo") {
        form.setFieldsValue({
          login_logo: response.data.name,
        });
        setUploadedLogoResponseData(response.data);
      }
      if (type === "login_background") {
        form.setFieldsValue({
          login_background: response.data.name,
        });
        setUploadedLogoBackgroundResponseData(response.data);
      }
      if (type === "app_icon") {
        form.setFieldsValue({
          app_icon: response.data.name,
        });
        setAppIconUploadedFileResponseData(response.data);
      }
      options.onSuccess(null, response.data);
    } else options.onError(() => "An error occured");
  };

  const handleSubmit = async (values: any) => {
    setIsUpdateHostname(true);
    let response = await hostnameServices.updateSuperHostname({
      ...values,
      superhostname_id: hostname_id,
      query_params: {
        instance: ims_name,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: "Success",
        description: "Super hostname updated successfully",
      });
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsUpdateHostname(false);
  };
  const onCRMSelectChange = async (selected_value: string) => {
    subHostnameForm.resetFields(["hostname"]);
    let selectedObj = crmList.filter((x) => x._id === selected_value)[0];
    setIsFetchingHostnames(true);
    let response = await hostnameServices.getHostnamesList({
      pageNumber: undefined,
      pageSize: undefined,
      query_params: {
        cms: selectedObj.cms_ref.name,
        instance: ims_name,
        uit: selectedObj.name,
      },
    });
    if (response && response.status_code === StatusCodes.OK)
      setHostnamesListData(response.data.data);
    else
      CustomNotification({
        error: response.errors,
      });
    setIsFetchingHostnames(false);
  };

  const tableCallbackFunction = useCallback(
    (
      pageNumber: number,
      pageSize: number,
      sortBy: SortingRule<{}> | undefined,
      filters: any
    ) => {
      let newState = {
        ...tableSearchState,
        sortBy,
        filters,
        pageNumber: pageNumber,
        pageSize,
      };
      setTableSearchState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    },
    []
  );

  useEffectOnce(() => {
    const getHostnameDetails = async () => {
      setIsFetchingSuperHostnameDetails(true);
      let response = await hostnameServices.getSuperHostnameDetails({
        _id: hostname_id,
        query_params: {
          uit: service_name,
          instance: ims_name,
          cms: DrawerState.parent_service_name,
        },
      });
      if (response.status_code === StatusCodes.OK && response.data) {
        setSuperHostnameData(response.data);
        form.setFieldsValue(response.data);
      } else
        CustomNotification({
          error: response.errors,
        });
      setIsFetchingSuperHostnameDetails(false);
    };
    const getAllCRMList = async () => {
      let response = await datastores.GetAllCRMList(ims_name);
      if (response.status_code === StatusCodes.OK && response.data) {
        setCRMList(response.data);
        form.setFieldsValue(response.data);
      } else
        CustomNotification({
          error: response.errors,
        });
    };
    getAllCRMList();
    getHostnameDetails();
    getSubHostnameList(tableSearchState);
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">Manage your Super Hostnames</h2>
          <p className="h6 font-weight-normal line-height-26">
            Using a Super Hostname gives you not only an easy to remember url
            for your customers, it also makes it possible to switch betweeen
            your GUI's and Products.
          </p>
        </div>
      </div>
      <Spin spinning={isFetchingSuperHostnameDetails}>
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            is_secure: false,
            selection_color: "#ff2233",
          }}
        >
          <div className="card mt-3">
            <div className="card-body card-ch-padding">
              <h4 className="text-primary">Update Super Hostname</h4>
              <div className="row mt-5">
                <label className="col-md-4 form-col-label">Is Secure</label>
                <Form.Item
                  className="col-md-5"
                  name="is_secure"
                  valuePropName="checked"
                >
                  <Switch
                    disabled
                    onChange={(checked) => {
                      setIsSecure(checked);
                    }}
                    className="ch-switch"
                  />
                </Form.Item>

                <label className="col-md-4 col-form-label">
                  Production Hostname
                </label>
                <Form.Item
                  className="col-md-5"
                  name="hostname"
                  extra={
                    <p>
                      To use this feature CNAME your domain like
                      xxx.yourcompany.com to our edgename:
                      {isSecure ? (
                        <b>securecloudmap.akamaized.net</b>
                      ) : (
                        <b> cloudmap.akamaized.net</b>
                      )}{" "}
                      Check with your hosting provider how to CNAME a subdomain
                      to a hostname. When you add a Hostname it will take ± 50
                      minutes for it to propagate accross all our CloudTV
                      regions.
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Hostname!",
                    },
                  ]}
                >
                  <Input
                    disabled
                    className="form-control bg-white font-size-14 text-default"
                    placeholder="Production Hostname"
                  />
                </Form.Item>

                <label className="col-md-4 col-form-label">Support URL</label>
                <Form.Item
                  className="col-md-5"
                  name="support_url"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Support URL!",
                    },
                  ]}
                  extra={
                    <p>
                      The url to a webpage that contains your support
                      information, this url will be shown to the customer in raw
                      format and as QR code.
                    </p>
                  }
                >
                  <Input
                    className="form-control bg-white font-size-14 text-default"
                    placeholder="Support URL"
                    type={"url"}
                    inputMode="url"
                  />
                </Form.Item>
              </div>
              <h4 className="text-primary">Login Settings</h4>
              <div className="row mt-5">
                <label className="col-md-4 col-form-label">Text</label>

                <IptvRichEditor
                  setValues={(contentState) => {
                    form.setFieldsValue({
                      login_text: contentState,
                    });
                  }}
                  reset={superHostnameData ? true : false}
                  values={superHostnameData?.login_text}
                ></IptvRichEditor>
                <Form.Item
                  name="login_text"
                  shouldUpdate={(prev, next) => prev !== next}
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (value && value !== "<p></p>\n") {
                          return await Promise.resolve();
                        } else {
                          return await Promise.reject(
                            new Error("Text Is Required !")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input hidden></Input>
                </Form.Item>

                <Form.Item
                  className="col-md-12"
                  extra="This information will be shown to your customers on the login screen, you can tell them where to get support or get account credentials"
                />
                <label className="col-md-4 col-form-label">Login Logo</label>
                <Form.Item
                  name="login_logo_file"
                  className="col-md-5"
                  valuePropName="fileList"
                  getValueFromEvent={fileValueFormEvent}
                  rules={[
                    {
                      validator: async (rule, value) => {
                        let login_logo = form.getFieldValue("login_logo");
                        if (login_logo) {
                          if (
                            typeof login_logo === "string" &&
                            login_logo !== ""
                          )
                            return await Promise.resolve();
                          else
                            return await Promise.reject(
                              new Error("Login Logo File Is Required")
                            );
                        } else {
                          return await Promise.reject(
                            new Error("Login Logo File Is Required")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Upload.Dragger
                    listType="picture"
                    className="default-upload"
                    defaultFileList={[]}
                    maxCount={1}
                    multiple={false}
                    accept=".png"
                    customRequest={(options) => {
                      handleCustomRequest(options, "login_logo");
                    }}
                    onRemove={() => {
                      setUploadedLogoBackgroundResponseData(undefined);
                      form.setFieldsValue({ login_logo: "" });
                    }}
                  >
                    <div className="media d-inline-flex align-items-center height-120">
                      <img src={UploadPNG} className="mr-3" alt="upload" />
                      <div className="media-body text-left my-3">
                        <h6 className="mt-0 text-primary">Upload your File</h6>
                        <p className="mb-0 h6 icon-gray font-weight-normal">
                          Drag files here or{" "}
                          <span className="text-secondary">Browse</span>
                        </p>
                      </div>
                    </div>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item name="login_logo" hidden className="col-md-12 pb-0">
                  <Input value={uploadedLogoResponseData?.name}></Input>
                </Form.Item>
                {superHostnameData?.login_logo &&
                  superHostnameData?.login_logo && (
                    <Form.Item
                      hidden={
                        uploadedLogoResponseData?.name &&
                        uploadedLogoResponseData?.name !== ""
                          ? true
                          : false
                      }
                      shouldUpdate={(prevValue: any, nextValue: any) => true}
                      className=""
                    >
                      <Image
                        src={superHostnameData?.login_logo}
                        width="100px"
                        height="100px"
                        preview={false}
                      />
                    </Form.Item>
                  )}
                <label className="col-md-4 col-form-label">
                  Login Background
                </label>
                <Form.Item
                  className="col-md-5"
                  name="login_background_file"
                  valuePropName="fileList"
                  getValueFromEvent={fileValueFormEvent}
                  rules={[
                    {
                      validator: async (rule, value) => {
                        let login_background =
                          form.getFieldValue("login_background");
                        if (login_background) {
                          if (
                            typeof login_background === "string" &&
                            login_background !== ""
                          )
                            return await Promise.resolve();
                          else
                            return await Promise.reject(
                              new Error("Login Background File Is Required")
                            );
                        } else {
                          return await Promise.reject(
                            new Error("Login Background File Is Required")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Upload.Dragger
                    name="login_backgrond_file"
                    listType="picture"
                    className="default-upload"
                    defaultFileList={[]}
                    maxCount={1}
                    multiple={false}
                    accept=".png"
                    customRequest={(options) => {
                      handleCustomRequest(options, "login_background");
                    }}
                    onRemove={() => {
                      setUploadedLogoBackgroundResponseData(undefined);
                      form.setFieldsValue({ login_background: "" });
                    }}
                  >
                    <div className="media d-inline-flex align-items-center height-120">
                      <img src={UploadPNG} className="mr-3" alt="upload" />
                      <div className="media-body text-left my-3">
                        <h6 className="mt-0 text-primary">Upload your File</h6>
                        <p className="mb-0 h6 icon-gray font-weight-normal">
                          Drag files here or{" "}
                          <span className="text-secondary">Browse</span>
                        </p>
                      </div>
                    </div>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item
                  name="login_background"
                  hidden
                  className="col-md-12 pb-0"
                >
                  <Input
                    value={uploadedLogoBackgroundResponseData?.name}
                  ></Input>
                </Form.Item>
                {superHostnameData?.login_background &&
                  superHostnameData?.login_background && (
                    <Form.Item
                      hidden={
                        uploadedLogoBackgroundResponseData?.name &&
                        uploadedLogoBackgroundResponseData?.name !== ""
                          ? true
                          : false
                      }
                      shouldUpdate={(prevValue: any, nextValue: any) => true}
                      className=""
                    >
                      <Image
                        src={superHostnameData?.login_background}
                        width="100px"
                        height="100px"
                        preview={false}
                      />
                    </Form.Item>
                  )}
                <label className="col-md-4 col-form-label">
                  Selection Color
                </label>
                <Form.Item
                  className="col-md-5"
                  name="selection_color"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Selection Color!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: 0,
                      height: 40,
                      width: 82,
                    }}
                    type={"color"}
                    className="form-control bg-white "
                  />
                </Form.Item>
              </div>
              <h4 className="text-primary">Progressive Web App</h4>
              <div className="row mt-5">
                <label className="col-md-4 col-form-label">App Name</label>
                <Form.Item
                  extra="This is required for Progressive Web App"
                  className="col-md-5"
                  name="app_name"
                  shouldUpdate={(prev, next) => prev !== next}
                  rules={[
                    {
                      warningOnly: true,
                      message:
                        "App name will be requried for Progressive web app ",
                    },
                  ]}
                >
                  <Input
                    className="form-control bg-white font-size-14 text-default"
                    placeholder="App Name"
                  />
                </Form.Item>

                <label className="col-md-4 col-form-label">App Icon</label>
                <Form.Item
                  extra="This is required for Progressive Web App"
                  name="app_icon_file"
                  valuePropName="fileList"
                  className="col-md-5"
                  getValueFromEvent={fileValueFormEvent}
                >
                  <Upload.Dragger
                    listType="picture"
                    className="default-upload"
                    defaultFileList={[]}
                    maxCount={1}
                    multiple={false}
                    accept=".png"
                    customRequest={(options) => {
                      handleCustomRequest(options, "app_icon");
                    }}
                    onRemove={() => {
                      setAppIconUploadedFileResponseData(undefined);
                      form.setFieldsValue({ app_icon: "" });
                    }}
                  >
                    <div className="media d-inline-flex align-items-center height-120">
                      <img src={UploadPNG} className="mr-3" alt="upload" />
                      <div className="media-body text-left my-3">
                        <h6 className="mt-0 text-primary">Upload your File</h6>
                        <p className="mb-0 h6 icon-gray font-weight-normal">
                          Drag files here or{" "}
                          <span className="text-secondary">Browse</span>
                        </p>
                      </div>
                    </div>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item name="app_icon" hidden className="col-md-12 pb-0">
                  <Input value={appIconUploadedFileResponseData?.name}></Input>
                </Form.Item>
                {superHostnameData?.app_icon && superHostnameData?.app_icon && (
                  <Form.Item
                    hidden={
                      appIconUploadedFileResponseData?.name &&
                      appIconUploadedFileResponseData?.name !== ""
                        ? true
                        : false
                    }
                    shouldUpdate={(prevValue: any, nextValue: any) => true}
                    className=""
                  >
                    <Image
                      src={superHostnameData?.app_icon}
                      width="100px"
                      height="100px"
                      preview={false}
                    />
                  </Form.Item>
                )}
              </div>
            </div>
          </div>

          <div className="text-right">
            <Button
              htmlType="submit"
              loading={isUpdateHostname}
              disabled={isUpdateHostname}
              className="mt-4 ant-btn btn btn-secondary rounded-pill width-160 height-54"
            >
              Update Hostname
            </Button>
          </div>
        </Form>
      </Spin>
      <Form
        form={subHostnameForm}
        name="add_sub_domain"
        onFinish={async (values) => {
          setIsAddingHostname(true);
          let result = await hostnameServices.addSubHostname({
            ...values,
            superhostname_id: hostname_id,
            query_params: {
              instance: ims_name,
            },
          });
          if (result && result.status_code === StatusCodes.OK) {
            notification.success({
              message: "Success",
              description: `Sub hostname added successfully`,
            });
            subHostnameForm.resetFields();
            getSubHostnameList(tableSearchState);
          } else
            CustomNotification({
              error: result.errors,
            });

          setIsAddingHostname(false);
        }}
        onFinishFailed={() => ""}
      >
        <div className="card mt-3">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary">Add Sub Hostname</h4>
            <div className="row mt-5">
              <label className="col-md-2 col-form-label">Select CRM</label>
              <Form.Item
                className="col-md-4"
                name="crm"
                rules={[
                  {
                    required: true,
                    message: "Please Select Any CRM!",
                  },
                ]}
              >
                <Select
                  className="default-select form-control bg-white font-size-14 text-default"
                  onChange={onCRMSelectChange}
                  placeholder="- Select -"
                >
                  {crmList.map((_crm) => (
                    <Select.Option key={_crm._id} value={_crm._id}>
                      {_crm.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <label className="col-md-2 col-form-label">Select Hostname</label>
              <Form.Item
                className="col-md-4"
                name="sub_domain"
                rules={[
                  {
                    required: true,
                    message: "Please Select Any Hostname!",
                  },
                ]}
              >
                <Select
                  loading={isFetchingHostnames}
                  disabled={isFetchingHostnames || hostnamesList.length <= 0}
                  className="default-select form-control bg-white font-size-14 text-default"
                  placeholder="- Select -"
                >
                  {hostnamesList.map((pholder) => (
                    <Select.Option key={pholder._id} value={pholder._id}>
                      {pholder.hostname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="text-right">
            <Button
              htmlType="submit"
              loading={isAddingHostname}
              disabled={isAddingHostname}
              className="m-3 ant-btn btn btn-secondary rounded-pill width-160 height-54 float-right"
            >
              Add Sub Hostname
            </Button>
          </div>
        </div>
      </Form>
      <div className="mt-3">
        <ReactBCTable
          isLoading={isFetchingSuperHostnameDetails}
          totalRecords={pagination.count}
          pageSize={pagination.pageSize}
          tableId={"super_hostnames_table"}
          columns={[
            {
              title: "CRM",
              dataKey: "crm_name",
              filter: "text",
              filterOption: "eq",
              hasSort: true,
            },
            {
              title: "Sub Domain",
              dataKey: "sub_domain",
              filter: "text",
              filterOption: "eq",
              hasSort: true,
            },
            {
              title: "Unique code",
              dataKey: "unique_code",
              filter: "text",
              filterOption: "eq",
              hasSort: true,
            },
            {
              title: "Actions",
              dataKey: "actions",
              cell: (row) => {
                return (
                  <span className="justify-content-center">
                    <Tooltip placement="top" title={`Delete`}>
                      <Icon
                        className="mx-2 cursor-pointer"
                        onClick={() => {
                          return confirm({
                            title: `Are you sure to delete ${row.sub_domain} Hostname ?`,
                            okText: "Yes",
                            okType: "danger",
                            cancelText: "No",
                            async onOk() {},
                            onCancel() {
                              console.log("Cancel");
                            },
                          });
                        }}
                        component={() => (
                          <img
                            alt="delete"
                            className="action-icon"
                            src={DeleteSVG}
                          />
                        )}
                      />
                    </Tooltip>
                  </span>
                );
              },
            },
          ]}
          data={subHostnamesList}
          onChange={tableCallbackFunction}
        />
      </div>
    </div>
  );
};

export default memo(UpdateSuperHostName);
