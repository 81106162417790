import { memo, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../..';
import { AuthContext } from '../../../../context_api/AuthContext';
import { DrawerContext } from '../../../../context_api/DrawerContext';
import { hasRole } from '../../../../utils/Auth/role.auth';
import getDatastoresMapping, { ICMSInfo, IDashboardDatastores } from '../../../../utils/DatastoresMapping';
import { CDNTrafficGraph, DeploymentDashboard, NewDeployment } from './components';

const IMSHome = () => {
  const { state: DrawerState } = useContext(DrawerContext);
  const [datastoreInfo, setDatastoreInfo] = useState<ICMSInfo[]>();
  const { state: AuthState } = useContext(AuthContext);
  const { ims_name } = useParams<IMSNameParams>();
  useEffect(() => {
    if (DrawerState.client_info) {
      let datastoresInfo: IDashboardDatastores = getDatastoresMapping(DrawerState.client_info.datastore);
      setDatastoreInfo(datastoresInfo.cms_info);
    }
  }, [DrawerState.client_info]);
  return (
    <div className="p-4 my-2">
      {hasRole(AuthState.user!, ims_name, ['superadmin', 'admin']) && (
        <>
          <NewDeployment />
          <CDNTrafficGraph />
        </>
      )}
      {datastoreInfo &&
        datastoreInfo.map((value, index) => {
          return <DeploymentDashboard {...value} key={index} />;
        })}
    </div>
  );
};

export default memo(IMSHome);
