import { Button, Form, Modal, Select } from "antd";
import { StatusCodes } from "http-status-codes";
import React, { memo, useState } from "react";
import { useEffectOnce } from "react-use";
import { IDatastore } from "../../../../../../../interfaces/responses/clients/IClientInfoResponse";
import { IAuthorizedUsersOfDatastoreResponseUser } from "../../../../../../../interfaces/responses/user/IGetAuthorizedUsersOfDatastore";
import userService from "../../../../../../../services/user";
const { Option } = Select;

type IRevokeDatastoreAccessForUserModelProps = {
  visible: boolean;
  onSubmit: (v: any) => void;
  onCancel: () => void;
  rowRecord: IDatastore;
  client_id: string;
};

const RevokeDatastoreAccessForUserModel = (
  props: IRevokeDatastoreAccessForUserModelProps
) => {
  const { visible, onCancel, rowRecord } = props;
  const [usersList, setUsersList] = useState<
    Array<IAuthorizedUsersOfDatastoreResponseUser>
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleFinish = (values: any) => {
    props.onSubmit(values);
  };
  const callMethod = async () => {
    setIsLoading(true);
    let response = await userService.GetAuthorizedUsersListForDatastores({
      datastore_id: rowRecord._id,
    });
    if (response.status_code === StatusCodes.OK) {
      setUsersList(() => response.data.users_list);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    (async () => await callMethod())();
  });
  return (
    <Modal
      visible={visible}
      width={640}
      footer={false}
      closable={true}
      onCancel={onCancel}
    >
      <div className="p-5 mx-4">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="mb-4">Delete a user </h4>
            <Form
              onFinish={handleFinish}
              onFinishFailed={({ values, errorFields, outOfDate }: any) => {}}
            >
              <label className="col-form-label">
                Select a user that will have not have access to this datastore
              </label>
              <Form.Item
                className="form-group pb-0"
                name="user_id"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "Please Select User!",
                  },
                ]}
              >
                <Select
                  loading={isLoading}
                  disabled={isLoading}
                  className="default-select bg-white"
                  placeholder="- Select a User -"
                >
                  {usersList &&
                    usersList.map((doc, index) => {
                      return (
                        <Option key={index} value={doc._id}>
                          {doc.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <div className="col-md-12 p-0">
                <Button
                  htmlType="submit"
                  className="btn btn-secondary float-right rounded-pill mt-3 width-200 height-48"
                >
                  Delete Access for User
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(RevokeDatastoreAccessForUserModel);
