import Enumerable from "linq";
import { IUserInfo } from "../../interfaces/responses/user/IUserInfoResponse";

export const hasRole = (
  user: IUserInfo,
  client: string,
  roles: string[]
): boolean => {
  if (user.clients) {
    let clients = Enumerable.from(user.clients);
    let searchedClient = clients
      .where((x) => x.client_name.toLowerCase() === client.toLowerCase())
      .firstOrDefault();
    if (searchedClient) {
      let all_roles = searchedClient.roles.map((x) => x.name);
      return roles.some((role: string) => all_roles.includes(role));
    }
    return false;
  } else {
    return false;
  }
};
