import Icon from "@ant-design/icons";
import { Table } from "antd";
import React, { memo } from "react";
const CustomExpandIcon = (props: any) => {
  if (props.expanded) {
    return (
      <span
        className="expand-row-icon cursor-pointer d-inline-block"
        onClick={(e) => props.onExpand(props.record, e)}
        style={{ color: "#1ac884" }}
      >
        <Icon
          type="caret-right"
          style={{
            transform: "rotate(45deg)",
            marginLeft: "4px",
            transformOrigin: "left center",
          }}
        />
      </span>
    );
  } else {
    return (
      <span
        className="expand-row-icon cursor-pointer d-inline-block"
        onClick={(e) => props.onExpand(props.record, e)}
        style={{ color: "#adb6c6" }}
      >
        <Icon type="caret-right" />
      </span>
    );
  }
};
const UserLogs = () => {
  const dataSource = [
    {
      key: "1",
      type: "CMS",
      subType: "Channel",
      action: "Add Groups for Channel",
      dbServiceGroup: "Dutch",
      userName: "hiren.albiorix@gmail.com",
      date: "21/01/2020",
      description:
        "Channel ID = 1816 created with name Hiren TestChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 59 with name = UK ChannelsChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 61 with name = TestGroup_2003Channel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 62 with name = EPG TIME TEST",
    },
    {
      key: "2",
      type: "CMS",
      subType: "Channel",
      action: "Add Packages for Channel",
      dbServiceGroup: "Dutch",
      userName: "hiren.albiorix@gmail.com",
      date: "21/01/2020",
      description:
        "Channel ID = 1816 created with name Hiren TestChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 59 with name = UK ChannelsChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 61 with name = TestGroup_2003Channel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 62 with name = EPG TIME TEST",
    },
    {
      key: "3",
      type: "CMS",
      subType: "Channel",
      action: "Add Channel",
      dbServiceGroup: "Dutch",
      userName: "hiren.albiorix@gmail.com",
      date: "21/01/2020",
      description:
        "Channel ID = 1816 created with name Hiren TestChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 59 with name = UK ChannelsChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 61 with name = TestGroup_2003Channel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 62 with name = EPG TIME TEST",
    },
    {
      key: "4",
      type: "CMS",
      subType: "Channel",
      action: "Add Packages for Channel",
      dbServiceGroup: "Dutch",
      userName: "hiren.albiorix@gmail.com",
      date: "21/01/2020",
      description:
        "Channel ID = 1816 created with name Hiren TestChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 59 with name = UK ChannelsChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 61 with name = TestGroup_2003Channel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 62 with name = EPG TIME TEST",
    },
    {
      key: "5",
      type: "CMS",
      subType: "Channel",
      action: "Update Channel Details",
      dbServiceGroup: "Content_Edo",
      userName: "eduard.princen@gmail.com",
      date: "21/01/2020",
      description:
        "Channel ID = 1816 created with name Hiren TestChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 59 with name = UK ChannelsChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 61 with name = TestGroup_2003Channel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 62 with name = EPG TIME TEST",
    },
    {
      key: "6",
      type: "CRM",
      subType: "Customer",
      action: "Customer Added",
      dbServiceGroup: "Premier",
      userName: "s.kerstens@iptvmiddleware.com",
      date: "23/01/2020",
      description:
        "Channel ID = 1816 created with name Hiren TestChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 59 with name = UK ChannelsChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 61 with name = TestGroup_2003Channel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 62 with name = EPG TIME TEST",
    },
    {
      key: "7",
      type: "CRM",
      subType: "Customer",
      action: "Customer Added",
      dbServiceGroup: "Premier",
      userName: "s.kerstens@iptvmiddleware.com",
      date: "23/01/2020",
      description:
        "Channel ID = 1816 created with name Hiren TestChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 59 with name = UK ChannelsChannel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 61 with name = TestGroup_2003Channel Group Item Created for Channel ID = 1816 with Channel Name = Hiren Test and Group ID = 62 with name = EPG TIME TEST",
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "type1",
      key: "type1",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
    },
    {
      title: "Sub-Type",
      dataIndex: "subType",
      key: "subType",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: true,
    },
    {
      title: "DB Service Group",
      dataIndex: "dbServiceGroup",
      key: "dbServiceGroup",
      sorter: true,
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      sorter: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: true,
    },
  ];
  return (
    <div className="container-fluid wrapper p-3">
      <div className="row">
        <div className="col-md-7">
          <h2 className="page-title">UserLogs</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table
            columns={columns}
            dataSource={dataSource}
            expandIcon={CustomExpandIcon}
            expandedRowRender={(record) => (
              <p style={{ margin: 0 }}>{record.description}</p>
            )}
            pagination={{
              showTotal: (total, range) => {
                return `${range[0]}-${range[1]} of ${total} items`;
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(UserLogs);
