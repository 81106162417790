import {
  AdvertisementsHoverSVG,
  AdvertisementsSVG,
  AppsSVG,
  AppsSVGHover,
  CamHoverSVG,
  CamSVG,
  ChannelsHoverSVG,
  ChannelsSVG,
  CommunicationHoverSVG,
  CommunicationSVG,
  CustomerHoverSVG,
  CustomersSVG,
  HostnamesHover,
  HostnamesSVG,
  LanguageHoverSVG,
  LanguageSVG,
  MenuAppsHoverSVG,
  MenuAppsSVG,
  MovieHoverSVG,
  MovieSVG,
  MusicHoverSVG,
  MusicSVG,
  PodcastHoverSVG,
  PodcastSVG,
  ProductsHoverSVG,
  ProductsSVG,
  ResellersHoverSVG,
  ResellersSVG,
  SeriesHoverSVG,
  SeriesSVG,
  SettingHover,
  SettingSVG,
  TicketsHoverSVG,
  TicketsSVG,
  TranscoderHoverSVG,
  TranscoderSVG,
  UserInterfacesHoverSVG,
  UserInterfacesSVG,
  WebshopsHoverSVG,
  WebshopsSVG,
  TemplateSVG,
} from '../assets/ims_images';

export interface AreaInsideRoutes {
  default_selected_key: string;
  default_open_key: string;
  Routes: IRoute[];
}
export interface IAllRoutes {
  AREASRoutes: IRoute[];
  CMSRoutes: AreaInsideRoutes;
  CRMRoutes: AreaInsideRoutes;
  UITRoutes: AreaInsideRoutes;
  APBRoutes: AreaInsideRoutes;
  //AppBuilderRoutes: AreaInsideRoutes;
}
export interface IRoute {
  type: string;
  path?: string;
  title: string;
  is_invisible?: boolean;
  component_path?: string;
  key?: string;
  icon?: string;
  icon_hover?: string;
  auth?: boolean;
  routes?: IRoute[];
  highlitedKey?: string;
}
const AllAreaRoutes: IAllRoutes = {
  AREASRoutes: [
    {
      key: 'CMS',
      type: 'item',
      path: '/cms/:cms_name',
      component_path: '../areas/cms',
      auth: true,
      title: 'CMS',
    },
    {
      key: 'CRM',
      type: 'item',
      path: '/crm/:crm_name',
      component_path: '../areas/crm',
      auth: true,
      title: 'CRM',
    },
  ],
  CMSRoutes: {
    default_selected_key: 'all_channels',
    default_open_key: 'cms_channels',
    Routes: [
      //Channels
      {
        key: 'cms_channels',
        type: 'collapse',
        auth: true,
        title: 'Channels',
        icon: ChannelsSVG,
        icon_hover: ChannelsHoverSVG,
        routes: [
          {
            key: 'all_channels',
            type: 'item',
            path: 'channels',
            title: 'Channels',
            component_path: './cms/channels',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_channels',
            key: 'cms_channels_add',
            type: 'item',
            path: 'channels/add-channel',
            title: 'Add',
            component_path: './cms/channels/add_new_channel',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_channels',
            key: 'cms_channels_update',
            type: 'item',
            path: 'channels/details/:channel_id',
            title: 'Add',
            component_path: './cms/channels/channel_details',
          },

          {
            key: 'cms_channels_excel',
            type: 'item',
            path: 'channels/excel',
            title: 'Excel',
            component_path: './cms/channels/channels_excel',
          },
          {
            key: 'cms_epg_overview',
            type: 'item',
            path: 'channels/epg-overview',
            title: 'EPG Overview',
            component_path: './cms/channels/epg/epg_overview',
          },
          {
            key: 'cms_epg_url',
            type: 'item',
            path: 'channels/epg-url',
            title: 'EPG Urls',
            component_path: './cms/channels/epg/epg_url',
          },
          {
            key: 'cms_cloud_pvr',
            type: 'item',
            path: 'channels/cloud-pvr',
            title: 'Cloud PVR',
            component_path: './cms/channels/epg/cloud_pvr',
          },
          {
            key: 'cms_channel_groups',
            type: 'item',
            path: 'channels/groups',
            title: 'Groups',
            component_path: './cms/channels/groups',
          },
          {
            key: 'cms_channel_package',
            type: 'item',
            path: 'channels/packages',
            title: 'Packages',
            component_path: './cms/channels/packages',
          },
        ],
      },
      //Cams
      {
        key: 'cams',
        type: 'collapse',
        auth: true,
        title: 'Cams',
        icon: CamSVG,
        icon_hover: CamHoverSVG,
        routes: [
          {
            key: 'all_cams',
            type: 'item',
            path: 'cams',
            title: 'All Cams',
            component_path: './cms/cams',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_cams',
            key: 'add_cam',
            type: 'item',
            path: 'cams/add-cam',
            title: 'Add Cams',
            component_path: './cms/cams/add_cam',
          },
          {
            highlitedKey: 'all_cams',

            key: 'cam_groups',
            type: 'item',
            path: 'cams/groups',
            title: 'Groups',
            component_path: './cms/cams/groups',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_cams',
            key: 'cam_details',
            type: 'item',
            path: 'cams/details/:cam_id',
            title: 'Update Cams',
            component_path: './cms/cams/details',
          },
          {
            highlitedKey: 'all_cams',

            key: 'cam_package',
            type: 'item',
            path: 'cams/packages',
            title: 'Packages',
            component_path: './cms/cams/packages',
          },
        ],
      },
      //Movies
      {
        key: 'cms_movies',
        type: 'collapse',
        auth: true,
        title: 'Movies',
        icon: MovieSVG,
        icon_hover: MovieHoverSVG,
        routes: [
          {
            key: 'all_movies',
            type: 'item',
            path: 'movies',
            title: 'Movies',
            component_path: './cms/movies',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_movies',
            key: 'add_movie',
            type: 'item',
            path: 'movies/add-movie',
            title: '',
            component_path: './cms/movies/add_new_movie',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_movies',
            key: 'detail_movie',
            type: 'item',
            path: 'movies/movie-details/:movie_id',
            title: '',
            component_path: './cms/movies/update_movie',
          },
          {
            key: 'movie_package',
            type: 'item',
            path: 'movies/packages',
            title: 'Packages',
            component_path: './cms/movies/packages',
          },

          {
            is_invisible: true,
            highlitedKey: 'movie_package',
            key: 'add_sub_movie_package',
            type: 'item',
            path: 'movies/add_package/:parent_package',
            title: '',
            component_path: './cms/movies/packages/add_package',
          },
          {
            is_invisible: true,
            highlitedKey: 'movie_package',
            key: 'add_movie_package',
            type: 'item',
            path: 'movies/add_package',
            title: '',
            component_path: './cms/movies/packages/add_package',
          },
        ],
      },
      //Music
      {
        key: 'cms_music',
        type: 'collapse',
        auth: true,
        title: 'Music',
        icon: MusicSVG,
        icon_hover: MusicHoverSVG,
        routes: [
          {
            key: 'music_album',
            type: 'item',
            path: 'music/album',
            title: 'Albums',
            component_path: './cms/music/album',
          },
          {
            is_invisible: true,
            highlitedKey: 'music_album',
            key: 'cms_music_album_add',
            type: 'item',
            path: 'music/album/add',
            title: 'Add Music Album',
            component_path: './cms/music/album/add_new_album',
          },
          {
            is_invisible: true,
            highlitedKey: 'music_album',
            key: 'cms_music_album_details',
            type: 'item',
            path: 'music/album/details/:music_album_id',
            title: 'Album Details',
            component_path: './cms/music/album/album_details',
          },

          {
            key: 'cms_music_packages',
            type: 'item',
            path: 'music/packages',
            title: 'Packages',
            component_path: './cms/music/packages',
          },
        ],
      },
      //Podcast
      {
        key: 'podcasts',
        type: 'collapse',
        auth: true,
        title: 'Podcast',
        icon: PodcastSVG,
        icon_hover: PodcastHoverSVG,
        routes: [
          {
            key: 'all_podcasts',
            type: 'item',
            path: 'podcast',
            title: 'Podcasts',
            component_path: './cms/podcasts/podcast',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_podcasts',
            key: 'podcast_add',
            type: 'item',
            path: 'podcast/add',
            title: 'Add Podcast',
            component_path: './cms/podcasts/podcast/add_new_podcast',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_podcasts',
            key: 'podcast_details',
            type: 'item',
            path: 'podcast/details/:podcast_id',
            title: 'Podcast Details',
            component_path: './cms/podcasts/podcast/podcast_details',
          },

          {
            key: 'podcast_packages',
            type: 'item',
            path: 'podcast/packages',
            title: 'Packages',
            component_path: './cms/podcasts/packages',
          },
        ],
      },
      //Education
      {
        key: 'cms_education',
        type: 'collapse',
        auth: true,
        title: 'Education',
        icon: ChannelsSVG,
        icon_hover: ChannelsHoverSVG,
        routes: [
          //year
          {
            key: 'cms_education_year',
            type: 'item',
            path: 'education/year',
            title: 'Year',
            component_path: './cms/education/year',
          },
          {
            key: 'cms_education_course',
            type: 'item',
            path: 'education/course',
            title: 'Course',
            component_path: './cms/education/course',
          },
          {
            is_invisible: true,
            highlitedKey: 'cms_education_course',
            key: 'cms_education_course_add',
            type: 'item',
            path: 'education/course/add',
            title: 'Course',
            component_path: './cms/education/course/add_new_course',
          },
          {
            is_invisible: true,
            highlitedKey: 'cms_education_course',
            key: 'cms_education_course_update',
            type: 'item',
            path: 'education/course/details/:course_id',
            title: 'Course',
            component_path: './cms/education/course/course_details',
          },

          //level
          {
            key: 'cms_education_level',
            type: 'item',
            path: 'education/level',
            title: 'Level',
            component_path: './cms/education/level',
          },
        ],
      },
      //Apps
      {
        key: 'cms_apps',
        type: 'collapse',
        auth: true,
        title: 'Apps',
        icon: AppsSVG,
        icon_hover: AppsSVGHover,
        routes: [
          {
            key: 'all_apps',
            type: 'item',
            path: 'apps',
            title: 'Apps',
            component_path: './cms/apps',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_apps',
            key: 'cms_apps_add',
            type: 'item',
            path: 'apps/add-new-app',
            title: 'Add New App',
            component_path: './cms/apps/add_new_app',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_apps',
            key: 'cms_app_details',
            type: 'item',
            path: 'apps/details/:app_id',
            title: 'App Details',
            component_path: './cms/apps/app_details',
          },
          {
            key: 'app_packages',
            type: 'item',
            path: 'apps/packages',
            title: 'Packages',
            component_path: './cms/apps/packages',
          },
        ],
      },
      //Series
      {
        key: 'cms_series',
        type: 'collapse',
        auth: true,
        title: 'Series',
        icon: SeriesSVG,
        icon_hover: SeriesHoverSVG,
        routes: [
          {
            key: 'all_series',
            type: 'item',
            path: 'series',
            title: 'Series',
            component_path: './cms/series',
          },
          {
            key: 'all_seasons',
            type: 'item',
            path: 'seasons',
            title: 'Seasons',
            component_path: './cms/series/season',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_seasons',
            key: 'add_season',
            type: 'item',
            path: 'seasons/add/:series_id',
            title: '',
            component_path: './cms/series/season/add_season',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_seasons',
            key: 'season_details',
            type: 'item',
            path: 'seasons/details/:season_id',
            title: '',
            component_path: './cms/series/season/season_details',
          }, //packages
          {
            key: 'series_package',
            type: 'item',
            path: 'series/packages',
            title: 'Packages',
            component_path: './cms/series/packages',
          },
          {
            is_invisible: true,
            highlitedKey: 'series_package',
            key: 'series_add_package',
            type: 'item',
            path: 'series/add_package',
            title: '',
            component_path: './cms/series/packages/add_package',
          },
        ],
      },
      //PPV
      {
        key: 'ppv_rules',
        type: 'collapse',
        title: 'PPV Rules',
        icon: SettingSVG,
        auth: true,
        icon_hover: SettingHover,
        routes: [
          {
            key: 'cms_ppv_rules',
            type: 'item',
            path: 'ppv_rules',
            title: 'PPV Rules',
            component_path: './cms/ppv_rules',
          },
        ],
      },

      //Transcoders
      {
        key: 'transcoders',
        type: 'collapse',
        auth: true,
        title: 'Transcoders',
        icon: TranscoderSVG,
        icon_hover: TranscoderHoverSVG,
        routes: [
          {
            key: 'all_transcoders',
            type: 'item',
            path: 'transcoders',
            title: 'Transcoders',
            component_path: './cms/transcoders',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_transcoders',
            key: 'transcoders_add',
            type: 'item',
            path: 'transcoders/add_transcoder',
            title: 'Add New Transcoder',
            component_path: './cms/transcoders/add_transcoder',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_transcoders',
            key: 'transcoders_details',
            type: 'item',
            path: 'transcoders/details/:transcoder_id',
            title: 'Transcoder Details',
            component_path: './cms/transcoders/details',
          },
          {
            key: 'dvr_list',
            type: 'item',
            path: 'dvr_storage',
            title: 'DVR Storage',
            component_path: './cms/transcoders/dvr_storage',
          },
          {
            key: 'cdn_hostname',
            type: 'item',
            path: 'cdn_hostname',
            title: 'CDN Hostname',
            component_path: './cms/transcoders/cdn_hostname',
          },
        ],
      },
      //Settings
      {
        key: 'cms_settings',
        type: 'collapse',
        auth: true,
        title: 'Settings',
        icon: SettingSVG,
        icon_hover: SettingHover,
        routes: [
          {
            key: 'all_cms_currencies',
            type: 'item',
            path: 'currencies',
            title: 'Currencies',
            component_path: './cms/settings/currencies',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_cms_currencies',
            key: 'cms_currency_add',
            type: 'item',
            path: 'currencies/add-new-currency',
            title: 'Add New Currency',
            component_path: './cms/settings/currencies',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_cms_currencies',
            key: 'cms_currency_details',
            type: 'item',
            path: 'currencies/details/:currency_id',
            title: 'Currency Details',
            component_path: './cms/settings/currencies',
          },
          {
            key: 'drm',
            type: 'item',
            path: 'drm/buydrm',
            title: 'DRM',
            component_path: './cms/settings/drm',
          },
          {
            key: 'irdeto_settings',
            type: 'item',
            path: 'drm/irdeto',
            title: 'Irdeto Settings',
            component_path: './cms/settings/irdeto_settings',
          },
          {
            key: 'token_settings',
            type: 'item',
            path: 'token_settings',
            title: 'Token Settings',
            component_path: './cms/settings/token_settings',
          },
        ],
      },
    ],
  },
  CRMRoutes: {
    default_selected_key: 'all_customers',
    default_open_key: 'crm_customers',
    Routes: [
      {
        key: 'crm_customers',
        type: 'collapse',
        auth: true,
        title: 'Customers',
        icon: CustomersSVG,
        icon_hover: CustomerHoverSVG,
        routes: [
          {
            key: 'all_customers',
            type: 'item',
            path: 'customers',
            title: 'Customers',
            component_path: './crm/customers',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_customers',
            key: 'crm_customers_add',
            type: 'item',
            path: 'customers/add-new-customer',
            title: 'Add customers',
            component_path: './crm/customers/add_new_customer',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_customers',
            key: 'crm_customers_update',
            type: 'item',
            path: 'customers/details/:_id',
            title: 'Update customers',
            component_path: './crm/customers/update_customer',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_customers',
            key: 'customer_ticket_responses',
            type: 'item',
            path: 'customers/ticket/details/:_id',
            title: 'Ticket Details',
            component_path: './crm/customers/ticket_details',
          },
        ],
      },
      //products
      {
        key: 'products',
        type: 'collapse',
        auth: true,
        title: 'Products',
        icon: ProductsSVG,
        icon_hover: ProductsHoverSVG,
        routes: [
          {
            key: 'all_products',
            type: 'item',
            path: 'products',
            title: 'Products',
            component_path: './crm/products',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_products',
            key: 'product_detail',
            type: 'item',
            path: 'products/details/:product_id',
            title: 'Products',
            component_path: './crm/products/product_details',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_products',
            key: 'add_product',
            type: 'item',
            path: 'products/add_product',
            title: 'Products',
            component_path: './crm/products/add_product',
          }, //Extra Packages
          {
            key: 'extra_packages',
            type: 'item',
            path: 'products/extrapackages',
            title: 'Extra Packages',
            component_path: './crm/products/extra_packages',
          },
          //DVR Plans
          {
            key: 'dvr_plans',
            type: 'item',
            path: 'products/dvrplans',
            title: 'DVR Plans',
            component_path: './crm/products/dvr_plan',
          },
          {
            is_invisible: true,
            highlitedKey: 'dvr_plans',
            key: 'details_dvr_plans',
            type: 'item',
            path: 'products/dvrplans/details',
            title: 'DVR Plan Details',
            component_path: './crm/products/dvr_plan',
          },
          {
            is_invisible: true,
            highlitedKey: 'dvr_plans',
            key: 'add_dvr_plan',
            type: 'item',
            path: 'products/dvrplans/addplan',
            title: 'Add DVR Plan',
            component_path: './crm/products/dvr_plan/add_dvr_plan',
          },
          //CATCHUP Plans
          {
            key: 'catchup_plans',
            type: 'item',
            path: 'products/cathupplans',
            title: 'Catchup Plans',
            component_path: './crm/products/catchup_plan',
          },
          {
            is_invisible: true,
            highlitedKey: 'catchup_plans',
            key: 'details_catchup_plans',
            type: 'item',
            path: 'products/cathupplans/details',
            title: 'Catchup Plan Details',
            component_path: './crm/products/catchup_plan',
          },
          {
            is_invisible: true,
            highlitedKey: 'catchup_plans',
            key: 'add_catchup_plan',
            type: 'item',
            path: 'products/cathupplans/addplan',
            title: 'Add Catchup Plan',
            component_path: './crm/products/catchup_plan/add_catchup_plan',
          },
          //IP Access Plans
          {
            key: 'ip_access',
            type: 'item',
            path: 'products/ipaccess',
            title: 'IP Ranges',
            component_path: './crm/products/ip_access',
          },
          //API Provisions
          {
            key: 'api_provisions',
            type: 'item',
            path: 'products/apiprovisions',
            title: 'API Provisions',
            component_path: './crm/products/api_provisions',
          },
          //Disclaimer
          {
            key: 'disclaimer',
            type: 'item',
            path: 'products/disclaimer',
            title: 'Disclaimer',
            component_path: './crm/products/disclaimer',
          },
          //Support page
          {
            key: 'support_page',
            type: 'item',
            path: 'products/supportpages',
            title: 'Support Page',
            component_path: './crm/products/support_page',
          },
          {
            key: 'add_support_page',
            is_invisible: true,
            highlitedKey: 'support_page',
            type: 'item',
            path: 'products/supportpages/addpage',
            title: 'Add Support Page',
            component_path: './crm/products/support_page/add_support_page',
          },
          {
            key: 'update_support_page',
            is_invisible: true,
            highlitedKey: 'support_page',
            type: 'item',
            path: 'products/supportpages/pagedetails/:_id',
            title: 'Support Page Details',
            component_path: './crm/products/support_page/update_support_page',
          },
          //App OTA
          {
            key: 'app_ota',
            type: 'item',
            path: 'products/appota',
            title: 'App OTA',
            component_path: './crm/products/app_ota',
          },
          //devices
          {
            is_invisible: true,
            highlitedKey: 'all_products',
            key: 'product_devices',
            type: 'item',
            path: 'products/devices/:_id',
            title: 'Product Devices',
            component_path: './crm/products/product_devices',
          },
        ],
      },
      //Webshops
      {
        key: 'webshops',
        type: 'collapse',
        auth: true,
        title: 'Webshops',
        icon: WebshopsSVG,
        icon_hover: WebshopsHoverSVG,
        routes: [
          {
            key: 'all_webshops',
            type: 'item',
            path: 'webshops',
            title: 'Webshops',
            component_path: './crm/webshops',
          },
          {
            is_invisible: true,
            key: 'woocommerce',
            type: 'item',
            path: 'webshops/woocommerce',
            title: 'Woocommerce',
            component_path: './crm/webshops/woocommerce',
          },
          {
            is_invisible: true,
            key: 'shopify',
            type: 'item',
            path: 'webshops/shopify',
            title: 'Shopify',
            component_path: './crm/webshops/shopify',
          },
          {
            is_invisible: true,
            key: 'prestashop',
            type: 'item',
            path: 'webshops/prestashop',
            title: 'Prestashop',
            component_path: './crm/webshops/prestashop',
          },
          {
            is_invisible: true,
            key: 'bigcommerce',
            type: 'item',
            path: 'webshops/bigcommerce',
            title: 'Bigcommerce',
            component_path: './crm/webshops/bigcommerce',
          },
          {
            is_invisible: true,
            key: 'opencart',
            type: 'item',
            path: 'webshops/opencart',
            title: 'Opencart',
            component_path: './crm/webshops/opencart',
          },
          {
            is_invisible: true,
            key: 'chargebee',
            type: 'item',
            path: 'webshops/chargebee',
            title: 'Chargebee',
            component_path: './crm/webshops/chargebee',
          },
          {
            is_invisible: true,
            key: 'update_webshop_details',
            type: 'item',
            path: 'webshops/:webshop_id/:webshop_product_id',
            title: 'Update webshop product',
            component_path: './crm/webshops/product_details',
          },
        ],
      },
      //Reseller
      {
        key: 'resellers',
        type: 'collapse',
        auth: true,
        title: 'Reseller',
        icon: ResellersSVG,
        icon_hover: ResellersHoverSVG,
        routes: [
          {
            key: 'all_resellers',
            type: 'item',
            path: 'resellers',
            title: 'Reseller',
            component_path: './crm/reseller',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_resellers',
            key: 'add_reseller',
            type: 'item',
            path: 'resellers/add_reseller',
            title: 'Resellers',
            component_path: './crm/reseller/add_reseller',
          },
          {
            is_invisible: true,
            highlitedKey: 'all_resellers',
            key: 'details_reseller',
            type: 'item',
            path: 'resellers/details/:reseller_id',
            title: 'Resellers',
            component_path: './crm/reseller/details',
          },
          //Extra packages mapping
          {
            key: 'reseller_extra_packages',
            type: 'item',
            path: 'resellers/extrapackages',
            title: 'Extra Packages Mapping',
            component_path: './crm/reseller/extra_packages_mapping',
          },
          //Domain mapping
          {
            key: 'reseller_domain_mapping',
            type: 'item',
            path: 'resellers/domainmapping',
            title: 'Domain Mapping',
            component_path: './crm/reseller/domain_mapping',
          },
        ],
      },
      //Advertisements
      {
        key: 'advertisements',
        type: 'collapse',
        auth: true,
        title: 'Advertisement',
        icon: AdvertisementsSVG,
        icon_hover: AdvertisementsHoverSVG,
        routes: [
          {
            key: 'home_banner_ads',
            type: 'item',
            path: 'home-banner-ads',
            title: 'Banner Ads',
            component_path: './crm/advertisements',
          },
          {
            is_invisible: true,
            highlitedKey: 'home_banner_ads',
            key: 'add_banner_ad',
            type: 'item',
            path: 'add-banner-ad',
            title: 'Banner Ads',
            component_path: './crm/advertisements/add_banner_ad',
          },
          {
            is_invisible: true,
            highlitedKey: 'home_banner_ads',
            key: 'update_banner_ad',
            type: 'item',
            path: 'home-banner-ads/details/:_id',
            title: 'Banner Ad Details',
            component_path: './crm/advertisements/update_banner_ad',
          },
          {
            key: 'preroll_ads',
            type: 'item',
            path: 'preroll-ads',
            title: 'PreRoll Ads',
            component_path: './crm/advertisements/preroll_ads/',
          },
          {
            is_invisible: true,
            highlitedKey: 'preroll_ads',
            key: 'update_preroll_ad',
            type: 'item',
            path: 'preroll-ads/details/:_id',
            title: 'Update PreRoll Ads',
            component_path: './crm/advertisements/preroll_ads/update_preroll_ad',
          },
          {
            is_invisible: true,
            highlitedKey: 'preroll_ads',
            key: 'add_preroll_ad',
            type: 'item',
            path: 'add-preroll-ad',
            title: 'Add PreRoll Ads',
            component_path: './crm/advertisements/preroll_ads/add_preroll_ad',
          },
          //Overlay ads
          {
            key: 'overlay_ads',
            type: 'item',
            path: 'overlay-ads',
            title: 'Overlay Ads',
            component_path: './crm/advertisements/overlay_ads',
          },
          {
            is_invisible: true,
            highlitedKey: 'overlay_ads',
            key: 'add_overlay_ad',
            type: 'item',
            path: 'add-overlay-ad',
            title: 'Add Overlay Ads',
            component_path: './crm/advertisements/overlay_ads/add_overlay_ad',
          },
          {
            is_invisible: true,
            highlitedKey: 'overlay_ads',
            key: 'update_overlay_ad',
            type: 'item',
            path: 'overlay-ads/details/:_id',
            title: 'Update Overlay Ads',
            component_path: './crm/advertisements/overlay_ads/update_overlay_ad',
          },
          //Ticker ads
          {
            key: 'ticker_ads',
            type: 'item',
            path: 'ticker-ads',
            title: 'Ticker Ads',
            component_path: './crm/advertisements/ticker_ads',
          },
          {
            is_invisible: true,
            highlitedKey: 'ticker_ads',
            key: 'add_ticker_ad',
            type: 'item',
            path: 'add-ticker-ad',
            title: 'Add Ticker Ads',
            component_path: './crm/advertisements/ticker_ads/add_ticker_ad',
          },
          {
            is_invisible: true,
            highlitedKey: 'ticker_ads',
            key: 'update_ticker_ad',
            type: 'item',
            path: 'ticker-ads/details/:_id',
            title: 'Update Ticker Ads',
            component_path: './crm/advertisements/ticker_ads/update_ticker_ad',
          },
        ],
      },
      //Communications
      {
        key: 'communications',
        type: 'collapse',
        auth: true,
        title: 'Communications',
        icon: CommunicationSVG,
        icon_hover: CommunicationHoverSVG,
        routes: [
          {
            key: 'tempate',
            type: 'item',
            path: 'communications/template',
            title: 'Templates',
            component_path: './crm/communications/templates',
          },
          {
            key: 'mass_mail_settings',
            type: 'item',
            path: 'communications/mass_mail_settings',
            title: 'Mass Mail Settings',
            component_path: './crm/communications/mass_mail_settings',
          },
          {
            key: 'mass_sms_settings',
            type: 'item',
            path: 'communications/mass_sms_settings',
            title: 'Mass SMS Settings',
            component_path: './crm/communications/mass_sms_settings',
          },
          {
            key: 'mass_sms',
            type: 'item',
            path: 'communications/mass_sms',
            title: 'Mass SMS',
            component_path: './crm/communications/mass_sms',
          },
          {
            key: 'mass_mail',
            type: 'item',
            path: 'communications/massmail',
            title: 'Mass Mail',
            component_path: './crm/communications/mass_mail',
          },
          {
            key: 'notifications',
            type: 'item',
            path: 'communications/notifications',
            title: 'Notifications',
            component_path: './crm/communications/notifications',
          },
          {
            key: 'subscriptions',
            type: 'item',
            path: 'communications/subscriptions',
            title: 'Subscriptions',
            component_path: './crm/communications/subscriptions',
          },
          {
            is_invisible: true,
            highlitedKey: 'subscriptions',
            key: 'subscriptions_details',
            type: 'item',
            path: 'communications/subscriptions/details/:_id',
            title: 'Subscriptions',
            component_path: './crm/communications/subscriptions/details',
          },
        ],
      },
      //Tickets
      {
        key: 'tickets',
        type: 'collapse',
        auth: true,
        title: 'Tickets',
        icon: TicketsSVG,
        icon_hover: TicketsHoverSVG,
        routes: [
          {
            key: 'all_tickets',
            type: 'item',
            path: 'tickets',
            title: 'Tickets',
            component_path: './crm/tickets',
          },
          {
            is_invisible: true,
            highlitedKey: 'tickets',
            key: 'response_tickets',
            type: 'item',
            path: 'tickets/details-ticket/:_id',
            title: 'Response Tickets',
            component_path: './crm/tickets/ticket_responses',
          },
        ],
      },
      //Settings
      {
        key: 'crm_settings',
        type: 'collapse',
        auth: true,
        title: 'Settings',
        icon: SettingSVG,
        icon_hover: SettingHover,
        routes: [
          {
            key: 'crm_currencies',
            type: 'item',
            path: 'currencies/list',
            title: 'Currencies',
            component_path: './crm/settings/currency',
          },
          {
            key: 'company_info',
            type: 'item',
            path: 'companyinfo',
            title: 'Company Info',
            component_path: './crm/settings/company_info',
          },
          {
            key: 'general',
            type: 'item',
            path: 'general',
            title: 'General',
            component_path: './crm/settings/general',
          },
          {
            key: 'log_translations',
            type: 'item',
            path: 'logtranslations',
            title: 'Log Translations',
            component_path: './crm/settings/log_translations',
          },
          {
            key: 'crm_rules',
            type: 'item',
            path: 'crmrules',
            title: 'CRM Rules',
            component_path: './crm/settings/crm_rules',
          },
          {
            is_invisible: true,
            highlitedKey: 'crm_rules',
            key: 'details_crm_rule',
            type: 'item',
            path: 'crmrules/:_id',
            title: 'Details CRM Rules',
            component_path: './crm/settings/crm_rules/details_crm_rule',
          },
        ],
      },
    ],
  },
  APBRoutes: {
    default_selected_key: 'applications',
    default_open_key: 'all',
    Routes: [
      {
        key: 'templates',
        type: 'collapse',
        auth: true,
        title: 'Templates',
        icon: TemplateSVG,
        icon_hover: TemplateSVG,
        routes: [

          {
            key: 'templates_app',
            type: 'item',
            path: 'templates/templates_app',
            title: 'App Templates',
            component_path: './apb/templates/templates_app',
          },
          {
            key: 'templates_auth',
            type: 'item',
            path: 'templates/templates_auth',
            title: 'Auth Templates',
            component_path: './apb/templates/templates_auth',
          },
        ]
      },
      {
        key: 'apb',
        type: 'collapse',
        auth: true,
        title: 'App Builder',
        icon: UserInterfacesSVG,
        icon_hover: UserInterfacesHoverSVG,
        routes: [
          {
            key: 'applications',
            type: 'item',
            path: 'applications',
            title: 'My Apps',
            component_path: './apb/applications',
          },
          {
            key: 'authentication',
            type: 'item',
            path: 'authentication',
            title: 'My Auths',
            component_path: './apb/authentication',
          },
    
  
          {
            is_invisible: true,
            key: 'add_header_minimal',
            type: 'item',
            highlitedKey: 'headers',
            path: 'headers/components/add/minimal',
            title: 'Add Minimal Header',
            component_path: './apb/headers/components/add/minimal',
          },


          {
            is_invisible: true,
            key: 'add_authentication',
            type: 'item',
            highlitedKey: 'applications',
            path: 'authentication/add',
            title: 'Add App',
            component_path: './apb/authentication/add',
          },
          {
            is_invisible: true,
            key: 'details_authentication',
            type: 'item',
            highlitedKey: 'applications',
            path: 'authentication/details/:id/:name',
            title: 'Details Auth Flow',
            component_path: './apb/authentication/details',
          },

          {
            is_invisible: true,
            key: 'convert_app',
            type: 'item',
            highlitedKey: 'templates',
            path: 'templates/convert_app/:id/:name',
            title: 'Convert Template',
            component_path: './apb/templates/convert_app',
          },
          {
            is_invisible: true,
            key: 'install_app',
            type: 'item',
            highlitedKey: 'templates',
            path: 'templates/install_app/:id/:name',
            title: 'Instal Template',
            component_path: './apb/templates/install_app',
          },

          {
            is_invisible: true,
            key: 'convert_auth',
            type: 'item',
            highlitedKey: 'templates',
            path: 'templates/convert_auth/:id/:name',
            title: 'Convert Template',
            component_path: './apb/templates/convert_auth',
          },
          {
            is_invisible: true,
            key: 'install_auth',
            type: 'item',
            highlitedKey: 'templates',
            path: 'templates/install_auth/:id/:name',
            title: 'Instal Template',
            component_path: './apb/templates/install_auth',
          },
          {
            is_invisible: true,
            key: 'add_app',
            type: 'item',
            highlitedKey: 'applications',
            path: 'applications/add',
            title: 'Add App',
            component_path: './apb/applications/add',
          },
          {
            is_invisible: true,
            key: 'details_app',
            type: 'item',
            highlitedKey: 'applications',
            path: 'applications/details/:id/:name',
            title: 'Details App',
            component_path: './apb/applications/details',
          },

          {
            key: 'apps_components',
            type: 'item',
            path: 'components',
            title: 'Components',
            component_path: './apb/components',
          },
          {
            key: 'apps_shapes',
            type: 'item',
            path: 'shapes',
            title: 'Shapes',
            component_path: './apb/shapes',
          },
          {
            key: 'apps_navigators',
            type: 'item',
            path: 'navigators',
            title: 'Navigators',
            component_path: './apb/navigators',
          },
          {
            key: 'apps_controls',
            type: 'item',
            path: 'controls',
            title: 'Controls',
            component_path: './apb/controls',
          },
          {
            key: 'apps_players',
            type: 'item',
            path: 'players',
            title: 'Players',
            component_path: './apb/players',
          },
          {
            key: 'apps_headers',
            type: 'item',
            path: 'headers',
            title: 'Headers',
            component_path: './apb/headers',
          },

          {
            is_invisible: true,
            key: 'components_textstyles',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/text',
            title: 'Add Text Style',
            component_path: './apb/components/components/add/text',
          },
          {
            is_invisible: true,
            key: 'components_button',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/button',
            title: 'Add Button',
            component_path: './apb/components/components/add/button',
          },
          {
            is_invisible: true,
            key: 'components_keyboard',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/keyboard',
            title: 'Add Keyboard',
            component_path: './apb/components/components/add/keyboard',
          },
          {
            is_invisible: true,
            key: 'components_input',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/input',
            title: 'Add Input',
            component_path: './apb/components/components/add/input',
          },
          {
            is_invisible: true,
            key: 'components_message',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/message',
            title: 'Add Message Window',
            component_path: './apb/components/components/add/message',
          },
          {
            is_invisible: true,
            key: 'components_modal',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/modal',
            title: 'Add Modal',
            component_path: './apb/components/components/add/modal',
          },
          {
            is_invisible: true,
            key: 'components_switch',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/switch',
            title: 'Add Switch',
            component_path: './apb/components/components/add/switch',
          },
          {
            is_invisible: true,
            key: 'components_card',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/card',
            title: 'Add Card',
            component_path: './apb/components/components/add/card',
          },
          {
            is_invisible: true,
            key: 'components_picker',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/picker',
            title: 'Add Picker',
            component_path: './apb/components/components/add/picker',
          },
          // {
          //   is_invisible: true,
          //   key: "shapes_square",
          //   highlitedKey: "applications_apps",
          //   type: "item",
          //   path: "shapes/components/add/square",
          //   title: "Add Square Shape",
          //   component_path: "./apb/shapes/components/add/square",
          // },

          {
            is_invisible: true,
            key: 'shapes_television',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'shapes/components/add/television',
            title: 'Add Television Shape',
            component_path: './apb/shapes/components/add/television',
          },
          {
            is_invisible: true,
            key: 'shapes_standard',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'shapes/components/add/standard',
            title: 'Add Standard Shape',
            component_path: './apb/shapes/components/add/standard',
          },
          {
            is_invisible: true,
            key: 'shapes_rectangle',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'shapes/components/add/rectangle',
            title: 'Add Rectangle Shape',
            component_path: './apb/shapes/components/add/rectangle',
          },
          {
            is_invisible: true,
            key: 'shapes_epg',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'shapes/components/add/epg',
            title: 'Add EPG Shape',
            component_path: './apb/shapes/components/add/epg',
          },
          {
            is_invisible: true,
            key: 'shapes_vertical',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'shapes/components/add/vertical',
            title: 'Add Vertical List Shape',
            component_path: './apb/shapes/components/add/vertical',
          },
          {
            is_invisible: true,
            key: 'shapes_horizontal',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'shapes/components/add/horizontal',
            title: 'Add Horizontal Row Shape',
            component_path: './apb/shapes/components/add/horizontal',
          },
          {
            is_invisible: true,
            key: 'shapes_square',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'shapes/components/add/square',
            title: 'Add Square',
            component_path: './apb/shapes/components/add/square',
          },

          {
            is_invisible: true,
            key: 'navigators_drawer',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'navigators/components/add/drawer',
            title: 'Add Drawer',
            component_path: './apb/navigators/components/add/drawer',
          },
          {
            is_invisible: true,
            key: 'navigators_tabbar',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'navigators/components/add/tabbar',
            title: 'Add Tab Bar',
            component_path: './apb/navigators/components/add/tabbar',
          },
          {
            is_invisible: true,
            key: 'navigators_menubar',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'navigators/components/add/menubar',
            title: 'Add Menu Bar',
            component_path: './apb/navigators/components/add/menubar',
          },

          {
            is_invisible: true,
            key: 'player_controls',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'players/components/add/controls',
            title: 'Add Controls',
            component_path: './apb/players/components/add/controls',
          },

          {
            is_invisible: true,
            key: 'headers_home',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'headers/components/add/home',
            title: 'Add Home Header',
            component_path: './apb/headers/components/add/home',
          },

          {
            is_invisible: true,
            key: 'controls_top',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'controls/components/add/top',
            title: 'Add Top Controls',
            component_path: './apb/controls/components/add/top',
          },
          {
            is_invisible: true,
            key: 'controls_left',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'controls/components/add/left',
            title: 'Add Left Controls',
            component_path: './apb/controls/components/add/left',
          },
          {
            is_invisible: true,
            key: 'controls_details',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'controls/components/add/details',
            title: 'Add Details Controls',
            component_path: './apb/controls/components/add/details',
          },

          {
            is_invisible: true,
            key: 'components_animators',
            highlitedKey: 'applications_apps',
            type: 'item',
            path: 'components/components/add/animators',
            title: 'Add Animators',
            component_path: './apb/components/components/add/animators',
          },
        ],
      },
      {
        key: 'settings',
        type: 'collapse',
        auth: true,
        title: 'App Settings',
        icon: SettingSVG,
        icon_hover: SettingHover,
        routes: [
          {
            key: 'languages',
            type: 'item',
            path: 'languages',
            title: 'Languages',
            component_path: './apb/languages',
          },
          {
            key: 'ageratings',
            type: 'item',
            path: 'ageratings',
            title: 'Age Ratings',
            component_path: './apb/ageratings',
          },

          {
            key: 'hostnames_details',
            type: 'item',
            path: 'hostnames',
            title: 'Hostnames',
            component_path: './apb/hostnames',
          },
          {
            key: 'superhostnames_details',
            type: 'item',
            path: 'superhostnames',
            title: 'Super Hostnames',
            component_path: './apb/superhostnames',
          },
 
        ],
      },
    ],
  },
  UITRoutes: {
    default_selected_key: 'all_languages',
    default_open_key: 'langauges',
    Routes: [
      {
        key: 'langauges',
        type: 'collapse',
        auth: true,
        title: 'Languages',
        icon: LanguageSVG,
        icon_hover: LanguageHoverSVG,
        routes: [
          {
            key: 'all_langauges',
            type: 'item',
            path: 'languages',
            title: 'Languages',
            component_path: './uit/languages',
          },
        ],
      },
      {
        key: 'uit_menu_apps',
        type: 'collapse',
        auth: true,
        title: 'Menu Apps',
        icon: MenuAppsSVG,
        icon_hover: MenuAppsHoverSVG,
        routes: [
          {
            key: 'all_menu_apps',
            type: 'item',
            path: 'menuapps',
            title: 'Menu Apps',
            component_path: './uit/menu_apps',
          },
          {
            key: 'add_menu_app',
            is_invisible: true,
            highlitedKey: 'all_menu_apps',
            type: 'item',
            path: 'addmenuapp',
            title: 'Menu Apps',
            component_path: './uit/menu_apps/add_menu_app',
          },
          {
            key: 'update_menu_app',
            is_invisible: true,
            highlitedKey: 'all_menu_apps',
            type: 'item',
            path: 'updatemenuapp/:_id',
            title: 'Menu Apps',
            component_path: './uit/menu_apps/update_menu_app',
          },
        ],
      },
      {
        key: 'uit_hostnames',
        type: 'collapse',
        auth: true,
        title: 'Hostnames',
        icon: HostnamesSVG,
        icon_hover: HostnamesHover,
        routes: [
          {
            key: 'all_hostnames',
            type: 'item',
            path: 'hostnames',
            title: 'Hostnames',
            component_path: './uit/hostnames',
          },
          {
            key: 'add_hostname',
            is_invisible: true,
            highlitedKey: 'all_hostnames',
            type: 'item',
            path: 'addhostname',
            title: 'Hostnames',
            component_path: './uit/hostnames/add_hostname',
          },
          {
            key: 'update_hostname',
            is_invisible: true,
            highlitedKey: 'all_hostnames',
            type: 'item',
            path: 'updatehostnames/:_id',
            title: 'updateHostnames',
            component_path: './uit/hostnames/update_hostname',
          },
        ],
      },
      {
        key: 'uit_userinterfaces',
        type: 'collapse',
        auth: true,
        title: 'User Interfaces',
        icon: UserInterfacesSVG,
        icon_hover: UserInterfacesHoverSVG,
        routes: [
          {
            key: 'all_userinterfaces',
            type: 'item',
            path: 'userinterfaces',
            title: 'User Interfaces',
            component_path: './uit/user_interfaces',
          },
          {
            key: 'add_userinterface',
            is_invisible: true,
            highlitedKey: 'all_userinterfaces',
            type: 'item',
            path: 'adduserinterface',
            title: 'adduserinterface',
            component_path: './uit/user_interfaces/add_user_interface',
          },
          {
            key: 'update_userinterface',
            is_invisible: true,
            highlitedKey: 'all_userinterfaces',
            type: 'item',
            path: 'updateuserinterface/:_id',
            title: 'updateUserinterface',
            component_path: './uit/user_interfaces/update_user_interface',
          },
        ],
      },
    ],
  },
};

export default AllAreaRoutes;
